import { MultiValue, SingleValue } from "react-select";
import { IdOption } from "../forms/types";
import { FlowAction, StepperType } from "../../context/types";
import { GridFilterModel, GridLinkOperator } from "@mui/x-data-grid-pro";
import { categorias } from "./ToolsBar/tools";
import {
  getBackendId,
  getInitialMeasured,
  getNodeType,
  getStringType,
} from "../../utils/util";
import { nanoid } from "nanoid";

/**
 * @description La función validateGroupByRow realiza una validación para determinar si una fila debe ser agregada a un grupo o no, evitando filas repetidas en un contexto de agrupación.
 *
 * @param {SingleValue<IdOption> | undefined} columnSelectedOption - La opción de columna seleccionada.
 * @param {SingleValue<IdOption> | undefined} functionSelectedOption - La opción de función seleccionada.
 * @param {any[]} groupByRows - Array de filas existentes en el grupo.
 * @param {any} setMessage - Función para establecer mensajes de estado.
 * @returns {boolean} Retorna true si la validación es exitosa y la fila puede ser agregada al grupo. Retorna false si la validación falla y la fila no debe ser agregada.
 */

export const validateGroupByRow = (
  columnSelectedOption: MultiValue<IdOption> | undefined,
  functionSelectedOption: SingleValue<IdOption> | undefined,
  groupByRows: any[],
  setMessage: any
) => {
  let result = false;
  if (columnSelectedOption && functionSelectedOption) {
    const repeatedRows =
      groupByRows !== undefined
        ? groupByRows?.filter((row) => {
            return (
              columnSelectedOption.some((col: any) => {
                return col.value === row.column.value;
              }) && row.function.value === functionSelectedOption?.value
            );
          })
        : [];
    if (repeatedRows && repeatedRows.length > 0) {
      result = false;
      setMessage("No se pueden agregar filas repetidas");
    } else {
      result = true;
    }
  } else {
    setMessage("Se deben completar todos los campos");
  }

  return result;
};

export const searchStepper = (id_stepper: number, data_flow: any) => {
  const stepper = data_flow?.steppers?.find(
    (stepper: StepperType) => stepper.id === id_stepper
  );
  return stepper;
};

/**
 * @name findBeforeElementPosition
 * @description Encuentra la posición del item anterior dentro de un array.
 *
 * @param {any[]} array - El array en el que se busca el item.
 * @param {any} idItem - (Opcional) El índice o identificador del item de referencia.
 * @returns {any} Retorna el id del item anterior si se encuentra, o undefined si el array está vacío o el item no tiene anterior.
 */

export const findBeforeElementPosition = (array: any[], idItem?: any) => {
  if (array && array.length != 0) {
    if (idItem === undefined) {
      return array[array?.length - 1].id;
    } else {
      const index = array?.findIndex((item) => item.id === idItem);
      return array[index - 1]?.id;
    }
  } else {
    return undefined;
  }
};

/**
 * @name getItemsOptions
 * @description Genera opciones de elementos a partir de un flujo de datos.
 *
 * Esta función toma un flujo de datos y crea un array de opciones basado en los steppers presentes en él. Las opciones
 * representan los steppers sin errores, y cada opción tiene un valor y una etiqueta que combina el nombre del stepper con
 * el tipo del primer item.
 *
 * @param {any} data_flow - El flujo de datos del cual se generarán las opciones.
 * @returns {any[]} Array de opciones de elementos generadas a partir del flujo de datos.
 */

export const getItemsOptions = (data_flow: any) => {
  const options: any[] = [];
  if (data_flow?.steppers?.length > 0) {
    const steppersWithoutError = data_flow?.steppers?.filter(
      (stepper: any) => !stepper?.items[stepper.items.length - 1]?.posee_error
    );
    steppersWithoutError.forEach((stepper: StepperType) =>
      options.push({
        value: stepper.id,
        label: `${stepper.nombre} - ${stepper.items[0]?.tipo}`,
      })
    );
  }
  return options;
};

/**
 * @name isFileNameValid
 * @description IMPORTANTE: FUNCIONALIDAD COMENTADA POR PEDIDO DE FER: Verifica si un nombre de archivo cumple con el formato válido.
 *
 * Esta función toma un nombre de archivo y utiliza una expresión regular para verificar si cumple con el formato válido.
 * El formato válido permite letras, números y ciertos caracteres especiales (espacio, guión bajo, guión y punto).
 * El nombre de archivo debe tener al menos 1 caracter y no más de 50 caracteres.
 *
 * @param {string} name - El nombre de archivo a ser verificado.
 * @returns {boolean} Verdadero si el nombre de archivo cumple con el formato válido, falso de lo contrario.
 */

export const isFileNameValid = (name: string) => {
  /* const validFormatRegExp = new RegExp("^[a-zA-Z0-9 _.&|:-]*$");
  const validFormat =
    validFormatRegExp.test(name) && name?.length < 50 && name?.length >= 1;
  return validFormat;*/
  return true;
};

/**
 * @name isStepNameRepeated
 * @description Verifica si un nombre de paso está repetido en el flujo de datos.
 *
 * Esta función toma un nombre de paso y un flujo de datos, y verifica si el nombre del paso está repetido en los steppers
 * presentes en el flujo de datos. La verificación es insensible a mayúsculas y espacios en blanco al principio y final del nombre.
 *
 * @param {string} name - El nombre de paso a ser verificado.
 * @param {any} data_flow - El flujo de datos que contiene los steppers para verificar la repetición del nombre.
 * @returns {boolean} Verdadero si el nombre de paso está repetido, falso de lo contrario.
 */

export const isStepNameRepeated = (name: string, data_flow: any) => {
  let isRepeated = false;
  data_flow?.steppers?.forEach((step: any) => {
    if (step.nombre?.toUpperCase().trim() === name?.toUpperCase().trim()) {
      isRepeated = true;
      return;
    }
  });
  return isRepeated;
};

/**
 * @name tranformDataGroupByToSend
 * @description Transforma un array de datos en un nuevo formato para su envío.
 * Cada fila del array se convierte en un objeto que contiene la columna, la operación y un
 * posible renombre. Si no hay un renombre definido, se establece como undefined.
 *
 * @param {any[]} data - Un array de objetos que representan las filas de datos a transformar.
 * @returns {Object[]} - Un array de objetos transformados con propiedades columna, operacion
 * y renombre.
 */
export const tranformDataGroupByToSend = (data: any) => {
  return data.map((row: any) => {
    return {
      columna: row.column.label,
      operacion: row.function.value,
      renombre: row.renombre && row.renombre !== "" ? row.renombre : undefined,
    };
  });
};

/**
 * @name isColumnNameValid
 * @description Verifica si un nombre de columna cumple con el formato válido.
 *
 * Esta función toma un nombre de columna y utiliza una expresión regular para verificar si cumple con el formato válido.
 * El formato válido permite letras, números y ciertos caracteres especiales (punto, guión bajo y guión).
 * El nombre de columna debe tener al menos 1 caracter y no más de 25 caracteres.
 *
 * @param {string} name - El nombre de columna a ser verificado.
 * @returns {boolean} Verdadero si el nombre de columna cumple con el formato válido, falso de lo contrario.
 */

export const isColumnNameValid = (name: string) => {
  const validFormatRegExp = new RegExp("^[^,]+$");
  const validFormat =
    validFormatRegExp?.test(name) && name?.length <= 40 && name?.length >= 1;
  return validFormat;
};
interface Operators {
  [key: string]: string;
}

export const operators: Operators = {
  contains: "CONTIENE",
  equals: "ES_IGUAL",
  startsWith: "COMIENZA_CON",
  endsWith: "TERMINA_CON",
  isEmpty: "ESTA_VACIO",
  isNotEmpty: "NO_ESTA_VACIO",
  isAnyOf: "ES_CUALQUIERA_DE",
  es_mayor: "ES_MAYOR",
  es_mayor_o_igual: "ES_MAYOR_O_IGUAL",
  es_menor: "ES_MENOR",
  es_menor_o_igual: "ES_MENOR_O_IGUAL",
  no_es_cero: "NO_ES_CERO",
  no_es_igual: "NO_ES_IGUAL",
  no_contiene: "NO_CONTIENE",
  no_comienza_con: "NO_COMIENZA_CON",
  no_termina_con: "NO_TERMINA_CON",
};

export const operadores: Operators = {
  CONTIENE: "contains",
  ES_IGUAL: "equals",
  COMIENZA_CON: "startsWith",
  TERMINA_CON: "endsWith",
  ESTA_VACIO: "isEmpty",
  NO_ESTA_VACIO: "isNotEmpty",
  ES_CUALQUIERA_DE: "isAnyOf",
  ES_MAYOR: "es_mayor",
  ES_MAYOR_O_IGUAL: "es_mayor_o_igual",
  ES_MENOR: "es menor",
  ES_MENOR_O_IGUAL: "es_menor_o_igual",
  NO_ES_CERO: "no_es_cero",
  NO_ES_IGUAL: "no_es_igual",
  NO_CONTIENE: "no_contiene",
  NO_COMIENZA_CON: "no_comienza_con",
  NO_TERMINA_CON: "no_termina_con",
};

type Item = {
  columnField: string;
  operatorValue: string;
  value: string | string[];
};

export type Info = {
  items: Item[];
  linkOperator: GridLinkOperator | undefined;
};

/**
 * @name transformDataToSend
 * @description Transforma un modelo de filtro de cuadrícula en un formato adecuado para su envío.
 * Cada filtro se convierte en un objeto con la columna, el operador y el valor correspondiente.
 * También se incluye un operador de nexo basado en el operador de enlace proporcionado.
 *
 * @param {GridFilterModel} info - El modelo que contiene los elementos a transformar.
 * @returns {Object} - Un objeto que contiene el tipo de operación, un array de filtros y el operador de nexo.
 */
export const transformDataToSend = (info: GridFilterModel) => {
  const filtros = info?.items?.map((item: any) => {
    const columna =
      item.columnField; /* .substring(0, item.columnField.lastIndexOf("(")) */
    const operador = operators[item.operatorValue];
    const valor = typeof item.value === "string" ? [item.value] : item.value;

    return {
      columna,
      operador,
      valor,
    };
  });

  return {
    tipo: "filtrar",
    filtros,
    operador_nexo: info.linkOperator === GridLinkOperator.And ? "Y" : "O",
  };
};

/**
 * @name isExcelFile
 * @description Verifica si un archivo tiene una extensión de archivo de Excel válida.
 * Comprueba si el nombre del archivo termina con alguna de las extensiones comunes de archivos de Excel.
 *
 * @param {any} file - El nombre del archivo a verificar.
 * @returns {boolean | undefined} - Retorna true si el archivo tiene una extensión válida de Excel,
 * false en caso contrario, o undefined si no se proporciona un archivo.
 */
export const isExcelFile = (file: any) => {
  if (file) {
    return (
      file.trim().endsWith(".xlsx") ||
      file.trim().endsWith(".xlsm") ||
      file.trim().endsWith(".xlsb") ||
      file.trim().endsWith(".xltx") ||
      file.trim().endsWith(".xltm") ||
      file.trim().endsWith(".xls") ||
      file.trim().endsWith(".xlt") ||
      file.trim().endsWith(".xml")
    );
  }
};

/**
 * @name initialFilterData
 * @description Inicializa los datos de filtro a partir de un objeto proporcionado.
 * Si se proporciona un objeto de datos, transforma sus filtros en un formato adecuado.
 * Si no se proporciona ningún dato, retorna un objeto con un filtro vacío y un operador de enlace predeterminado.
 *
 * @param {any} data - Un objeto que contiene filtros y un operador de enlace.
 * @returns {Object} - Un objeto que incluye un array de filtros inicializados y el operador de enlace.
 */
export const initialFilterData = (data?: any) => {
  if (data) {
    return {
      items: data?.filtros?.map((filtro: any, index: number) => {
        return {
          id: index,
          columnField: filtro.columna,
          operatorValue: operadores[filtro.operador],
          value:
            filtro.operador === "ES_CUALQUIERA_DE"
              ? filtro.valor
              : filtro.valor[0],
        };
      }),
      linkOperator:
        data?.operador_nexo === "Y"
          ? GridLinkOperator.And
          : GridLinkOperator.Or,
    };
  } else {
    return {
      items: [
        {
          id: "",
          columnField: "",
          operatorValue: "",
          value: [],
        },
      ],
      linkOperator: GridLinkOperator.And,
    };
  }
};

/**
 * @name isColumnNameRepeated
 * @description Verifica si un nombre de columna se repite en un array de columnas.
 * Compara el nombre proporcionado con los nombres de las columnas en el array, ignorando mayúsculas y espacios.
 *
 * @param {string} name - El nombre de la columna que se desea verificar.
 * @param {any[]} dataColumns - Un array de objetos que representan las columnas.
 * @returns {boolean} - Retorna true si el nombre de la columna se repite, false en caso contrario.
 */
export const isColumnNameRepeated = (name: string, dataColumns: any) => {
  let isRepeated = false;
  dataColumns?.forEach((column: any) => {
    if (column.columna.toUpperCase().trim() === name.toUpperCase().trim()) {
      isRepeated = true;
      return;
    }
  });
  return isRepeated;
};

/**
 * @name filterIdFromColumns
 * @description Filtra un array de columnas, excluyendo aquellas que tienen el nombre "id".
 * Retorna un nuevo array que contiene solo las columnas que no son "id".
 *
 * @param {any[]} dataColumns - Un array de objetos que representan las columnas.
 * @returns {any[]} - Un nuevo array que contiene las columnas que no son "id".
 */
export const filterIdFromColumns = (dataColumns: any) => {
  return dataColumns?.filter(
    (column: any) => column.columna != "id" && column?.nombre !== "id"
  );
};

/**
 * @name handleSetItemBarStatus
 * @description Maneja el estado de la barra de herramientas de elementos.
 *
 * Esta función toma varios parámetros y manipula el estado de la barra de herramientas de elementos en función del flujo de datos,
 * el stepper activo y el estado de los elementos en el flujo. Habilita o deshabilita las herramientas de acuerdo a condiciones
 * específicas para permitir o restringir ciertas acciones en la interfaz.
 *
 * @param {React.Dispatch<FlowAction>} flowDispatch - El despachador de acciones para el flujo de datos.
 * @param {any} data_flow - El flujo de datos actual.
 * @param {number} active_stepper - El identificador del stepper activo.
 * @param {typeof categorias} itemsBarStatus - El estado de la barra de herramientas de elementos.
 */

export const handleSetItemBarStatus = (
  flowDispatch: React.Dispatch<FlowAction>,
  data_flow: any,
  active_stepper: number,
  itemsBarStatus: typeof categorias
) => {
  const habilitarNuevaFuenteMergeConcat = () => {
    flowDispatch({
      type: "SET_ITEMS_BAR_STATUS",
      payload: itemsBarStatus.map((item: any) => {
        item.tools.map((tool: any) => {
          tool.tipo === "nueva_fuente" ||
          tool.tipo === "merge" ||
          tool.tipo === "concat" ||
          tool.tipo === "cambiar_fuentes"
            ? (tool.disabled = false)
            : (tool.disabled = true);
          return tool;
        });
        return item;
      }),
    });
  };

  const habilitarNuevaFuente = () =>
    flowDispatch({
      type: "SET_ITEMS_BAR_STATUS",
      payload: itemsBarStatus.map((item: any) => {
        item.tools.map((tool: any) => {
          (tool.tipo === "nueva_fuente" && data_flow?.nombre != "") ||
          (tool.tipo === "cambiar_fuentes" &&
            data_flow?.nombre != "" &&
            data_flow?.steppers?.length > 0)
            ? (tool.disabled = false)
            : (tool.disabled = true);
          return tool;
        });
        return item;
      }),
    });
  const habilitarTodos = () =>
    flowDispatch({
      type: "SET_ITEMS_BAR_STATUS",
      payload: itemsBarStatus.map((item: any) => {
        item.tools.map((tool: any) => {
          tool.disabled = false;
          return tool;
        });
        return item;
      }),
    });
  const habilitarTodosMenosMergeConcat = () =>
    flowDispatch({
      type: "SET_ITEMS_BAR_STATUS",
      payload: itemsBarStatus.map((item: any) => {
        item.tools.map((tool: any) => {
          tool.tipo === "merge" || tool.tipo === "concat"
            ? (tool.disabled = true)
            : (tool.disabled = false);
          return tool;
        });
        return item;
      }),
    });

  /* const haySteppers = data_flow?.steppers.length !== 0;

  const activeStepper = data_flow && data_flow?.steppers && data_flow?.steppers?.find(
    (stepper: any) => stepper.id === active_stepper
  );

  const activeStepperTieneError =
    activeStepper?.items[activeStepper.items.length - 1]?.posee_error;

  const hayMasDeUnStepperSinError =
    data_flow?.steppers?.filter(
      (stepper: any) =>
        stepper?.items[stepper.items.length - 1]?.posee_error === false
    ).length > 1;*/

  /*if (haySteppers) {
    if (activeStepper) {
      if (activeStepperTieneError) {
        if (hayMasDeUnStepperSinError) {
          habilitarNuevaFuenteMergeConcat();
        } else {
          habilitarNuevaFuente();
        }
      } else {
        if (hayMasDeUnStepperSinError) {
          habilitarTodos();
        } else {
          habilitarTodosMenosMergeConcat();
        }
      }
    } else {
      if (hayMasDeUnStepperSinError) {
        habilitarNuevaFuenteMergeConcat();
      } else {
        habilitarNuevaFuente();
      }
    }
  } else {
    habilitarNuevaFuente();
  }*/
  habilitarTodos();
};

/**
 * @name setInitialFlowNodes
 * @description Setea los nodos luego de obtener el flow
 *
 * Esta función toma los items del flow enviados por el backend y los formatea al formato nodos que necesita reactFlow.
 *
 * @param {any[]} items - Los items del flow enviados por el backend
 * @returns {any[]} los items formateados al formato de nodo
 */
export const setInitialFlowNodes = (items: any[]) => {
  const groups = groupByItemGroup(items);
  return [
    ...items
      .filter((item: any) => item.grupo.id === undefined)
      .map((item: any) => {
        return {
          id: item.id.toString(),
          type: getNodeType(item.tipo),
          measured: getInitialMeasured(item.tipo),
          position: { x: item.x, y: item.y },
          data: {
            comentario: item.comentario,
            backendId: item.id,
            parentIds: item.parent_ids,
            error: item.posee_error,
            errorMessage: item.mensaje_error,
          },
        };
      }),
    ...groups.map((item: any) => {
      return {
        id: `${item.id}-group`,
        type: "node-group",
        measured: { width: 45, height: 52 },
        position: { x: item.x, y: item.y },
        data: {
          nombre: item.nombre,
          groupId: item.id,
          parentIds: item.items[0].parent_ids,
          nodes: item.items.map((item: any) => {
            return {
              ...item,
              type: getNodeType(item.tipo),
              backendId: item.id.toString(),
            };
          }),
        },
      };
    }),
  ];
};

/**
 * @name setInitialFlowEdges
 * @description Setea los edges (uniones de los nodos) luego de obtener el flow
 *
 * Esta función toma los items del flow enviados por el backend y formatea los edges al formato nodos que necesita reactFlow.
 *
 * @param {any[]} items - Los items del flow enviados por el backend
 * @returns {any[]} los edges formateados al formato que necesita reactFlow
 */
export const setInitialFlowEdges = (items: any[]) => {
  const groups = groupByItemGroup(items);
  if (groups.length === 0) {
    return items
      .filter((item) => item.parent_ids && item.parent_ids.length > 0) // Filtrar solo los items que tienen parent_ids válidos
      .flatMap((item) => {
        return item.parent_ids.map((parentId: any) => {
          return {
            id: nanoid(),
            source: parentId.toString(),
            target: item.id.toString(),
            type: "custom-edge",
            sourceHandle: `handle-source-2-${parentId}`,
            targetHandle: `handle-target-2-${item.id}`,
          };
        });
      });
  } else {
    const naturalFlowEdges = items
      .filter((item) => item.parent_ids && item.parent_ids.length > 0) // Filtrar solo los items que tienen parent_ids válidos
      .flatMap((item) => {
        return item.parent_ids.map((parentId: any) => {
          return {
            id: nanoid(),
            source: parentId.toString(),
            target: item.id.toString(),
            type: "custom-edge",
            sourceHandle: `handle-source-2-${parentId}`,
            targetHandle: `handle-target-2-${item.id}`,
          };
        });
      });
    const groupsEdges: any[] = [];
    groups.forEach((group: any) => {
      const firstNodeConnection = group.items.sort(
        (a: any, b: any) => a.x - b.x
      )[0].parent_ids[0];
      const lastNodeConnection = findNodeByParentId(
        group.items.sort((a: any, b: any) => a.x - b.x)[group.items.length - 1]
          .id,
        items
      );
      firstNodeConnection &&
        groupsEdges.push({
          id: nanoid(),
          source: firstNodeConnection.toString(),
          target: `${group.id}-group`,
          type: "custom-edge",
        });
      lastNodeConnection &&
        groupsEdges.push({
          id: nanoid(),
          source: `${group.id}-group`,
          target: lastNodeConnection.id.toString(),
          type: "custom-edge",
        });
    });
    return [...naturalFlowEdges, ...groupsEdges];
  }
};

/**
 * @name setErrorFlowNodes
 * @description Setea los errores en los nodos luego de obtener el flow
 *
 * Esta función toma los items del flow enviados por el backend e inserta los errores de existir.
 *
 * @param {any[]} backendItems - Los items del flow enviados por el backend
 * @returns {any[]} los items formateados al formato de nodo
 */
export const setErrorFlowNodes = (nodes: any[], backendItems: any[]) => {
  return nodes.map((node: any) => {
    // Encuentra el backendItem que coincida con el backendId del nodo
    const backendItem = backendItems.find(
      (item: any) => item.id === node.data.backendId
    );

    // Si se encuentra un backendItem, usa sus valores para actualizar el nodo
    if (backendItem) {
      return {
        ...node,
        data: {
          ...node.data,
          error: backendItem.posee_error,
          errorMessage: backendItem.mensaje_error,
        },
      };
    }

    // Si no se encuentra un backendItem, devuelve el nodo sin cambios
    return node;
  });
};

/**
 * @name setLocationFlowNodes
 * @description Setea las ubicaciones en los nodos luego de obtener el flow
 *
 * Esta función toma los items del flow enviados por el backend y los ordena.
 *
 * @param {any[]} backendItems - Los items del flow enviados por el backend
 * @returns {any[]} los items formateados al formato de nodo
 */
export const setLocationFlowNodes = (nodes: any[], backendItems: any[]) => {
  return nodes.map((node: any) => {
    // Encuentra el backendItem que coincida con el backendId del nodo
    const backendItem = backendItems.find(
      (item: any) => item.id === node.data.backendId
    );

    // Si se encuentra un backendItem, usa sus valores para actualizar el nodo
    if (backendItem) {
      return {
        ...node,
        position: { x: backendItem.x, y: backendItem.y },
      };
    }

    // Si no se encuentra un backendItem, devuelve el nodo sin cambios
    return node;
  });
};

/**
 * @name getItemById
 * @description Busca y retorna el primer elemento en un array que coincide con el ID proporcionado.
 * Si no se encuentra ningún elemento con el ID especificado, retorna undefined.
 *
 * @param {number} id - El ID del elemento que se desea buscar.
 * @param {any[]} array - Un array de elementos donde se buscará el elemento.
 * @returns {any | undefined} - Retorna el objeto que representa el elemento si se encuentra,
 * o undefined si no se encuentra ningún elemento con el ID especificado.
 */
export const getItemById = (id: number, array: any) => {
  return array?.find((item: any) => item.id == id);
};

/**
 * @name formatConcatAndMergeOptions
 * @description Formatea los items seleccionados para el concat al formar {value:1, label:"Opcion"}
 *
 * Esta función se utiliza en el edit del concat para formatear los items seleccionados al formato necesario para el select
 *
 * @param {any[] | undefined} ids - Array de ids del backend de los items seleccionados
 * @param {any[]} nodes - El array de nodos
 * @returns {any[]} Array de opciones de elementos generadas a partir del flujo de datos.
 */

export const formatConcatAndMergeOptions = (
  ids: any[] | undefined,
  nodes: any[]
) => {
  const options: any[] = [];
  nodes.forEach((node: any) => {
    return ids?.forEach((id: any) => {
      if (id == node.id || id === node?.data?.backendId) {
        options.push(node);
      }
    });
  });
  return options.map((node: any) => {
    return {
      value: node.data.backendId,
      label: `${getStringType(node.type)}  ${
        node.data.comentario ? " - " + node.data.comentario : ""
      }`,
    };
  });
};

/**
 * @name formatConcatOptionsFromBackend
 * @description Formatea los items seleccionados para el concat al formar {value:1, label:"Opcion"}
 *
 * Esta función se utiliza en el edit del concat para formatear los items que vienen del backend seleccionados al formato necesario para el select
 *
 * @param {any[] | undefined} ids - Array de ids del backend de los items seleccionados
 * @param {any[]} nodes - El array de nodos
 * @returns {any[]} Array de opciones de elementos generadas a partir del flujo de datos.
 */

export const formatConcatOptionsFromBackend = (
  ids: any[] | undefined,
  nodes: any[]
) => {
  const options: any[] = [];
  nodes.forEach((node: any) => {
    return ids?.forEach((id: any) => {
      if (id === node.data.backendId) {
        options.push(node);
      }
    });
  });
  return options.map((node: any) => {
    return {
      value: node.data.backendId,
      label: `${getStringType(node.type)}  ${
        node.data.comentario ? " - " + node.data.comentario : ""
      }`,
    };
  });
};

/**
 * @name formatMergeOption
 * @description Formatea los items seleccionados para el concat al formar {value:1, label:"Opcion"}
 *
 * Esta función se utiliza en el edit del merge para formatear los items que vienen del backend seleccionados al formato necesario para el select
 *
 * @param {any | undefined} id - Id del backend del item seleccionado
 * @param {any[]} nodes - El array de nodos
 * @returns {object} Objeto con el formato {value:1, label: "opcion"} con la opcion seleccionada
 */

export const formatMergeOption = (id: any | undefined, nodes: any[]) => {
  const node = nodes.find((node: any) => {
    return node?.data?.backendId === id;
  });
  return {
    value: node?.data?.backendId,
    label: `${getStringType(node?.type)}  ${
      node?.data?.comentario ? " - " + node.data?.comentario : ""
    }`,
  };
};

/**
 * @name formatMergeColumnOptions
 * @description Formatea las columnas seleccionadas del backend al formar {value:1, label:"Opcion"}
 *
 * Esta función se utiliza en el edit del merge para formatear las columnas seleccionadas del backendal formato necesario para el select
 *
 * @param {any[] | undefined} columns - Array de columnas seleccionadas por el backend

 * @returns {object[]} Array de objetos con el formato adecuado para las options del select
 */

export const formatMergeColumnOptions = (columns: any[] | undefined) => {
  return columns?.map((column: string) => {
    return {
      value: column,
      label: column,
    };
  });
};

/**
 * @name getSteppersOptions
 * @description Genera opciones de elementos con el formato necesario para el checkmark select
 *
 * Esta función toma un flujo de datos y crea un array de opciones basado en los steppers presentes en él. Las opciones
 * representan los steppers sin errores, y cada opción tiene un valor y una etiqueta que combina el nombre del stepper con
 * el tipo del primer item.
 *
 * @param {any} data_flow - El flujo de datos del cual se generarán las opciones.
 * @returns {any[]} Array de opciones de elementos generadas a partir del flujo de datos.
 */

export const getSteppersOptions = (data_flow: any) => {
  const options: any[] = [];
  if (data_flow?.steppers?.length > 0) {
    const steppersWithoutError = data_flow?.steppers?.filter(
      (stepper: any) => !stepper?.items[stepper.items.length - 1]?.posee_error
    );
    steppersWithoutError.forEach((stepper: StepperType) =>
      options.push({
        id: stepper.id,
        label: `${stepper.nombre} - ${stepper.items[0]?.tipo}`,
        steppers_asociados:
          stepper.items[0]?.tipo === "merge" ||
          stepper.items[0]?.tipo === "concat"
            ? stepper.items[0]?.steppers_asociados
            : undefined,
      })
    );
  }
  return options;
};

/**
 * @name getItemsById
 * @description Devuelve los items a partir de los ids que recibe como parametro
 *
 * Esta función recibe el data_flow y los ids asociados a un items especifico y devuelve un array de objetos con los items buscados

 * @param {any} data_flow - El flujo de datos.
 *  * @param {number[]} ids - Array con los ids de los items buscados.
 * @returns {any[]} Array de objetos con los items buscados.
 */

export const getItemsById = (data_flow: any, ids: number[]) => {
  const items: any = [];
  data_flow?.steppers?.forEach((stepper: StepperType) => {
    if (ids.includes(stepper.id as number)) {
      items.push({
        id: stepper.id,
        label: `${stepper.nombre} - ${stepper.items[0]?.tipo}`,
        steppers_asociados:
          stepper.items[0]?.tipo === "merge" ||
          stepper.items[0]?.tipo === "concat"
            ? stepper.items[0]?.steppers_asociados
            : undefined,
      });
    }
  });
  return items;
};

/**
 * @name deleteDuplicatedItems
 * @description Elimina los items duplicados del array
 *
 * Esta función recibe un array con los items seleccionado y elimina los duplicados

 * @param {any} items - El array de items
 * @returns {any[]} Array de items sin duplicados.
 */

export function deleteDuplicatedItems(items: any) {
  const idsSet = new Set(); // Utilizamos un conjunto para mantener un registro de los IDs únicos
  const resultado = [];

  for (const item of items) {
    if (!idsSet.has(item.id)) {
      resultado.push(item);
      idsSet.add(item.id);
    }
  }
  return resultado;
}

/**
 * @name getFileById
 * @description Busca y retorna la información de un archivo en un array de hojas de datos
 * utilizando el ID proporcionado. Si no se encuentra ningún archivo con el ID especificado,
 * retorna undefined.
 *
 * @param {number} id - El ID del archivo que se desea buscar.
 * @param {any[]} dataSheets - Un array de hojas de datos donde se buscará el archivo.
 * @returns {any | undefined} - Retorna el objeto que representa el archivo si se encuentra,
 * o undefined si no se encuentra ningún archivo con el ID especificado.
 */
export function getFileById(id: number, dataSheets: any[]) {
  const fileInfo = dataSheets.find((file) => {
    return file.id === id;
  });
  return fileInfo;
}

/**
 * @name formatMergeColumnControlOptions
 * @description Elimina los items duplicados del array
 *
 * Esta función recibe como primer parametro un objeto con el id del stepper seleccionado como archivo 1 para el merge y sos columnas, y como segundo parametro otro objeto con el id del stepper seleccionado como archivo 2 para el merge y sos columnas, y devuelve un array con todas las columnas de ambos archivos especificando a cuál stepper pertenecen

 * @param {any} font1 - El objeto con el id del stepper 1 y sus columnas
 * @param {any} font2 - El objeto con el id del stepper 2 y sus columnas
 * @returns {any[]} Array de opciones para el select de columna-control.
 */

export const formatMergeColumnControlOptions = (font1: any, font2: any) => {
  const columns1WithInfo = font1.columnas.map((col: any) => {
    return {
      value: col.id,
      parent_ids: font1.value,
      label: `${col.columna} - ${font1.label.replace(
        /^(.*)\s-\s[^-]*$/,
        "$1"
      )}`, // esto es para obtener solo el nombre de la fuente ya que el label viene como 'nombreSTEPPER - nueva fuente'
    };
  });
  const columns2WithInfo = font2.columnas.map((col: any) => {
    return {
      value: col.id,
      parent_ids: font2.value,
      label: `${col.columna} - ${font2.label.replace(
        /^(.*)\s-\s[^-]*$/,
        "$1"
      )}`, // esto es para obtener solo el nombre de la fuente ya que el label viene como 'nombreSTEPPER - nueva fuente'
    };
  });

  return columns1WithInfo.concat(columns2WithInfo);
};

/**
 * @name checkIfAllColumnNotExists
 * @description Verifica si todas las columans seleccionadas, no existen en la lista de columnas actuales.
 *
 * Esta función recibe dos arreglos, uno que contiene las columnas seleccionadas y otro que contiene las columnas actuales. Devuelve true si todas las columnas seleccionadas no existen en la lista de columnas actuales, de lo contrario, devuelve false.
 *
 * @param {any[]} selectedColumns - Arreglo que contiene las columnas seleccionadas.
 * @param {any[]} actualColumns - Arreglo que contiene las columnas actuales.
 * @returns {boolean} true si todas las columnas seleccionadas no existen en la lista de columnas actuales, false de lo contrario.
 */
export const checkIfAllColumnNotExists = (
  selectedColumns: any[],
  actualColumns: any[]
) => {
  return selectedColumns.every((selectedCol) => {
    return !actualColumns.some((col) => col.columna === selectedCol.label);
  });
};

/**
 * @name checkIfColumnNotExists
 * @description Verifica si una columna específica no existe en la lista de columnas actuales.
 *
 * Esta función recibe el nombre de una columna y un arreglo que contiene las columnas actuales. Devuelve true si la columna específica no existe en la lista de columnas actuales, de lo contrario, devuelve false.
 *
 * @param {string} column - El nombre de la columna que se va a verificar.
 * @param {any[]} actualColumns - Arreglo que contiene las columnas actuales.
 * @returns {boolean} true si la columna específica no existe en la lista de columnas actuales, false de lo contrario.
 */
export const checkIfColumnNotExists = (
  column: string,
  actualColumns: any[]
) => {
  return !actualColumns.some((col) => {
    return col.label === column;
  });
};

/**
 * @name formatFonts
 * @description Formatea un array de nodos, extrayendo solo aquellos de tipo "font"
 * que tienen un backendId. Retorna un array de objetos con el nombre, ID y archivo de datos.
 *
 * @param {any[]} data - Un array de nodos que se procesarán.
 * @returns {Object[]} - Un array de objetos que contiene el nombre, ID y archivo de datos
 * de los nodos de tipo "font" que tienen un backendId.
 */
export const formatFonts = (data: any) => {
  return data
    ?.filter((node: any) => {
      return node.type === "font" && node.data.backendId;
    })
    .map((font: any) => {
      return {
        nombre: font.data.comentario,
        id: font.data.backendId,
        dataFile: "original",
      };
    });
};

/**
 * @name handleSetting
 * @description Maneja la creación de nodos según una opción seleccionada.
 * Basado en el valor del parámetro `item`, crea diferentes tipos de nodos
 * con propiedades específicas y en una posición determinada.
 *
 * @param {string} item - La opción seleccionada que determina qué nodo crear.
 * @param {function} createNode - Función para crear un nuevo nodo.
 * Debe aceptar cuatro argumentos: tipo de nodo, datos, posición y ID del nodo padre.
 * @param {Object} nodeData - Datos del nodo actual, que incluye color y posición.
 */
export const handleSetting = (item: string, createNode: any, nodeData: any) => {
  switch (item) {
    case "Setear cabecera":
      createNode(
        "set-header",
        { color: nodeData.data.color },
        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
    case "Nueva columna":
      createNode(
        "new-column",
        { color: nodeData.data.color },
        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
      case "Seleccionar columnas":
        createNode(
          "select-columns",
          { color: nodeData.data.color },
          { x: nodeData.position.x + 60, y: nodeData.position.y },
          nodeData.id
        );
        break;
    case "Eliminar columna":
      createNode(
        "delete-column",
        { color: nodeData.data.color },
        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
    case "Modificar columna":
      createNode(
        "modify-column",
        { color: nodeData.data.color },
        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
    case "Renombrar columnas":
      createNode(
        "rename-columns",
        { color: nodeData.data.color },

        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
    case "Dinamizar columnas":
      createNode(
        "dinamize",
        { color: nodeData.data.color },
        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
    case "Desdinamizar columnas":
      createNode(
        "desdinamize",
        { color: nodeData.data.color },
        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
    case "Eliminar filas":
      createNode(
        "delete-rows",
        { color: nodeData.data.color },
        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
    case "Filtrar":
      createNode(
        "filter",
        { color: nodeData.data.color },
        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
    case "Group By":
      createNode(
        "group-by",
        { color: nodeData.data.color },
        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
    case "Merge":
      createNode(
        "merge",
        { color: nodeData.data.color },
        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
    case "Concat":
      createNode(
        "concat",
        { color: nodeData.data.color },
        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
    case "Calcular":
      createNode(
        "calculate",
        { color: nodeData.data.color },
        { x: nodeData.position.x + 60, y: nodeData.position.y },
        nodeData.id
      );
      break;
    default:
      console.log("Opción no reconocida");
  }
};

export const getAllDescendants = (
  nodeId: any,
  elements: any[],
  edges: any,
  isGroupNode?: boolean
) => {
  const descendants = new Set();

  const findDescendants = (id: any, isGroupNode?: boolean) => {
    elements.forEach((el) => {
      if (isGroupNode) {
        if (
          (el.data.parentIds.includes(
            typeof parseInt(id) === "number" ? parseInt(id) : id
          ) ||
            findEdgeBySourceNode(edges, id)?.target === el.id) &&
          el.type !== "merge" &&
          el.type !== "concat"
        ) {
          descendants.add(el);
          findDescendants(el.id); // Recursivamente busca descendientes
        }
      } else {
        if (
          el.data.parentIds.includes(
            typeof parseInt(id) === "number" ? parseInt(id) : id
          ) &&
          el.type !== "merge" &&
          el.type !== "concat"
        ) {
          descendants.add(el);
          findDescendants(el.id); // Recursivamente busca descendientes
        }
      }
    });
  };

  findDescendants(nodeId, isGroupNode);
  return Array.from(descendants);
};

/**
 * @name getRandomColor
 * @description Selecciona un color al azar de una lita de colores filtrando que no exista en los colores utilizados
 *
 * @param {usedColors} string[] - El evento despachado por default.
 * @returns {string | null} El color elegido al azar o null si todos estan utilizados
 */

export function getRandomColor(usedColors: string[]): string | null {
  const colors = [
    "blue",
    "dark-blue",
    "light-blue",
    "blue-greeny",
    "greeny",
    "green",
    "light-green",
    "yellow",
    "light-yellow",
    "orange",
    "violet",
    "light-violet",
    "dark-blue-2",
    "violet-2",
    "green-2",
  ];
  // Filtra los colores que no están en la lista de colores usados
  const availableColors = colors.filter((color) => !usedColors.includes(color));

  // Verifica si hay colores disponibles
  if (availableColors.length === 0) {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex]; // O puedes lanzar un error, dependiendo de tu necesidad
  }

  // Selecciona un color aleatorio de los colores disponibles
  const randomIndex = Math.floor(Math.random() * availableColors.length);
  return availableColors[randomIndex];
}

/**
 * @name setItemsColor
 * @description Actualiza el color de todos los nodos descendientes de nodos específicos.
 * Si un nodo es de tipo "font", "merge" o "concat", todos sus descendientes
 * recibirán el mismo color que el nodo padre.
 *
 * @param {any[]} nodes - Un array de nodos que se procesarán.
 * @param {function} updateNode - Función para actualizar los nodos.
 * Debe aceptar dos argumentos: el ID del nodo y un objeto con los datos actualizados.
 */
export const setItemsColor = (nodes: any, updateNode: any, edges: any) => {
  if (nodes?.length > 0) {
    setTimeout(() => {
      nodes.forEach((nodeDad: any) => {
        if (
          nodeDad.type === "font" ||
          nodeDad.type === "merge" ||
          nodeDad.type === "concat" ||
          nodeDad.type === "node-group"
        ) {
          const allConnectedNodes = getAllDescendants(
            nodeDad.id,
            nodes,
            edges,
            nodeDad.type === "node-group"
          );
          allConnectedNodes.forEach((node: any) => {
            updateNode(node.id, {
              ...node.data,
              color: nodeDad.data.color,
            });
          });
        }
      });
    }, 10);
  }
};

/**
 * Agrupa los items de un flujo de transformaciones por su grupo asociado.
 *
 * @param {any} items - Array de `items` del flujo.
 *                     Cada item puede tener una propiedad `grupo`.
 *
 * @returns {Array<any>} - Un array de grupos, donde cada grupo es un objeto
 *                         que contiene `id`, `nombre` y un array de `items`
 *                         pertenecientes a ese grupo.

 */
export const groupByItemGroup = (items: any) => {
  const groupsMap = new Map<number, any>();

  items.forEach((item: any) => {
    if (item.grupo.id) {
      const { grupo } = item;

      // Si el grupo no existe en el mapa, lo inicializamos
      if (!groupsMap.has(grupo.id)) {
        groupsMap.set(grupo.id, { ...grupo, items: [] });
      }

      // Añadimos el item al grupo correspondiente
      groupsMap.get(grupo.id)!.items.push(item);
    }
  });

  return Array.from(groupsMap?.values());
};

/**
 * Busca un item en una estructura de datos que tenga un parentId igual al id dado.
 *
 * @param {any} id - El id a buscar como parentId en los items.
 * @param {Array<Object>} items - La estructura de datos donde buscar.
 * @returns {Object|null} - El item que coincide con el parentId, o null si no se encuentra.
 */
export const findItemByParentId = (id: any, items: any) => {
  return (
    items.find((item: any) => item.data.parentIds.includes(Number(id))) || null
  );
};

/**
 * Busca el item en una estructura de datos que tenga el id igual al parentId dado.
 *
 * @param {any} id - El id a buscar como parentId en los items.
 * @param {Array<Object>} items - La estructura de datos donde buscar.
 * @returns {Object|null} - El item que coincide con el parentId, o null si no se encuentra.
 */
export const findItemById = (id: any, items: any) => {
  if (id) {
    return items.find((item: any) => item.id == id) || null;
  } else {
    return null;
  }
};

/**
 * @name checkIfNodeHasPendingEdges
 * @description Verifica si un nodo tiene conexiones pendientes en el almacenamiento local.
 * Si hay conexiones pendientes, se hace el llamado al back con la conexión utilizando putConnection
 * y elimina la conexión del almacenamiento.
 *
 * @param {string} nodeId - El ID del nodo que se está verificando.
 * @param {function} putConnection - Función que se utiliza para actualizar la conexión.
 * @param {number} nodeBackendId - El ID del nodo en el backend utilizado al actualizar la conexión.
 */
export const checkIfNodeHasPendingEdges = (
  nodeId: string,
  putConnection: any,
  nodeBackendId: number
) => {
  const pendingEdges =
    localStorage.getItem("pendingEdges") !== null
      ? JSON.parse(localStorage.getItem("pendingEdges") as string)
      : [];
  const pendingEdge = pendingEdges.find((edge: any) => edge.nodeId == nodeId);
  if (pendingEdge) {
    putConnection(undefined, [
      { ...pendingEdge.call, parent_ids: [nodeBackendId] },
    ]);
    localStorage.setItem(
      "pendingEdges",
      JSON.stringify(pendingEdges.filter((edge: any) => edge.nodeId !== nodeId))
    );
  }
};

/**
 * Busca un item en una estructura de datos que tenga un parentId igual al id dado.
 *
 * @param {any} id - El id a buscar como parentId en los items.
 * @param {Array<Object>} items - La estructura de datos donde buscar.
 * @returns {Object|null} - El item que coincide con el parentId, o null si no se encuentra.
 */
export const findNodeByParentId = (id: any, items: any) => {
  return items.find((item: any) => item.parent_ids.includes(id)) || null;
};

/**
 * Busca y retorna el edge que tenga
 * un nodo fuente (source) que coincida con el id proporcionado.
 *
 * @param {Array} edges - Un array de objetos, donde cada objeto debe
 *                        tener una propiedad 'source'.
 * @param {any} id - El identificador del nodo fuente que se busca.
 * @returns {Object | undefined} - Retorna el primer objeto que
 *                                  coincide con el id, o undefined si no se
 *                                  encuentra ninguno.
 */
export const findEdgeBySourceNode = (edges: any, id: any) => {
  return edges.find((edge: any) => edge.source === id);
};

/**  @name validateFlowName
 * @description Valida un nombre de flujo asegurando que no contenga los caracteres '/' o '\'.
 *
 * @param {string} name - El nombre del flujo que se desea validar.
 * @returns {boolean} - Retorna true si el nombre es válido, false en caso contrario.
 */
export const validateFlowName = (name: string) => {
  const regex = /^[^/\\]*$/;
  return regex.test(name);
};

/**
 * @function areAllSelectedNodesConnected
 * @description Verifica si todos los nodos en la estructura están conectados entre sí.
 * @param {Array} nodes - Array de nodos a verificar.
 * @param {Array} edges - Array de edges a verificar.
 * @returns {boolean} - Retorna true si todos los nodos están conectados, false en caso contrario.
 */

export function areAllSelectedNodesConnected(nodes: any[], edges: any[]) {
  // Crear un conjunto de IDs de nodos para facilitar la búsqueda
  const nodeIds = new Set(nodes.map((node) => node.id));

  // Función para encontrar si hay una conexión entre dos nodos
  const findConnections = (nodeId: any, visited: any) => {
    // Si ya se visitó, retornar
    if (visited.has(nodeId)) return;
    visited.add(nodeId);

    // Buscar aristas que salgan del nodo
    edges.forEach((edge) => {
      if (edge.source == nodeId && nodeIds.has(edge.target)) {
        findConnections(edge.target, visited);
      }
      if (edge.target == nodeId && nodeIds.has(edge.source)) {
        findConnections(edge.source, visited);
      }
    });
  };

  // Verificar conexiones a partir del primer nodo
  const visited = new Set();
  findConnections(nodes[0].id, visited);

  // Comprobar si todos los nodos han sido visitados
  return nodes.every((node) => visited.has(node.id));
}

/**
 * @function checkConcatConfigAndDeleteOldParents
 * @description Verifica si hay alguna fuente que haya sido eliminada del concat y en caso de que si elimina el edge
 * @param {Array} edges - Array de edges.
 * @param {any} concatId - Id del item concat.
 * @param {any} backendId - Backend id del item concat.
 * @param {any[]} parentIds - ParentIds del item concat.
 * @param {any} changeConnections - Función para actualizar el store.
 */
export const checkConcatConfigAndDeleteOldParents = (
  edges: any[],
  concatId: any,
  backendId: any,
  parentIds: any[] | undefined,
  changeConnections: any
) => {
  const edgesToDelete = edges.filter(
    (edge) =>
      (edge.target == concatId || edge.target == backendId) &&
      !parentIds?.includes(parseInt(edge.source))
  );

  // Si hay edges que eliminar, proceder a eliminarlos
  if (edgesToDelete.length > 0) {
    changeConnections(
      edgesToDelete.map((edge: any) => {
        return { id: edge.id, type: "remove" };
      })
    );
  }
};

/**
 * @function getParentIdsPutConnection
 * @description Arma el array de parentsIds para enviar al backend según los parents Ids de la conexión
 * @param {Array} parentIds - Parents ids previos del item.
 * @param {Array} connections - Array de conexiones.
 * @param {any[]} nodes - Los nodos actuales del flujo.
 */
export const getParentIdsPutConnection = (
  parentIds: any[],
  connections: any[],
  nodes: any[]
) => {
  const finalParentsIds: Set<number> = new Set(); // Usamos un Set para evitar duplicados
  
  // Agregar los parentIds previos
  if (parentIds?.length > 0) {
    parentIds.forEach((pid: any) => {
      finalParentsIds.add(parseInt(getBackendId(pid, nodes))); // Añadimos sin duplicados
    });
  }

  // Agregar las conexiones
  if (connections?.length > 0) {
    connections.forEach((connection: any) => {
      finalParentsIds.add(parseInt(getBackendId(connection.id, nodes))); // Añadimos sin duplicados
    });
  }

  // Convertir el Set de vuelta a un array antes de devolverlo
  return Array.from(finalParentsIds);
};