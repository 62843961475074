import { Box, TextField } from "@mui/material";

interface IResponsibleInput {
  value: string
  handleChangeResponsible: (responsible: any) => void;
  width?: string
}

const ResponsibleInput = ({ handleChangeResponsible, width, value }: IResponsibleInput) => {

  return (
    <Box
      sx={{
        width: width ? width :"100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <TextField
        label="Escribe el nombre del responsable"
        variant="outlined"
        value={value}
        onChange={(event: any)=> handleChangeResponsible(event.target.value)}
        fullWidth
      />
    </Box>
  );
};
export default ResponsibleInput;
