import { InputLabel, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Tab, TabValue } from "../../colorTabs/types";
import ColorTabs from "../../colorTabs/ColorTabs";
import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import { form_label } from "../../../styles/app-styles";
import { useLocation } from "react-router-dom";
import { getRoute } from "../../flowConfiguration/Modals/AddFont/utils";
import ExploreFolders from "./ExploreFolders";
import useApi from "../../../hooks/useApi";
import { DATA_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import UploadFileContent from "./UploadFileContent";
import SelectResourceLabel from "../ResourceLabels/SelectResourceLabel";
import { SingleValue } from "react-select";
import { IdOption } from "../../forms/types";
import { isValidName } from "../../schemas/utils";

interface UploadFileModalProps {
  open: boolean;
  handleClose: (resourceTabType?: string) => void;
  isDataManagementPage?: boolean;
  onSuccessPostFolder?: () => void;
  setIsLoadingPostFolder?: (isLoading: boolean) => void;
  areMultipleFiles?: boolean;
  folders?: any[];
  callRefetchResources?: ()=>void
}

const UploadFileModal = ({
  open,
  handleClose,
  isDataManagementPage,
  onSuccessPostFolder,
  setIsLoadingPostFolder,
  areMultipleFiles,
  folders,
  callRefetchResources
}: UploadFileModalProps) => {
  const [tabValue, setTabValue] = useState<TabValue>("CARGAR ARCHIVOS LOCALES");
  const [resourceTypetabValue, setResourceTypeTabValue] =
    useState<TabValue>("CARGAR ARCHIVOS");
  const [folderName, setFolderName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [externalLocationFiles, setExternalLocationFiles] = useState<any>([]);
  const [isCurrentLocation, setIsCurrentLocation] = useState<boolean>(true);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [dataFileState, setDataFileState] = useState<any>(null);
  const [label, setLabel] = useState<SingleValue<IdOption> | undefined>(
    undefined
  );
  const locationUrl = useLocation();
  


  /*************************** CARGA DE DATOS ***************************/

  const loadLocation = () => {
    if (locationUrl.pathname.split("/").pop() === "administracion-datos") {
      setLocation("Carpeta raíz");
    } else {
      setLocation(decodeURIComponent(getRoute(locationUrl.pathname)));
    }
  };
  //Seteamos la ubicación de la carpeta
  useEffect(() => {
    loadLocation();
  }, [locationUrl.pathname]);

  const tabs: Tab[] = isDataManagementPage
    ? [
        {
          value: "CARGAR ARCHIVOS LOCALES",
          label: "CARGAR ARCHIVOS LOCALES",
        },
      ]
    : [
        {
          value: "CARGAR ARCHIVOS LOCALES",
          label: "CARGAR ARCHIVOS LOCALES",
        },
        {
          value: "EXPLORAR ARCHIVOS",
          label: "EXPLORAR ARCHIVOS",
        },
      ];

  const resourceTypetabs: Tab[] =
    location !== "PHI"
      ? [
          {
            value: "CARGAR ARCHIVOS",
            label: "CARGAR ARCHIVOS",
          },
          {
            value: "CREAR CARPETA",
            label: "CREAR CARPETA",
          },
        ]
      : [
          {
            value: "CARGAR ARCHIVOS",
            label: "CARGAR ARCHIVOS",
          },
        ];

  /*************************** HANDLERS ***************************/

  const handleChangeResourceTab = (
    event: React.SyntheticEvent,
    newValue: TabValue
  ) => {
    setResourceTypeTabValue(newValue);
  };

  const handleChangeIsCurrentLocation = (location: boolean) => {
    setIsCurrentLocation(location);
    if (location) {
      if (locationUrl.pathname.split("/").pop() === "administracion-datos") {
        setLocation("Carpeta raíz");
      } else {
        setLocation(getRoute(locationUrl.pathname));
      }
    } else {
      setLocation("Carpeta raíz");
    }
  };

  const handleChangeSelectedFolder = (location: string) => {
    if (location === "") {
      setLocation("Carpeta raíz");
    } else {
      setLocation(location);
    }
  };

  const isErrorFolderName = () => {
    return (
      folderName === "" ||
      (isCurrentLocation && !isValidName(folders, folderName)) ||
      (!isCurrentLocation &&
        isValidName(
          externalLocationFiles?.filter((file: any) => file.tipo === "carpeta"),
          folderName
        ))
    );
  };

  const getFolderNameHelperText = () => {
    if (isCurrentLocation) {
      return !isValidName(folders, folderName)
        ? "Ya existe una carpeta con este nombre en esta ubicación"
        : "";
    }else{
      return !isValidName(externalLocationFiles?.filter((file: any) => file.tipo === "carpeta"), folderName)
      ? "Ya existe una carpeta con este nombre en esta ubicación"
      : "";
    }
  };

  /********************************* LLAMADAS ************************************************/

  const refetchResources = () => {
    onSuccessPostFolder && onSuccessPostFolder();
  };

  const {
    error: errorPostFolder,
    isLoading: isLoadingPostFolder,
    callApi: postFolder,
  } = useApi(
    DATA_URL,
    "POST",
    texts.dataManagement.postFolder.codes,
    {
      tipo: "carpeta",
      nombre: folderName,
      destino: location === "Carpeta raíz" ? "" : location,
      etiqueta: label?.value,
    },
    refetchResources,
    undefined
  );

  useEffect(() => {
    setIsLoadingPostFolder && setIsLoadingPostFolder(isLoadingPostFolder);
  }, [isLoadingPostFolder]);

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const handleAccept = () => {
    if (resourceTypetabValue === "CREAR CARPETA") {
      postFolder();
    }
    handleClose(resourceTypetabValue);
    cleanAndClose();
  };

  const cleanAndClose = () => {
    handleClose();
    setTabValue("CARGAR ARCHIVOS LOCALES");
    setFolderName("");
    setResourceTypeTabValue("CARGAR ARCHIVOS");
    setDataFileState(null);
    setIsCurrentLocation(true);
    areMultipleFiles ? setSelectedFile([]) : setSelectedFile("");
    loadLocation();
  };

  const isFormCompleted = () => {
    if (resourceTypetabValue === "CREAR CARPETA") {
      return folderName !== "";
    } else {
      return dataFileState?.length > 0;
    }
  };

  return (
    <Dialog
      open={open}
      title={isDataManagementPage ? "Cargar recursos" : "Cargar archivos"}
      handleClose={cleanAndClose}
      maxWidth="xl"
    >
      {isDataManagementPage ? (
        <Stack sx={{ width: "50vw", height: "30vw" }}>
          <Stack
            width="100%"
            justifyContent={"flex-start"}
            margin="0 !important"
          >
            <ColorTabs
              value={resourceTypetabValue}
              handleChange={handleChangeResourceTab}
              tabs={resourceTypetabs}
              marginBottom="8px"
            />
          </Stack>
          {resourceTypetabValue === "CARGAR ARCHIVOS" && (
            <UploadFileContent
              isDataManagementPage
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              areMultipleFiles
              dataFileState={dataFileState}
              setDataFileState={setDataFileState}
              handleCloseModal={handleClose}
              callRefetchResources={callRefetchResources}
            />
          )}
          {resourceTypetabValue === "CREAR CARPETA" && (
            <>
              <InputLabel sx={form_label}>Nombre de la carpeta</InputLabel>
              <TextField
                id="outlined-basic"
                helperText={
                  getFolderNameHelperText()
                }
                error={isErrorFolderName()}
                size="small"
                variant="outlined"
                fullWidth
                label=""
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                placeholder={`Nombre de la carpeta`}
                autoComplete="off"
                InputLabelProps={{
                  style: {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "100%",
                    color: "var(--blue)",
                  },
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                    margin: 0,
                    backgroundColor: "var(--very-very-light-grey)",
                  },
                }}
              />
              <Stack
                direction="row"
                gap={2}
                width="100%"
                sx={{ marginBottom: "10px", marginTop: "20px" }}
              >
                <Typography sx={form_label}>
                  Ubicación de la carpeta:
                </Typography>
                <label htmlFor="actualLocation">
                  <input
                    type="radio"
                    id="actualLocation"
                    name="location"
                    onChange={() => handleChangeIsCurrentLocation(true)}
                    checked={isCurrentLocation === true}
                  />
                  Ubicación actual
                </label>

                <label htmlFor="otherLocation">
                  <input
                    type="radio"
                    id="otherLocation"
                    name="location"
                    onChange={() => handleChangeIsCurrentLocation(false)}
                    checked={isCurrentLocation === false}
                  />
                  Otra ubicación
                </label>
              </Stack>

              {!isCurrentLocation && (
                <Stack sx={{ marginTop: "10px" }}>
                  <InputLabel sx={form_label}>
                    Seleccione la ubicación de la nueva carpeta
                  </InputLabel>
                  <ExploreFolders
                    handleChangeSelectedFolder={handleChangeSelectedFolder}
                    setResourcesData={setExternalLocationFiles}
                  />
                </Stack>
              )}
              <SelectResourceLabel setLabel={setLabel} />
            </>
          )}
          <Stack
            direction="row"
            sx={{
              alignSelf: "center",
              marginTop: "auto",
              justifyContent: "center",
            }}
          >
            <Button
              title="Cerrar"
              type="button"
              onClick={cleanAndClose}
              color="light-grey"
            />
            <Button
              title="Aceptar"
              type="button"
              onClick={handleAccept}
              color="blue"
              disabled={!isFormCompleted()}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack sx={{ width: "50vw", height: "30vw" }}>
          <UploadFileContent
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            areMultipleFiles={areMultipleFiles}
            dataFileState={dataFileState}
            setDataFileState={setDataFileState}
            callRefetchResources={callRefetchResources}
          />
          <Stack
            direction="row"
            sx={{
              alignSelf: "center",
              marginTop: "auto",
              justifyContent: "center",
            }}
          >
            <Button
              title="Cerrar"
              type="button"
              onClick={cleanAndClose}
              color="light-grey"
            />
          </Stack>
        </Stack>
      )}
    </Dialog>
  );
};

export default UploadFileModal;
