import { Box, Card, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
interface ItemBarProps {
  title: string;
  onClick: ()=> void;
  disabled: boolean;
}

const ItemBar = ({ title, onClick, disabled }: ItemBarProps) => {
  return (
    <Box
     onClick={disabled ? undefined : onClick}
      sx={{
        position: "relative",
        borderRadius: "20px",
        boxShadow: disabled ? "none" : 2,
        "&:hover": {
          cursor: disabled ? "auto" : "pointer",
          boxShadow: disabled ? "none" : 8,
        },
      }}
    >
      <Card
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "76px",
          height: "76px",
          padding: 2,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
          WebkitUserSelect: "none",
          backgroundColor: disabled ? "var(--very-light-grey)" : "var(--very-very-light-grey)"
        }}
      >
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "center",
              color: disabled ? "var(--light-grey)" : "var(--blue)",
              textTransform: "uppercase",
              fontFamily: "Verdana",
              fontSize: title === "Renombrar columnas" || title === "Seleccionar columnas"? "0.61rem" : "0.65rem",
              fontWeight: "600",
            }}
          >
            {title}
          </Typography>
      </Card>
      <Box sx={{
        position: "absolute",
        bottom: -9,
        right: -10,
        backgroundColor: "var(--very-very-light-grey)",
        width:"30px",
        height:"28px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <AddCircleIcon
          fontSize="large"    
          sx={{color: disabled ? "var(--light-grey)" : "var(--blue)"}}
        />
      </Box>
    </Box>
  );
};

export default ItemBar;
