import Stack from "@mui/material/Stack";
import ItemBar from "../itemBar/ItemBar";
import useDialog from "../../../hooks/useDialog";
import { useState } from "react";
import { Tool } from "./types.js";

import { Collapse, Divider, Typography } from "@mui/material";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts.js";
import {
  FLOW_ITEMS,
} from "../../../api/axios";
import {
  bar_container_styles,
  bar_items_container_styles,
  form_label,
  item_container_styles,
  toolsbar_buttons_container_styles,
} from "../../../styles/app-styles";
import ChangeFonts from "../Modals/ChangeFonts";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useStore } from "../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
import { ConfirmationModal } from "../../dialog";
import { categorias } from "./tools";

const selector = (store: any) => ({
  createNode: store.createNode,
  nodes: store.nodes,
  flow: store.flow
});

const ToolsBar = () => {

  const [openToolbar, setOpenToolbar] = useState(true);
  const [openChangeFontsModal, handleToggleChangeFontsModal] = useDialog();
  const [openConfirmSaveFlow, toggleConfirmSaveFlow] = useDialog();

  const store = useStore(selector, shallow);

  /***************************** FUNCIONES MODALES Y BAR *****************************/

  type ToolFunctions = {
    [key: string]: () => void;
  };

  const toolFunctions: ToolFunctions = {
    nueva_fuente: () => {
      store.createNode("font", {});
    },
    cambiar_fuentes: handleToggleChangeFontsModal,
    nueva_columna: () => {
      store.createNode("new-column", {});
    },
    eliminar_columna: () => {
      store.createNode("delete-column", {});
    },
    modificar_columna: () => {
      store.createNode("modify-column", {});
    },
    merge: () => {
      store.createNode("merge", {});
    },
    group_by: () => {
      store.createNode("group-by", {});
    },
    concat: () => {
      store.createNode("concat", {});
    },
    eliminar_filas: () => {
      store.createNode("delete-rows", {});
    },
    filtrar: () => {
      store.createNode("filter", {});
    },
    renombrar_columnas: () => {
      store.createNode("rename-columns", {});
    },
    dinamizar: () => {
      store.createNode("dinamize", {});
    },
    desdinamizar: () => {
      store.createNode("desdinamize", {});
    },
    setear_cabecera: () => {
      store.createNode("set-header", {});
    },
    calcular: () => {
      store.createNode("calculate", {});
    },
    seleccionar_columnas: () => {
      store.createNode("select-columns", {});
    },
  };

  const getToolFunction = (tool: Tool) => toolFunctions[tool];

  const everyToolIsDisabled = (category: any) => {
    return category.tools.every(
      (tool: any) => tool.disabled === true || tool.separator === true
    );
  };


  /*const { data: dataFlujos } = useApiQuery(
    GET_FLOW_TASKS_URL,
    true,
    texts.taskManager.getTasks.error
  );*/

  const {
    isLoading: isLoadingPutConnection,
    callApi: putConnection,
    error: errorPutConnection,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );


  return (
    <>
      <Typography
        sx={{
          ...form_label,
          fontSize: "18px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          textTransform: "uppercase",
        }}
        onClick={() => {
          setOpenToolbar(!openToolbar);
        }}
      >
        Herramientas
        {openToolbar ? <ExpandLess /> : <ExpandMore />}
      </Typography>
      { (
        <Collapse in={openToolbar} timeout={10} sx={{ width: "100%" }}>
          <Stack sx={bar_container_styles}>
      
            {categorias.map((categoria: any) => {
              return (
                <Stack
                  key={categoria.categoria}
                  sx={bar_items_container_styles(
                    everyToolIsDisabled(categoria)
                  )}
                >
                  <Stack sx={item_container_styles}>
                    {categoria.tools.map((tool: any) => {
                      if (tool.separator) {
                        return (
                          <Divider
                            key={tool.label}
                            orientation="vertical"
                            flexItem
                            sx={{
                              bgColor: "var(--blue)",
                            }}
                          />
                        );
                      }
                      return (
                        <ItemBar
                          key={tool.label}
                          title={tool.label}
                          onClick={getToolFunction(tool.tipo)}
                          disabled={tool.disabled === true}
                        />
                      );
                    })}
                  </Stack>
                </Stack>
              );
            })}
            <Stack>
              <ChangeFonts
                open={openChangeFontsModal}
                handleClose={handleToggleChangeFontsModal}
              />
            </Stack>
            <Stack sx={toolsbar_buttons_container_styles}>
             {/*
             <Button
                title="Guardar flujo"
                color="blue"
                type="button"
                margin={true}
                onClick={toggleConfirmSaveFlow}
                disabled={false}
              />
             */} 
              <ConfirmationModal
                open={openConfirmSaveFlow}
                handleClose={toggleConfirmSaveFlow}
                handleAccept={() => {
                ()=>console.log("cambiar")
                }}
                title="Guardar cambios"
                message={"guardar el flow"}
              />
            </Stack>
          </Stack>
        </Collapse>
      )}
    </>
  );
};

export default ToolsBar;
