import { Menu, MenuItem, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { DATA_URL, DOWNLOAD_FILE } from "../../api/axios";
import { texts } from "../../texts";
import SaveNameModal from "../dialog/SaveNameModal";
import {
  cutFileExtension,
  formatResourceUsesData,
  getFileExtension,
  handleClickVariant,
} from "../../utils/util";
import useDialog from "../../hooks/useDialog";
import MoveResourceModal from "./MoveResourceModal";
import { ConfirmationModal } from "../dialog";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import EditResourceLabelModal from "./ResourceLabels/EditResourceLabelModal";
import { Label } from "./ResourceLabels/types";
import FileDetailModal from "./FileDetailModal";
import DataManagementProvider from "../../context/DataManagementContext";
import { useSnackbar } from "notistack";

interface DataDropdownMenuProps {
  anchorEl: null | HTMLElement;
  name: string;
  type: string;
  folderLocation: string;
  handleCloseUserMenu: () => void;
  refreshResources?: () => void;
  date: string;
  modifiedDate?:string
  label?: Label;
  size?: number;
  id?: number;
  isExternalResource?: boolean;
  resourceId?:number;
}

const DataDropdownMenu = ({
  name,
  type,
  folderLocation,
  refreshResources,
  anchorEl,
  handleCloseUserMenu,
  label,
  date,
  size,
  id,
  isExternalResource,
  resourceId,
  modifiedDate
}: DataDropdownMenuProps) => {
  const [editName, setEditName] = useState(cutFileExtension(name));
  const [dataDelete, setDataDelete] = useState<any>(undefined);
  const { DataState } = useContext<
    React.ContextType<typeof DataManagementProvider>
  >(DataManagementProvider);
  const { folderFiles } = DataState;
  const [openEditNameModal, toggleOpenEditNameModal] = useDialog();
  const [openMoveResourceModal, toggleOpenMoveResourceModal] = useDialog();
  const [openDetailModal, toggleDetailModal] = useDialog();
  const [openEditResourceLabelModal, toggleOpenEditResourceLabelModal] =
    useDialog();
  const [openConfirmDeleteResourceModal, toggleConfirmDeleteResourceModal] =
    useDialog();
  const [openDeleteWarningModal, toggleConfirmDeleteWarningModal] = useDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  useEffect(() => {
    if (dataDelete) {
      toggleConfirmDeleteWarningModal();
    }
  }, [dataDelete]);

  /*********** Menú desplegable *****************/

  const settings =
    type === "archivo"
      ? [
          "Ver detalle",
          "Editar nombre",
          // "Editar permisos",
          `Mover ${type}`,
          "Descargar",
          "Editar etiqueta",
          // "Ver trazabilidad del archivo",
          `Eliminar ${type}`,
          `Copiar ubicación del ${type}`,
        ]
      : name.toUpperCase() !== "PHI" ? [
          "Ver detalle",
          "Editar nombre",
          // "Editar permisos",
         `Mover ${type}`,
          "Descargar",
          "Editar etiqueta",
          `Eliminar ${type}`,
          `Copiar ubicación de la ${type}`,
        ] :  [
          "Ver detalle",
          // "Editar permisos",
          "Descargar",
          "Editar etiqueta",
          `Copiar ubicación de la ${type}`,
        ] ;

  const handleSetting = (setting: string) => {
    handleCloseUserMenu();
    switch (setting) {
      case "Ver detalle":
        toggleDetailModal();
        break;
      case "Descargar":
        downloadResource();
        break;
      case `Eliminar ${type}`:
        toggleConfirmDeleteResourceModal();
        break;
      case "Editar nombre":
        toggleOpenEditNameModal();
        break;
      case `Mover ${type}`:
        toggleOpenMoveResourceModal();
        break;
      case "Mover carpeta":
        toggleOpenMoveResourceModal();
        break;
      case "Editar etiqueta":
        toggleOpenEditResourceLabelModal();
        break;
        case  `Copiar ubicación de la ${type}`:
          case  `Copiar ubicación del ${type}`:
          handleCopyClick();
          break;
      default:
        break;
    }
  };

  
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(folderLocation+name);
      handleClickVariant(
        `Ubicación copiada`,
        "success",
        enqueueSnackbar,
        closeSnackbar
      );
    } catch (error) {
      handleClickVariant(
        `Ha ocurrido un error al copiar la ubicación`,
        "error",
        enqueueSnackbar,
        closeSnackbar
      );
    }
  };

  /******************************* LLAMADAS ************************************/

  const { isLoading: isLoadingEditName, callApi: callEditName } = useApi(
    DATA_URL,
    "PUT",
    texts.dataManagement.editResource.codes,
    {
      lista_recursos: [
        {
          ruta: `${folderLocation}${name}`,
          nuevo_nombre: `${folderLocation}${editName.trim()}${
            type === "archivo" ? getFileExtension(name) : ""
          }`,
          tipo: type,
        },
      ],
    },
    () => {
      refreshResources && refreshResources();
    },
    undefined,
    false
  );

  const { isLoading: isLoadingDeleteResource, callApi: deleteResource } =
    useApi(
      DATA_URL,
      "DELETE",
      texts.dataManagement.deleteResource.codes,
      {
        eliminar: false,
        lista_recursos: [
          {
            directorio: type !== "archivo",
            id: id !== undefined && id !== null ? id : undefined,
            path:
              id === undefined || id === null
                ? `${folderLocation}${name}`
                : undefined,
          },
        ],
      },
      () => {
        refreshResources && refreshResources();
      },
      (data: any) => {
        onErrorDeleteResource(data);
      },
      false
    );

  const { isLoading: isLoadingDownloadResource, callApi: downloadResource } =
    useApi(
      DOWNLOAD_FILE,
      "POST",
      texts.dataManagement.downloadResource.codes,
      {
        lista_archivos: [
          {
            tipo: type,
            ruta: `${folderLocation}${name}`,
          },
        ],
      },
      undefined,
      undefined,
      false,
      undefined,
      true,
      {
        fileName: `${name}${type === "archivo" ? "" : ".zip"}`,
        fileType: `${
          type === "archivo" ? getFileExtension(name) : "aplication/zip"
        }`,
      }
    );

  const handleDeleteResource = (isWarning: boolean) => {
    if (isWarning) {
      toggleConfirmDeleteWarningModal();
      deleteResource(undefined, {
        eliminar: true,
        lista_recursos: [
          {
            directorio: type !== "archivo",
            id: id !== undefined && id !== null ? id : undefined,
            path:
              id === undefined || id === null
                ? `${folderLocation}${name}`
                : undefined,
          },
        ],
      });
    } else {
      toggleConfirmDeleteResourceModal();
      deleteResource();
    }
  };

  const onErrorDeleteResource = (data: any) => {
    setDataDelete(data);
  };

  /********************* HANDLERS ***************** */

  const handleAcceptEditName = () => {
    toggleOpenEditNameModal();
    callEditName();
  };

  const handleMoveResource = (newLocation: string) => {
    callEditName(undefined, {
      lista_recursos: [
        {
          ruta: `${folderLocation}${name}`,
          nuevo_destino: `${
            newLocation === "Carpeta raíz" ? "" : newLocation
          }${name}`,
          tipo: type,
        },
      ],
    });
  };

  const handleCloseEditLabel = (button: string) => {
    toggleOpenEditResourceLabelModal();
   // button === "aceptar" && refreshResources && refreshResources();
  };

  const validateEditName = (name: string) => {
    if (name.startsWith("_") && type ==="carpeta"){
      return {error: true, message: "El nombre de la carpeta no puede comenzar con _ (guión bajo)"}
    }else{
      return {error: false}
    }
  };

  const getDisabled = (setting: string) => {
    return folderLocation.startsWith("PHI/") && (setting === "Editar nombre" || setting.startsWith("Eliminar") || (setting.startsWith("Mover") && type==="carpeta"))
  }

  return (
    <>
      <SimpleBackdrop
        open={isLoadingDeleteResource}
        message={texts.dataManagement.deleteResource.loading}
      />
      <SimpleBackdrop
        open={isLoadingEditName}
        message={texts.dataManagement.editResource.loading}
      />
      <SimpleBackdrop
        open={isLoadingDownloadResource}
        message={texts.dataManagement.downloadResource.loading}
      />

      <Menu
        sx={{ mt: "45px", zIndex: "200" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting: string) => (
          <MenuItem key={setting} onClick={() => handleSetting(setting)} disabled={getDisabled(setting)}>
            <Typography
              textAlign="center"
              sx={{
                color: "var(--greeny)",
              }}
            >
              {setting}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <FileDetailModal
        name={name}
        type={type}
        id={id}
        date={date}
        modifiedDate={modifiedDate ? modifiedDate : ""}
        size={size}
        label={label}
        fileLocation={folderLocation}
        open={openDetailModal}
        handleClose={toggleDetailModal}
        folderLocation={folderLocation}
        refreshResources={refreshResources}
        isExternalResource={isExternalResource}
        resourceId={resourceId}
      />
      <SaveNameModal
        open={openEditNameModal}
        handleClose={toggleOpenEditNameModal}
        handleAccept={handleAcceptEditName}
        title={"Editar nombre"}
        text={`Editar nombre ${
          type === "carpeta" ? " de la carpeta " + name : "del archivo " + name
        }`}
        label={"Nombre del archivo"}
        placeholder={"Nombre del archivo"}
        dispatchFunction={setEditName}
        valueState={editName}
        namesList={folderFiles}
        customValidationFunction={validateEditName}
        editName={name}
      />
      <MoveResourceModal
        open={openMoveResourceModal}
        name={name}
        type={type}
        folderLocation={`${folderLocation}${name}`}
        handleClose={toggleOpenMoveResourceModal}
        handleAccept={handleMoveResource}
      />
      <EditResourceLabelModal
        open={openEditResourceLabelModal}
        name={name}
        route={folderLocation}
        type={type}
        handleClose={handleCloseEditLabel}
        selectedLabel={label}
        refreshResources={refreshResources}
      />
      <ConfirmationModal
        open={openConfirmDeleteResourceModal}
        handleClose={toggleConfirmDeleteResourceModal}
        handleAccept={() => {
          handleDeleteResource(false);
        }}
        title="Eliminar recurso"
        message={`eliminar ${
          type === "carpeta" ? "la carpeta " + name : "el archivo " + name
        }`}
      />
      <ConfirmationModal
        open={openDeleteWarningModal}
        handleClose={toggleConfirmDeleteWarningModal}
        handleAccept={() => {
          handleDeleteResource(true);
        }}
        title="Eliminar recurso"
        message={`¿Confirma que desea eliminar ${
          type === "carpeta" ? "la carpeta " + name : "el archivo " + name
        }? Esta siendo utilizado en: ${formatResourceUsesData(
          dataDelete?.modelos_en_uso
        )}`}
        customMessage
      />
    </>
  );
};

export default DataDropdownMenu;
