import ComponentLayout from "../layout/ComponentLayout";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import useApi from "../../hooks/useApi";
import {
  ALLOWED_FILES_URL,
  CALCULATED_PERIODS,
  CONTROL_TABLE,
  TABLE_DETAIL,
} from "../../api/axios";
import { texts } from "../../texts";
import { useEffect, useRef, useState } from "react";
import {
  deleteSlash,
  filterPeridos,
  handleGroupByTemplate,
  handleGroupByYear,
  handleGroupTotal,
  orderTableKeys,
  transformDataSimpleTable,
} from "./utils";
import { useApiQuery } from "../../hooks/useApiQuery";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import ControlTable from "./ControlTable";
import useDialog from "../../hooks/useDialog";
import ConfigsModal from "./ConfigsModal";
import Configs from "./Configs";
import { Box, Stack, Typography } from "@mui/material";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import useSingleSelect from "../../hooks/useSingleSelect";
import Select from "../forms/Select";
import { selectStyles } from "../../styles/select.styles";
import { SingleValue } from "react-select";
import { IdOption } from "../forms/types";
import FrecuencyModal from "./FrecuencyModal";

const DataControl = () => {
  const [openModal, handleToggleOpenModal] = useDialog();
  const [openLocationModal, handleToggleLocationOpenModal] = useDialog();
  const [openDetailModal, handleToggleDetailModal] = useDialog();
  const [openFrecuencyModal, handleToggleFrecuencyModal] = useDialog();
  const [selectedPeriod, setSelectedPeriod] = useState<any>();
  const [selectedDetail, setSelectedDetail] = useState<any>();
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const rowsRef = useRef<any[]>([]);
  const columnsRef = useRef<any[]>([]);
  const isGroupedByTotal = columns.find(
    (col: any) => col.field.toUpperCase() === "TOTAL"
  );
  const [
    frecuencySelectedOption,
    setfrecuencySelectedOption,
    frecuencyOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    calculatedPeriodsSelectedOption,
    setCalculatedPeriodsSelectedOption,
    calculatedPeriodsChangeHandler,
  ] = useSingleSelect();

  const frecuencyOptions = [
    {value: "3 meses", label: "Últimos 3 meses "},
    {value: "n meses", label: "Últimos n meses "},
    {value: "Todo", label: "Todos los períodos "}
  ]

  const calculatedOptions = [
    {value: "Todos los períodos", label: "Todos los períodos"},
    {value: "Sólo períodos sin calcular", label: "Sólo períodos sin calcular"}
  ]
  /********************* LLAMADAS **************************/

  const {
    data: table,
    isLoading: isLoadingTable,
    refetch: refetchTable,
  } = useApiQuery(
    CONTROL_TABLE,
    false,
    texts.dataManagement.getAllowedFileNames.error
  );

  const {
    isLoading: isLoadingGetCalculatedPeriods,
    callApi: getCalculatedPeriods,
    data: dataPeriods
  } = useApi(
    CALCULATED_PERIODS,
    "GET",
    texts.dataControl.getCalculatedPeriods.codes,
    undefined,
    undefined,
    undefined,
    false,
  );

    useEffect(() => {
    getCalculatedPeriods();
  }, []);
  

  useEffect(() => {
    if (table?.length > 0) {
      setRows(transformDataSimpleTable(table).rows);
      rowsRef.current = transformDataSimpleTable(table).rows;
      setColumns(
        orderTableKeys(
          transformDataSimpleTable(
            table,
            undefined,
            undefined,
            undefined,
            table,
            handleClickOnEdit,
            handleClickOnEditLocationModal,
            handleGroupTemplate,
            handleGroupByPeriod,
            handleClickOnDetailModal
          ).columns
        )
      );
      columnsRef.current = orderTableKeys(
        transformDataSimpleTable(
          table,
          undefined,
          undefined,
          undefined,
          table,
          handleClickOnEdit,
          handleClickOnEditLocationModal,
          handleGroupTemplate,
          handleGroupByPeriod,
          handleClickOnDetailModal
        ).columns
      );
    }
  }, [table]);

  useEffect(() => {
    if (table?.length > 0 && dataPeriods?.calculados?.length > 0) {
      setRows(transformDataSimpleTable(table).rows);
      rowsRef.current = transformDataSimpleTable(table).rows;
      setColumns(
        orderTableKeys(
          transformDataSimpleTable(
            filterPeridos(dataPeriods?.calculados, table),
            undefined,
            undefined,
            undefined,
            table,
            handleClickOnEdit,
            handleClickOnEditLocationModal,
            handleGroupTemplate,
            handleGroupByPeriod,
            handleClickOnDetailModal
          ).columns
        )
      );
      columnsRef.current = orderTableKeys(
        transformDataSimpleTable(
          table,
          undefined,
          undefined,
          undefined,
          table,
          handleClickOnEdit,
          handleClickOnEditLocationModal,
          handleGroupTemplate,
          handleGroupByPeriod,
          handleClickOnDetailModal
        ).columns
      );
    }
  }, [dataPeriods,table]);

  useEffect(() => {
    if (table?.length > 0) {
      if (rows.length > 0) {
        rowsRef.current = rows;
      } else {
        rowsRef.current = transformDataSimpleTable(table).rows;
      }
    }
  }, [rows]);

  useEffect(() => {
    if (table?.length > 0) {
      if (columns.length > 0) {
        columnsRef.current = columns;
      }
    }
  }, [columns]);

  const { isLoading: isLoadingPutTable, callApi: putTable } = useApi(
    CONTROL_TABLE,
    "PUT",
    texts.dataControl.sendConfig.codes,
    undefined,
    refetchTable,
    undefined,
    false
  );

  const onSuccesGetDetailModal = (data: any) => {
    if (data?.archivo_nombre) {
      setSelectedDetail(data);
      handleToggleDetailModal();
    }
  };

  const { isLoading: isLoadingDetailModal, callApi: getDetail } = useApi(
    TABLE_DETAIL,
    "GET",
    texts.dataControl.sendConfig.codes,
    undefined,
    onSuccesGetDetailModal,
    undefined,
    false
  );

  /***************************************** HANDLERS ********************************************************** */

  const handleClickOnEdit = (row: number, period: string) => {
    setSelectedPeriod({ id: row, periodo: period });
    handleToggleOpenModal();
  };

  const handleClickOnEditLocationModal = (row: number, location: string) => {
    setSelectedPeriod({ id: row, ruta_directorio: location });
    handleToggleLocationOpenModal();
  };

  const handleAcceptModal = (state: string) => {
    putTable(undefined, {
      ...selectedPeriod,
      estado: state,
    });
    handleToggleOpenModal();
  };

  const handleAcceptLocationModal = (location: string) => {
    putTable(undefined, {
      id: selectedPeriod.id,
      ruta_directorio: deleteSlash(location),
    });
    handleToggleLocationOpenModal();
  };

  const handleGroupTemplate = (template: string) => {
    handleGroupByTemplate(template, rowsRef.current, setRows);
  };

  const handleGroupByPeriod = (period: string) => {
    handleGroupByYear(
      period,
      rowsRef.current,
      setRows,
      setColumns,
      handleClickOnEdit,
      handleGroupTemplate,
      handleGroupByPeriod
    );
  };

  const handleGroupByTotal = () => {
    handleGroupTotal(
      rowsRef.current,
      setRows,
      setColumns,
      handleClickOnEdit,
      handleGroupTemplate,
      handleGroupByPeriod
    );
  };

  const handleDisgroupByTotal = () => {
    setRows(transformDataSimpleTable(table).rows);
    rowsRef.current = transformDataSimpleTable(table).rows;
    setColumns(
      orderTableKeys(
        transformDataSimpleTable(
          table,
          undefined,
          undefined,
          undefined,
          table,
          handleClickOnEdit,
          handleClickOnEditLocationModal,
          handleGroupTemplate,
          handleGroupByPeriod,
          handleClickOnDetailModal
        ).columns
      )
    );
    columnsRef.current = orderTableKeys(
      transformDataSimpleTable(
        table,
        undefined,
        undefined,
        undefined,
        table,
        handleClickOnEdit,
        handleClickOnEditLocationModal,
        handleGroupTemplate,
        handleGroupByPeriod,
        handleClickOnDetailModal
      ).columns
    );
  };

  const handleClickOnDetailModal = (row: number, period: string) => {
    getDetail(TABLE_DETAIL(row, period));
  };

  const handlerFilterByFrecuency = (frecuency: SingleValue<IdOption>, action: any) => {
    frecuencyOptionsChangeHandler(frecuency, action)
    if(frecuency?.value === "n meses"){
      handleToggleFrecuencyModal()
      return
    }
    if(frecuency?.value === "3 meses"){
      return rows.map((item: any) => {
        // Obtener las claves de los periodos y ordenarlas
        const periodos = Object.keys(item).filter(key => key !== 'id' && key !== 'TEMPLATE' && key !== 'RESPONSABLE' && key !== 'ARCHIVOS_FUENTES' && key !== 'archivo_fuente_id' && key !== 'RUTA_DIRECTORIO' && key !== 'CRITICO');
    
        // Ordenar los periodos de mayor a menor
        periodos.sort((a, b) => {
          const [mesA, anioA] = a.split('-').map(Number);
          const [mesB, anioB] = b.split('-').map(Number);
          return anioB - anioA || mesB - mesA;
        });
    
        // Tomar los últimos 3 periodos
        const ultimosTresPeriodos = periodos.slice(0, 3);
    
        // Crear un nuevo objeto con los últimos tres periodos
        const nuevoObjeto = {
          ...item,
          ...ultimosTresPeriodos.reduce((acc: any, periodo: any) => {
            acc[periodo] = item[periodo];
            return acc;
          }, {})
        };
    
        // Eliminar las claves no relacionadas con los periodos
        Object.keys(nuevoObjeto).forEach(key => {
          if (key !== 'id' && key !== 'TEMPLATE' && key !== 'RESPONSABLE' && key !== 'ARCHIVOS_FUENTES' && key !== 'archivo_fuente_id' && key !== 'RUTA_DIRECTORIO' && key !== 'CRITICO' && !ultimosTresPeriodos.includes(key)) {
            delete nuevoObjeto[key];
          }
        });
    
        setColumns(orderTableKeys(
          transformDataSimpleTable(
            [nuevoObjeto],
            undefined,
            undefined,
            undefined,
            [nuevoObjeto],
            handleClickOnEdit,
            handleClickOnEditLocationModal,
            handleGroupTemplate,
            handleGroupByPeriod,
            handleClickOnDetailModal
          ).columns
        ))
      });
    }else{
      setColumns(orderTableKeys(
        transformDataSimpleTable(
          table,
          undefined,
          undefined,
          undefined,
          table,
          handleClickOnEdit,
          handleClickOnEditLocationModal,
          handleGroupTemplate,
          handleGroupByPeriod,
          handleClickOnDetailModal
        ).columns
      ))
    }
    
  };

  const handleFilterByCalculated = (calculated: SingleValue<IdOption>, action: any) => {
  calculatedPeriodsChangeHandler(calculated, action)
  if(calculated?.value === "Sólo períodos sin calcular" || calculated === null || calculated === undefined){
    setColumns(
      orderTableKeys(
        transformDataSimpleTable(
          filterPeridos(dataPeriods?.calculados, table),
          undefined,
          undefined,
          undefined,
          table,
          handleClickOnEdit,
          handleClickOnEditLocationModal,
          handleGroupTemplate,
          handleGroupByPeriod,
          handleClickOnDetailModal
        ).columns
      )
    );
  }else{
    setColumns(
      orderTableKeys(
        transformDataSimpleTable(
          table,
          undefined,
          undefined,
          undefined,
          table,
          handleClickOnEdit,
          handleClickOnEditLocationModal,
          handleGroupTemplate,
          handleGroupByPeriod,
          handleClickOnDetailModal
        ).columns
      )
    );
  }
  }

  const handleAcceptFrecuencyModal = (frecuency: string)=>{
    handleToggleFrecuencyModal()
      return rows.map((item: any) => {
        // Obtener las claves de los periodos y ordenarlas
        const periodos = Object.keys(item).filter(key => key !== 'id' && key !== 'TEMPLATE' && key !== 'RESPONSABLE' && key !== 'ARCHIVOS_FUENTES' && key !== 'archivo_fuente_id' && key !== 'RUTA_DIRECTORIO' && key !== 'CRITICO');
    
        // Ordenar los periodos de mayor a menor
        periodos.sort((a, b) => {
          const [mesA, anioA] = a.split('-').map(Number);
          const [mesB, anioB] = b.split('-').map(Number);
          return anioB - anioA || mesB - mesA;
        });
    
        // Tomar los últimos 3 periodos
        const ultimosTresPeriodos = periodos.slice(0, parseInt(frecuency));
    
        // Crear un nuevo objeto con los últimos tres periodos
        const nuevoObjeto = {
          ...item,
          ...ultimosTresPeriodos.reduce((acc: any, periodo: any) => {
            acc[periodo] = item[periodo];
            return acc;
          }, {})
        };
    
        // Eliminar las claves no relacionadas con los periodos
        Object.keys(nuevoObjeto).forEach(key => {
          if (key !== 'id' && key !== 'TEMPLATE' && key !== 'RESPONSABLE' && key !== 'ARCHIVOS_FUENTES' && key !== 'archivo_fuente_id' && key !== 'RUTA_DIRECTORIO' && key !== 'CRITICO' && !ultimosTresPeriodos.includes(key)) {
            delete nuevoObjeto[key];
          }
        });
    
        setColumns(orderTableKeys(
          transformDataSimpleTable(
            [nuevoObjeto],
            undefined,
            undefined,
            undefined,
            [nuevoObjeto],
            handleClickOnEdit,
            handleClickOnEditLocationModal,
            handleGroupTemplate,
            handleGroupByPeriod,
            handleClickOnDetailModal
          ).columns
        ))
      });
    
  }


  return (
    <ComponentLayout
      title="Control de archivos"
      icon={<FactCheckIcon />}
      rightComponent={
        table?.length > 0 ? (
          <ConfigsModal refetchTable={refetchTable} />
        ) : undefined
      }
    >
      <SimpleBackdrop
        open={isLoadingTable || isLoadingPutTable}
        message={"Cargando..."}
      />
      {table?.length && rows?.length ? (
        <>
          <Box sx={{ width: "100%", display: "flex" }}>
            <Typography
              sx={{
                fontSize: "16px",
                color: "var(--blue)",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                marginRight: "auto",
                cursor: "pointer",
              }}
              onClick={
                isGroupedByTotal ? handleDisgroupByTotal : handleGroupByTotal
              }
            >
              {" "}
              <GroupWorkIcon />
              {isGroupedByTotal ? "DESAGRUPAR POR TOTAL" : "AGRUPAR POR TOTAL"}
            </Typography>
            <Box sx={{display:"flex", marginLeft: "auto", alignItems:"center", marginBottom:"5px"
}}>
            <Typography
              sx={{
                fontSize: "16px",
                color: "var(--blue)",
                fontWeight: "bold",
                marginRight:"15px"
              }}
            >
              Filtrar:
            </Typography>
            <Select
                          styles={selectStyles(frecuencySelectedOption)}
                          options={frecuencyOptions}
                          name="frecuency"
                          onChange={handlerFilterByFrecuency}
                          closeMenuOnSelect
                          placeholder="Todos los períodos"
                          isClearable
                          defaultValue={frecuencySelectedOption}
                        />
                        <Stack sx={{marginLeft:"10px"}}>
                        <Select
                          styles={selectStyles(calculatedPeriodsSelectedOption)}
                          options={calculatedOptions}
                          name="Hoja"
                          onChange={handleFilterByCalculated}
                          closeMenuOnSelect
                          placeholder="Sólo períodos sin calcular"
                          isClearable
                          defaultValue={calculatedPeriodsSelectedOption}
                        />
                        </Stack>
                        
            </Box>
            <Typography
              sx={{
                fontSize: "16px",
                color: "var(--blue)",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginLeft:"30px"
              }}
              onClick={handleDisgroupByTotal}
            >
              {" "}
              <GroupWorkIcon />
              {"DESAGRUPAR"}
            </Typography>
          </Box>

          <ControlTable
            columns={columns}
            rows={rows}
            openModal={openModal}
            openLocationModal={openLocationModal}
            handleCloseModal={handleToggleOpenModal}
            handleAcceptModal={handleAcceptModal}
            selectedPeriod={selectedPeriod?.periodo}
            periodLocation={selectedPeriod?.ruta_directorio}
            handleAcceptLocationModal={handleAcceptLocationModal}
            handleCloseLocationModal={handleToggleLocationOpenModal}
            openDetailModal={openDetailModal}
            handleCloseDetailModal={handleToggleDetailModal}
            detail={selectedDetail}
          />
        </>
      ) : (
        <Configs handleAccept={refetchTable} />
      )}
      <FrecuencyModal handleClose={handleToggleFrecuencyModal} open={openFrecuencyModal} handleAccept={handleAcceptFrecuencyModal}/>
    </ComponentLayout>
  );
};

export default DataControl;
