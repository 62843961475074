import { Position } from "@xyflow/react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import useDialog from "../../../../hooks/useDialog";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import NodeWithToolbar from "../nodeToolbar/NodeToolbar";
import AddFont from "../../Modals/AddFont/AddFont";
import useApi from "../../../../hooks/useApi";
import { texts } from "../../../../texts";
import { ITEM_URL } from "../../../../api/axios";
import { useStore } from "../store/store";
import { shallow } from "zustand/shallow";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import CustomHandle from "../handles/CustomHandle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import NodeComment from "./NodeComment";

import { useEffect, useState } from "react";
import { getRandomColor } from "../../utils";

interface FontNodeProps {
  id: any;
  data: any;
}

const selector = (store: any) => ({
  flow: store.flow,
  nodes: store.nodes,
  currentColors: store.currentColors,
  updateColors: store.updateColors,
  updateNode: store.updateNode,
  addTaskToQueue: store.addTaskToQueue,
  assignColorToNode: store.assignColorToNode
});

export default function FontNode({ id, data }: FontNodeProps) {
  const [openFontModal, handleToggleFontModal] = useDialog();
  const [isLoadingFont, setIsLoadingFont] = useState(false);

  const store = useStore(selector, shallow);

  const node = store.nodes.find((node: any) => node.id === id);

  useEffect(() => {
    if(!data.backendId){
      handleToggleFontModal()
    }
    if(!data.color){
      store.addTaskToQueue(() => {
        store.assignColorToNode(id, node.data);
      });
    }else{
      store.updateColors([...store.currentColors,  data.color])
    }
   }, []);



  const onSuccessGetItem = () => {
    handleToggleFontModal();
  };

  const handleEdit = () => {
    if (data.backendId) {
      getDataItem();
    } else {
      handleToggleFontModal();
    }
  };

  const {
    data: dataItem,
    callApi: getDataItem,
    setData: setDataItem,
    isLoading: isLoadingGetItem,
  } = useApi(
    ITEM_URL(store.flow?.id, data?.backendId),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetItem,
    undefined,
    false
  );

  return (
    <Stack sx={{ alignItems: "center", width: "45px", height:"64px" }} >
      <SimpleBackdrop open={isLoadingGetItem} message={"Cargando datos..."} />

      <NodeWithToolbar
        handleEdit={handleEdit}
        backendId={data.backendId}
        nodeData={node}
        activateShowResults={!isLoadingFont}
      />
      {data.error && (
        <WarningAmberIcon
          style={{
            fontSize: 12,
            color: "var(--red)",
            position: "absolute",
            top: -5,
            right: 5,
          }}
        />
      )}
      {isLoadingFont && (
        <CircularProgress
          sx={{
            width: "10px !important",
            height: "10px !important",
            right: "0",
            position: "absolute",
          }}
        />
      )}

      <InsertDriveFileIcon
        style={{
          fontSize: 40,
          color: `var(--${data.color})`, 
        }}
      />
      <Typography sx={{ fontSize: "8px" }}>
        Fuente{data.comentario ? ": " : ""}
      </Typography>
      <NodeComment id={id} data={data} />
      <CustomHandle
        type="source"
        position={Position.Right}
        id={`handle-source-2-${id}`}
        connectionCount={20}
        nodeId={id}
      />
      <AddFont
        handleClose={handleToggleFontModal}
        open={openFontModal}
        dataItem={dataItem}
        id={id}
        backendId={data.backendId}
        nodeData={node}
        setIsLoading={setIsLoadingFont}
      />
    </Stack>
  );
}
