import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { memo, useRef } from "react";
import { Stack } from "@mui/material";
import useLanguage from "../../context/LanguageProvider";
import drilldown from "highcharts/modules/drilldown.js";


interface Props {
  results: any;
  tooltip?: (value: any)=> string;
}
const BarChart = memo((props: Props) => {

  const { results, tooltip } = props;
  const chartRef: any = useRef(null);
  const { t } = useLanguage();

  drilldown(Highcharts);

  const options = {
      chart: {
        type: "bar",
      },
      title: {
        text: results?.titulo,
      },
      xAxis: {
        type: "category",
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        
        title: {
          text: results?.nombre_eje_y,
          align: "high",
        },

        labels: {
          overflow: "justify",
        },
        gridLineWidth: 0,
      },
      tooltip: {
        y: 0,
        useHTML: tooltip,
        formatter: function () {
          return tooltip
            ? tooltip(this.y)
            : `<p>${this.y}</p> `;
        },
      },

      credits: {
        enabled: false,
      },
      //CAMBIAR EN EL FUTURO SI HAY GRAFICOS CON MÁS DE UNA SERIE
      series: [{  showInLegend: false, ...results?.series[0]}]
    }
  
  return (
    <Stack sx={{ width: "100%", margin: "0px !important" }}>
      <Stack
        sx={{ alignSelf: "flex-end", height: "35px", margin: "0px !important" }}
      >
      </Stack>
     <HighchartsReact highcharts={Highcharts} options={options} />
    </Stack>
  );
});

interface PropsDrildown {
  results: any;
  drilldownParam: any
  tooltip?: (value: any, tooltip: any)=> string;

}
const BarDrilldownChart = memo((props: PropsDrildown) => {
  const { results, drilldownParam, tooltip } = props;

  drilldown(Highcharts);

  const options = {
    chart: {
      type: "bar",
      style: {
        width: "100%",
      },
      events: {
        applyDrilldown: () => {
          "d";
        },
        addSingleSeriesAsDrilldown: (e: any, serie: any) => {
          "s";
        },
        options: { colors: [] },
        drilldown: function (e: any) {
          if (!e.seriesOptions) {
            const series = drilldownParam?.series;
            series.forEach((serie: any, index: number) => {
              this.addSingleSeriesAsDrilldown(e.point, {
                ...serie,
                color: this.options.colors[index],
              });
            });
            this.applyDrilldown();
          }
        },
      },
      zooming: {
        type: "xy",
      },
    },
    title: { text: results.titulo_grafico },
    colors: [
      "var(--light-violet)",
      "var(--light-blue)",
      "var(--blue)",
      "var(--light-green)",
      "var(--dark-grey)",
    ],
    xAxis: {
      type: "category",
      title: {
        text: results.nombre_eje_x,
        style: {
          fontWeight: "900",
          fontSize: "16px",
          textTransform: "uppercase",
        },
      },
      /*  min: 0,
      max: 7, */
      /* scrollbar: {
        enabled: true,
      }, */
    },
    yAxis: {
      title: {
        text: results.nombre_eje_y,
        style: {
          fontWeight: "900",
          fontSize: "16px",
          textTransform: "uppercase",
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [{  showInLegend: false, ...results?.series[0]}],

    accessibility: {
      enabled: false,
    },
    tooltip: {
      y: 0,
      point:{tooltip:""},
      useHTML: tooltip,
      formatter: function () {
        return tooltip
          ? tooltip(this.y, this.point.tooltip)
          : `<p>${this.y}</p> `;
      },
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
});


export { BarChart, BarDrilldownChart }