import { Box, InputLabel, Stack } from "@mui/material";
import {useEffect, useRef } from "react";
import useSingleSelect from "../../hooks/useSingleSelect";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../forms/types";
import { form_label } from "../../styles/app-styles";
import Select from "../forms/Select";
import { selectStyles } from "../../styles/select.styles";
import { getValidationType } from "./utils";


interface CustomColumnInputProps {
  column: string;
  handleChangeValidation: (object: any) => any;
  validations: any[]
}

const CustomColumnInput = ({
  column,
  handleChangeValidation,
  validations
}: CustomColumnInputProps) => {

    const validation = validations?.find((val: any)=> val.column === column)

  const [
    valueTypeSelectedOption,
    setValueTypeSelectedOption,
    valueTypeOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    validation
      ? { value: validation.columnType, label: getValidationType(validation.columnType) }
      : { value: "string", label: "Texto" }
  );

  const [
    formatTypeSelectedOption,
    setFormatTypeSelectedOption,
    formatTypeOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    validation
      ? { value: validation.formato, label: validation.formato }
      :  { value: "MM-AAAA", label: "MM-AAAA"}
  );

  const valueOptions = [
    { value: "string", label: "Texto" },
    { value: "numerico", label: "Númerica" },
    { value: "float", label: "Decimal" },
    { value: "date", label: "Fecha" },

  ];

  const valueTypeRef: any = useRef(null);
  const formatTypeRef: any = useRef(null);

  const negativeValueOptions = [
    { value: "permitir-vacios", label: "Permitir valores vacíos" },
    {
      value: "no-permitir-valores-vacios",
      label: "No permitir valores vacíos",
    },
    {
      value: "no-permitir-columna-vacia",
      label: "No permitir columna vacía",
    },
  ];

  const formatOptions = [
    { value: "MM-AAAA", label: "MM-AAAA"},
    { value: "AAAA-MM", label: "AAAA-MM" },
  ];

  const [
    nullValuesSelectedOption,
    setNullValuesSelectedOption,
    nullValuesOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    validation
      ? validation.noEmptyValues
      : {
          value: "permitir-vacios",
          label: "Permitir valores vacíos",
        }
  );

  const nullValuesRef: any = useRef(null);

  const handleChangeTypeSelect = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    valueTypeOptionsChangeHandler(newValue, actionMeta);
    handleChangeValidation({
      column: column,
      columnType: newValue?.value,
      noEmptyValues: nullValuesSelectedOption,
      formato: formatTypeSelectedOption?.value
    });
  };

  const handleChangeFormatSelect = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    formatTypeOptionsChangeHandler(newValue, actionMeta);
    handleChangeValidation({
      column: column,
      columnType: valueTypeSelectedOption?.value,
      noEmptyValues: nullValuesSelectedOption,
      formato: newValue?.value
    });
  };

  const handleChangeNullSelect = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    nullValuesOptionsChangeHandler(newValue, actionMeta);
    handleChangeValidation({
      column: column,
      columnType: valueTypeSelectedOption?.value,
      noEmptyValues: newValue,
      formato: formatTypeSelectedOption?.value

    });
  };

  useEffect(() => {
    setValueTypeSelectedOption(
      validation
        ? { value: validation.columnType, label: getValidationType(validation.columnType) }
        : { value: "string", label: "Texto" }
    );
    setNullValuesSelectedOption(
      validation
        ? validation.noEmptyValues
        : {
            value: "no-permitir-ningun-vacio",
            label: "No permitir ningún valor vacío",
          }
    );
  }, [validations]);


  return (
    <Stack
      sx={{
        alignItems: "stretch",
        flexDirection: "column",
        margin: "13px 0px",
        width: "90%",
        backgroundColor: "var(--very-very-light-grey)",
        paddingBottom: "5px",
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "var(--blue)",
          borderRadius: "4px",
          color: "white",
          display: "flex",
          fontSize: column.length > 17 ? "14px" : "16px",
          justifyContent: "center",
          marginRight: "8px",
          minWidth: "270px",
          textAlign: "center",
          width: "100%",
          height: "35px",
          wordBreak: "break-all",
        }}
      >
        {column}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: "10px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InputLabel sx={{ ...form_label, marginRight: "10px" }}>
            Valores vacíos
          </InputLabel>
          <Select
            reference={nullValuesRef}
            styles={selectStyles(nullValuesSelectedOption)}
            options={negativeValueOptions}
            name="Valores vacios"
            onChange={handleChangeNullSelect}
            closeMenuOnSelect
            placeholder="Valores vacios"
            defaultValue={nullValuesSelectedOption}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InputLabel sx={{ ...form_label, marginRight: "10px" }}>
            Tipo de columa
          </InputLabel>
          <Select
            reference={valueTypeRef}
            styles={selectStyles(valueTypeSelectedOption)}
            options={valueOptions}
            name="Tipo de dato"
            onChange={handleChangeTypeSelect}
            closeMenuOnSelect
            placeholder="Tipo de dato"
            defaultValue={valueTypeSelectedOption}
          />
        </Box>
        {valueTypeSelectedOption?.value === "date" && <Box sx={{ display: "flex", alignItems: "center" }}>
          <InputLabel sx={{ ...form_label, marginRight: "10px" }}>
            Formato
          </InputLabel>
          <Select
            reference={formatTypeRef}
            styles={selectStyles(formatTypeSelectedOption)}
            options={formatOptions}
            name="Formato"
            onChange={handleChangeFormatSelect}
            closeMenuOnSelect
            placeholder="Formato"
            defaultValue={formatTypeSelectedOption}
          />
        </Box>}
      </Box>
    </Stack>
  );
};

export default CustomColumnInput;
