import React, { useState } from 'react';
import { TextField, IconButton, Button, Box, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddButton } from '../buttons';

interface Column {
  columna: string;
  alias: string[];
}

interface FinalColumnsProps {
  columns: Column[];
  setColumns: (columns: Column[])=> void
}

const FinalColumns: React.FC<FinalColumnsProps> = ({ columns, setColumns }) => {

 // Maneja el cambio en el input de alias
  const handleAliasChange = (columnIndex: number, alias: string) => {
    if(alias.trim()){
      const newColumnData = [...columns];
      newColumnData[columnIndex].alias.push(alias);
      setColumns(newColumnData)
    }
    
  };

  // Eliminar un alias
  const handleAliasRemove = (columnIndex: number, aliasIndex: number) => {
    const newColumnData = [...columns];
    newColumnData[columnIndex].alias.splice(aliasIndex, 1);
    setColumns(newColumnData)

  };

  return (
    <div>
      {columns?.length > 0 &&     <Typography sx={{color: "var(--blue)", fontSize:"18px", fontWeight:"bold", marginTop:"10px"}}>Editar alias de columnas</Typography>}
  
      <List>
        {columns.map((col, columnIndex) => (
          <ListItem key={col.columna}>
            <Box sx={{ width: '100%' }}>
            <Typography sx={{color: "var(--blue)", fontSize:"16px", fontWeight:"bold", marginTop:"10px"}}>{col.columna +":"}</Typography>
              <Box display="flex" flexDirection="column">
                {col.alias.length > 0 && (
                  <Box sx={{ marginBottom: 2 }}>
                    {col.alias.map((alias, aliasIndex) => (
                      <Box key={aliasIndex} display="flex" alignItems="center" marginBottom={1}>
                        <span>{alias}</span>
                        <IconButton
                          onClick={() => handleAliasRemove(columnIndex, aliasIndex)}
                         
                          size="small"
                          sx={{ marginLeft: 1, color:"var(--light-grey)"}}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                )}
                <Stack sx={{flexDirection:"row", alignItems:"center", width:"100%"}}>
                <TextField
                  id={`alias-input-${columnIndex}`}
                  label="Nuevo alias"
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2 }}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter' && e.target.value) {
                      handleAliasChange(columnIndex, e.target.value);
                      (e.target as HTMLInputElement).value = ''; 
                    }
                  }}
                />
                <AddButton
                  onClick={() => {
                    const input = document.getElementById(`alias-input-${columnIndex}`) as HTMLInputElement;
                    if (input && input.value) {
                      handleAliasChange(columnIndex, input.value);
                      input.value = '';
                    }
                  }}
                  margin="0px 0px 12px 5px"
                 width='medium'  
              />
                </Stack>

              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default FinalColumns;
