import {
  Box,
  Collapse,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import useApi from "../../hooks/useApi";
import {
  ALLOWED_FILES_URL,
  DATA_CONTROL_CONFIGURATION,
  FONT_FILES,
} from "../../api/axios";
import { texts } from "../../texts";
import FileConfig from "./FileConfig";
import { useEffect, useRef, useState } from "react";
import {
  deleteSlash,
  frecuencyOptions,
  getPeriodById,
  periods,
  steps,
} from "./utils";
import { AddButton, Button } from "../buttons";
import ShadowBox from "../flowConfiguration/Modals/ShadowBox";
import { ExpandLess } from "@mui/icons-material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { form_label } from "../../styles/app-styles";
import ResponsibleInput from "./ResponsibleInput";
import Select from "../forms/Select";
import useSingleSelect from "../../hooks/useSingleSelect";
import { optionalSelectStyles, selectStyles } from "../../styles/select.styles";
import CheckmarkSelect from "./CheckmarkSelect";
import { useApiQuery } from "../../hooks/useApiQuery";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import FontFile from "./FontFile";
import ExploreFolders from "../dataManagement/UploadFileModal/ExploreFolders";
import Validations from "./Validations";
import CustomStepper from "../stepper/CustomStepper";
import InfoIcon from "@mui/icons-material/Info";
import { Checkbox } from "@mui/material";
import CalculatedPeriodsModal from "./CalculatedPeriodsModal";

interface IConfigsProps {
  handleAccept?: () => void;
}

const Configs = ({ handleAccept }: IConfigsProps) => {
  const [filesState, setFilesState] = useState<any[]>([]);
  const [openSection, setOpenSection] = useState(true);
  const [filesNames, setFilesNames] = useState<any>([]);
  const [fileNameInput, setFileNameInput] = useState("");
  const [responsible, setResponsible] = useState("");
  const [editingIndex, setEditingIndex] = useState<any>(null);
  const [selectedPeriods, setSelectedPeriods] = useState<number[]>([]);
  const [location, setLocation] = useState<string>("");
  const [validations, setValidations] = useState<any>();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isCriticalFile, setIsCriticalFile] = useState(false);

  const frecuencyRef: any = useRef(null);

  const [
    frecuencySelectedOption,
    setFrecuencySelectedOption,
    frecuencyChangeHandler,
  ] = useSingleSelect();

  const handleChange = (event: any) => {
    setFileNameInput(event.target.value);
  };

  const handleAddFile = (event: any) => {
    if (fileNameInput.trim() !== "") {
      if (editingIndex === null) {
        sendFile();
      } else {
        const updatedfilesNames = [...filesNames];
        updatedfilesNames[editingIndex] = fileNameInput;
        setFilesNames(updatedfilesNames);
        setEditingIndex(null);
      }
      setFileNameInput("");
      setResponsible("");
      setSelectedPeriods([]);
      setFrecuencySelectedOption(undefined);
      frecuencyRef?.current?.clearValue();
      setLocation("");
      setActiveStep(0);
      setTimeout(() => {
        setValidations([]);
      }, 3000);
    }
  };

  const handleEdit = (file: any) => {
    setEditingIndex(file);
  };

  /********************* LLAMADAS **************************/

  const {
    data: allowedFileNames,
    isLoading: isLoadingFilesNames,
    refetch: refetchLocalFilesNames,
  } = useApiQuery(
    ALLOWED_FILES_URL,
    false,
    texts.dataManagement.getAllowedFileNames.error
  );

  const {
    data: fontFiles,
    isLoading: isLoadingGetFontFiles,
    refetch: refetchFontFiles,
  } = useApiQuery(
    FONT_FILES,
    false,
    texts.dataManagement.getAllowedFileNames.error
  );

  const {
    isLoading: isLoadingConfig,
    callApi: refetchConfig,
    data: config,
  } = useApi(
    DATA_CONTROL_CONFIGURATION,
    "GET",
    texts.dataControl.sendConfig.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  const prepareConfigToSend = () => {
    return {
      configuracion: filesState?.map((file: any) => {
        return {
          template: file?.file,
          archivos_fuentes: file?.files?.map((r: any) => r?.id),
        };
      }),
    };
  };

  const prepareFileToSend = () => {
    return {
      nombre: fileNameInput,
      responsable: responsible,
      critico: isCriticalFile,
      frecuencia_actualizacion: frecuencySelectedOption?.value,
      periodos: selectedPeriods?.map((p: any) => getPeriodById(p)),
      ruta_directorio: location === "" ? "/" : deleteSlash(location),
      validacion: {
       archivo_base: validations?.withoutFile ? null : validations?.file?.id,
        hoja_archivo_base: validations?.withoutFile ? null :validations?.sheet?.value,
        indice_fila_encabezado: validations?.header?.index ? parseInt(validations?.header.index)
        : null,
      exp_regular_encabezado : validations?.header?.exp_regular ? validations?.header.exp_regular :
       null,
       lista_columnas_guardar: validations?.finalColumns,
        validacion_columnas_vacias: 
        (validations?.header?.exp_regular && !validations?.header?.index) || (validations?.validations?.length === 0)
        ? undefined : validations?.validations
                ?.filter((val: any) => val?.column !== "id")
                .map((val: any) => {
                  return {
                    columna: val?.column,
                    valor: val?.noEmptyValues.value,
                  };
                })
            ,
            validacion_columnas_tipo_dato:
            (validations?.header?.exp_regular && !validations?.header?.index) || (validations?.validations?.length === 0)
            ? undefined
            : validations?.validations
                ?.map((val: any) => {return {tipo_dato: val.columnType, columna: val.column, formato: val?.formato}} )
    }};
  };

  const { isLoading: isLoadingSendConfig, callApi: sendConfig } = useApi(
    DATA_CONTROL_CONFIGURATION,
    "POST",
    texts.dataControl.sendConfig.codes,
    prepareConfigToSend(),
    handleAccept
      ? () => {
          handleAccept();
        }
      : undefined,
    undefined,
    false
  );

  const { isLoading: isLoadingPostFontFile, callApi: sendFile } = useApi(
    FONT_FILES,
    "POST",
    texts.dataControl.sendConfig.codes,
    prepareFileToSend(),
    () => {
      refetchFontFiles();
    },
    undefined,
    false
  );

  /*************************************************************************************************** */

  useEffect(() => {
    if (allowedFileNames?.length > 0 && filesState?.length === 0) {
      const files = allowedFileNames.map((name: string) => ({
        file: name,
        files: [],
      }));
      setFilesState(files);
    }
  }, [allowedFileNames]);

  useEffect(() => {
    if (fontFiles?.length > 0) {
      setFilesNames(
        fontFiles.map((font: any) => {
       
          return {
            ...font,
            validacion: {
              file: {
                id: font?.validacion?.archivo_base?.value,
                nombre: font?.validacion?.archivo_base?.label,
                ruta: font?.validacion?.archivo_base?.ruta?.substring(
                  0,
                  font?.validacion?.archivo_base?.ruta?.lastIndexOf("/")
                ),
              },
              withoutFile: font?.validacion?.archivo_base.value === undefined,
              sheet: font?.validacion?.hoja_archivo_base,
              header:{exp_regular: font?.validacion?.exp_regular_encabezado, 
                index: font?.validacion?.indice_fila_encabezado,
              },
              finalColumns: font?.validacion.lista_columnas_guardar,
              validations: font?.validacion?.validacion_columnas_vacias?.map(
                (columnaId: any) => {
                    return {
                    column: columnaId.columna,
                    columnType:
                      font?.validacion.validacion_columnas_tipo_dato.find((col: any) =>columnaId.columna === col.columna)?.tipo_dato,
                      formato: font?.validacion.validacion_columnas_tipo_dato.find((col: any) =>columnaId.columna === col.columna)?.formato,
                    noEmptyValues: {
                      value: columnaId.valor,
                      label:
                        columnaId.valor === "permitir-vacios"
                          ? "Permitir valores vacíos"
                          : columnaId.valor === "no-permitir-valores-vacios"
                          ? "No permitir valores vacíos"
                          : "No permitir columna vacía",
                    },
                  };
                }
              ),
            },
          };
        })
      );

      // ESTO ES PARA QUE CUANDO SE ACTUALICE UN FILE SE ACTUALICE EL ESTADO DE LA CONFIG
      if (filesState?.length > 0 && config) {
        const files = config?.map((file: any) => ({
          file: file?.template,
          files: file.archivos_fuentes.map((f: any) => {
            return fontFiles.find((fi: any) => {
              return fi.id === f.id;
            });
          }),
        }));
        setFilesState(files);
      }
    }
  }, [fontFiles]);

  useEffect(() => {
    if (config?.length > 0) {
      const files = config?.map((file: any) => ({
        file: file?.template,
        files: file.archivos_fuentes,
      }));
      setFilesState(files);
    }
  }, [config]);

  useEffect(() => {
    refetchConfig();
  }, []);

  const handleSendConfig = () => {
    sendConfig();
  };

  const handleChangeSelectedFolder = (location: string) => {
    if (location === "") {
      setLocation("/");
    } else {
      setLocation(location);
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Stack sx={{ width: "100%" }}>
      <SimpleBackdrop open={isLoadingGetFontFiles} message={"Cargando..."} />
      <SimpleBackdrop
        open={isLoadingPostFontFile}
        message={"Creando archivo..."}
      />
      <Stack sx={{ width: "100%" }}>
 <CalculatedPeriodsModal />
        <Typography
          sx={{
            fontSize: "22px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
            marginTop: "15px",
            padding: "8px",
            backgroundColor: "var(--blue)",
            fontWeight: "550",
            color: "white",
            borderRadius: "5px",
          }}
          onClick={() => {
            setOpenSection(!openSection);
          }}
        >
          {"ALTA DE FUENTES"}
          {openSection ? (
            <ExpandLess sx={{ fontSize: "30px" }} />
          ) : (
            <ExpandMore sx={{ fontSize: "30px" }} />
          )}
        </Typography>
        <Collapse in={openSection} timeout={10} sx={{ width: "100%" }}>
          <ShadowBox margin>
            <CustomStepper activeStep={activeStep} data={steps} />
            <Box sx={{ marginBottom: "20px", alignItems: "center" }}>
              <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                Nuevo archivo
              </InputLabel>
              {activeStep === 0 && (
                <Box
                  sx={{ display: "flex", width: "100%", alignItems: "center" }}
                >
                  <TextField
                    label="Escribe el nombre del archivo"
                    variant="outlined"
                    value={fileNameInput}
                    onChange={handleChange}
                    fullWidth
                    sx={{ marginRight: "10px", width: "25%" }}
                  />
                  <ResponsibleInput
                    handleChangeResponsible={setResponsible}
                    width="25%"
                    value={responsible}
                  />
                  <Stack sx={{ marginLeft: "10px", marginBottom: "5px" }}>
                    <InputLabel sx={{ ...form_label }}>Frecuencia:</InputLabel>
                    <Select
                      reference={frecuencyRef}
                      styles={optionalSelectStyles(frecuencySelectedOption)}
                      options={frecuencyOptions}
                      name="quantity"
                      onChange={frecuencyChangeHandler}
                      closeMenuOnSelect={true}
                      placeholder="Seleccionar frecuencia"
                      isClearable
                      defaultValue={frecuencySelectedOption}
                    />
                  </Stack>
                  <Stack sx={{ marginLeft: "10px", marginBottom: "8px" }}>
                    <InputLabel sx={{ ...form_label }}>Periodos:</InputLabel>
                    <CheckmarkSelect
                      dates={periods}
                      selectedPeriods={selectedPeriods}
                      setSelectedPeriods={setSelectedPeriods}
                    />
                  </Stack>
                  <Box sx={{display:"flex", alignItems:"center", marginLeft: "10px"}}>
                  <InputLabel sx={{ ...form_label }}>Archivo crítico:</InputLabel>

                  <Checkbox
                    checked={isCriticalFile}
                    onChange={() => {
                      setIsCriticalFile((prev) => !prev);
                    }}
                  />
                  </Box>
                </Box>
              )}
              {activeStep === 1 && (
                <>
                  <InputLabel
                    sx={{
                      ...form_label,
                      marginBottom: "5px",
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Ruta del directorio
                    <Tooltip title="Ubicación donde se almacenará el archivo">
                      <InfoIcon
                        sx={{
                          color: "var(--blue)",
                          fontSize: "21px",
                          cursor: "pointer",
                          marginLeft: "1px",
                          marginBottom: "2px",
                        }}
                      />
                    </Tooltip>
                  </InputLabel>

                  <ExploreFolders
                    handleChangeSelectedFolder={handleChangeSelectedFolder}
                    customFolder={location ? location : ""}
                  />
                </>
              )}
              {activeStep === 2 && (
                <>
                  <Validations
                    validations={validations}
                    setValidations={setValidations}
                  />
                  <Box sx={{ marginLeft: "auto" }}>
                    {activeStep === 2 && (
                      <Button
                        title={"Crear archivo"}
                        color={"blue"}
                        type={"button"}
                        onClick={handleAddFile}
                        disabled={
                          fileNameInput.length === 0 ||
                          responsible.length === 0 ||
                          selectedPeriods.length === 0 ||
                          frecuencySelectedOption === undefined || (validations?.withoutFile && (validations.header?.exp_regular === "" ||validations.header?.exp_regular === undefined ))
                        }
                      />
                    )}
                  </Box>
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  title="Anterior"
                  color="light-blue"
                  type="button"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                />

                <Button
                  title="Siguiente"
                  color="light-blue"
                  type="button"
                  onClick={handleNext}
                  disabled={
                    activeStep === 2 ||
                    fileNameInput.length === 0 ||
                    responsible.length === 0 ||
                    selectedPeriods.length === 0 ||
                    frecuencySelectedOption === undefined
                  }
                />
              </Box>
             
              <InputLabel
                sx={{ ...form_label, marginBottom: "5px", marginTop: "20px" }}
              >
                Archivos creados:
              </InputLabel>
              <Box mt={2}>
                {filesNames.map((font: any, index: number) => (
                  <Box key={index} display="flex" flexDirection={"column"}>
                    <FontFile
                      index={index}
                      fontFile={font}
                      refetchFontFiles={refetchFontFiles}
                      editingIndex={editingIndex}
                      handleEdit={handleEdit}
                      onSuccessCreateFile={refetchFontFiles}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            
          </ShadowBox>
        </Collapse>
        {allowedFileNames?.length &&
          allowedFileNames.map((file: any) => {
            return (
              <FileConfig
                fileOptions={fontFiles}
                allowedFile={file}
                key={file}
                setFilesState={setFilesState}
                fileState={filesState}
                filesSelectedOptionParam={
                  config
                    ? config?.find(
                        (template: any) => template.template === file
                      )?.archivos_fuentes
                    : undefined
                }
              />
            );
          })}
        <Stack sx={{ margin: "auto" }}>
          <Button
            title="Generar tabla"
            color="blue"
            onClick={() => handleSendConfig()}
            type="button"
            disabled={filesState?.some((file) => file.files?.length === 0)}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Configs;
