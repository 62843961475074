import Stack from "@mui/material/Stack";
import { useContext, useState } from "react";
import { InputLabel } from "@mui/material";
import { form_label } from "../../../styles/app-styles";
import useSingleSelect from "../../../hooks/useSingleSelect";
import Select from "../../forms/Select";
import { selectStyles } from "../../../styles/select.styles";
import  CheckmarkSelectPeriods from "../../dataControl/CheckmarkSelect";
import DataManagementProvider from "../../../context/DataManagementContext";
import { formatFontFilesOptions } from "../../../utils/util";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption, IsMulti, OnChangeValue } from "../../forms/types";
import useMultiSelect from "../../../hooks/useMultiSelect";
import { formatSimpleSheets } from "../../fileColumns/utils";
import CheckmarkSelect from "../../forms/CheckmarkSelect";

interface SheetOptions {
  file: any;
  filesState: any[];
  setFilesState: (state: any) => void;
  id: any
}

const SheetOptions = ({ file, setFilesState, filesState, id }: SheetOptions) => {
  const [selectedPeriods, setSelectedPeriods] = useState<number[]>([]);
  const [selectedSheets, setSelectedSheets] = useState<any[]>([]);

  const [fileSelectedOption, setFileSelectedOption, fileChangeHandler] =
    useSingleSelect();

  const [sheetsSelectedOption, setSheetsSelectedOption, sheetsChangeHandler] =
    useMultiSelect();

  const { DataState, dataDispatch } = useContext<
    React.ContextType<typeof DataManagementProvider>
  >(DataManagementProvider);

  const handleChangeSelectedPeriod = (periods: any) => {
    setSelectedPeriods(periods);
  
    setFilesState((prevState: any[]) => {
      const updatedFilesState = prevState.map((f: any) =>
        f.identificador === id ? { ...f, periods: periods } : f
      );
  
      const exists = prevState.some((f: any) => f.identificador === id);
        if (!exists) {
        return [...updatedFilesState, { identificador: id, periods: periods }];
      }
  
      return updatedFilesState;
    });
  };
  

  const handleChangeFileSelectedOption = (
    newValue: any,
    actionMeta: ActionMeta<IdOption>
  ) => {
    fileChangeHandler(newValue, actionMeta);
  
    setFilesState((prevState: any[]) => {
      const updatedFilesState = prevState.map((f: any) => 
        f.identificador === id 
          ? { ...f, file: {file: newValue?.value, type: newValue.type }}
          : f
      );
  
      // Verifica si el identificador ya existe en el estado
      const exists = prevState.some((f: any) => f.identificador === id);
  
      // Si no existe, agrega un nuevo objeto con el identificador y el valor de 'file'
      if (!exists) {
        return [...updatedFilesState, { id:file.id,identificador: id, file: newValue?.value }];
      }
  
      return updatedFilesState;
    });
  };
  

  const handleChangeSelectedSheets = (selectedSheets: any[]) => {
    setSelectedSheets(selectedSheets);
    
    setFilesState((prevState: any[]) => {
      const updatedFilesState = prevState.map((f: any) => 
        f.identificador === id 
          ? { ...f, sheets: selectedSheets, id:file.id,} 
          : f
      );
  
      // Verifica si el identificador existe en el estado
      const exists = prevState.some((f: any) => f.identificador === id);
  
      // Si no existe, agrega un nuevo objeto con el identificador y las hojas seleccionadas
      if (!exists) {
        return [...updatedFilesState, { id:file.id,identificador: id, sheets: selectedSheets }];
      }
  
      return updatedFilesState;
    });
  };
  


  return (
    <Stack
      sx={{
        alignItems: "center",
        flexDirection: "row",
        marginBottom:"20px",
        width:"fit-content",
        minWidth:"1000px"

      }}
    >
      <Stack sx={{marginRight:"8px"}}>
        <InputLabel
          sx={{ ...form_label, marginBottom: "2px", fontSize: "18px" }}
        >
          {`Seleccione las hojas`}
        </InputLabel>
        <CheckmarkSelect
                label="Seleccionar hojas"
                selectedItems={selectedSheets}
                setSelectedItems={handleChangeSelectedSheets}
                items={file.hojas?.map((item: any) => {
                  return {
                    id: item?.id,
                    label: `${item?.nombre}`,
                  };
                })}
                width="100%"
                allOptionsLabel="Seleccionar todas las hojas"
              />
      </Stack>
      <Stack sx={{marginRight:"10px"}}>
      <InputLabel
            sx={{ ...form_label, marginBottom: "2px", fontSize: "18px" }}
          >
            {`Seleccione el archivo asociado`}
          </InputLabel>
          <Select
            styles={selectStyles(fileSelectedOption)}
            options={formatFontFilesOptions(DataState.periodsInfo)}
            name="File"
            onChange={handleChangeFileSelectedOption}
            closeMenuOnSelect
            defaultValue={fileSelectedOption}
            placeholder="Seleccionar archivo"
            isClearable
          />
      </Stack>
      {fileSelectedOption && DataState.periodsInfo[0].padre === false
           && (
        <Stack marginTop="20px">
          <CheckmarkSelectPeriods
            dates={fileSelectedOption?.periods}
            selectedPeriods={selectedPeriods}
            setSelectedPeriods={handleChangeSelectedPeriod}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default SheetOptions;
