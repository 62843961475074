import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import Tooltip from "@mui/material/Tooltip";
import { useContext, useEffect, useState } from "react";
import {
  checkDuplicatedFiles,
  checkFileInList,
  checkFileInUploadList,
  getRoute,
  hasRepeatedFiles,
} from "./utils";
import useLanguage from "../../../../context/LanguageProvider";
import useDialog from "../../../../hooks/useDialog";
import { ConfirmationModal } from "../../../dialog";
import { convertArrayToText } from "../../../taskManager/utils";
import DataManagementProvider from "../../../../context/DataManagementContext";
import { cutFileExtension, getFileExtension } from "../../../../utils/util";
import UploadAllowedFilesWarningModal from "../../../dataManagement/MoveResourceWarningModal";
import SelectPeriodsAndFileModal from "../../../dataManagement/UploadFileModal/SelectPeriodsAndFileModal";
import * as XLSX from "xlsx";
import { isExcelFile } from "../../utils";
import SelectDelimiterModal from "../../../dataManagement/UploadFileModal/SelectDelimiterModal";
/* eslint-disable no-useless-escape */

interface IUploadModalContent {
  filesToUpload: File[] | null;
  setFilesToUpload: (files: File[]) => void;
  isRestartChecked: boolean;
  setIsRestartChecked: any;
  isSingleFile?: any;
  customWidth?: string;
  handleCloseModal?: any
  callRefetchResources?: ()=>void
}

const UploadModalContent = ({
  setFilesToUpload,
  filesToUpload,
  isRestartChecked,
  setIsRestartChecked,
  isSingleFile,
  customWidth,
  handleCloseModal,
  callRefetchResources
}: IUploadModalContent) => {
  const { t } = useLanguage();
  const [repeatedFiles, setRepeatedFiles] = useState<boolean>(false);
  const [acceptedFilesToUpload, setAcceptedFilesToUpload] = useState<any[]>([]);
  const [filesWithDelimiter, setFilesWithDelimiter] = useState<any[]>([]);
  const [filesWithoutDelimiter, setFilesWithoutDelimiter] = useState<any[]>([]);
  const [openConfirmationModal, handleToggleConfirmationModal] = useDialog();
  const [openWarningModal, handleToggleWarningModal] = useDialog();
  const [openPeriodsModal, handleTogglePeriodsModal] = useDialog();
  const [openDelimiterModal, handleToggleDelimiterModal] = useDialog();
  const { DataState, dataDispatch } = useContext<
    React.ContextType<typeof DataManagementProvider>
  >(DataManagementProvider);
  const { folderFiles } = DataState;
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: isSingleFile ? 1 : 0,
    accept: {
      "text/csv": [".csv", ".parquet"],
      "text/plain": [".txt"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    onDrop: (acceptedFiles) => {
      validateFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    if (hasRepeatedFiles(filesToUpload)) {
      setIsRestartChecked(true);
    } else {
      setIsRestartChecked(false);
    }
  }, [filesToUpload]);

  const handleDeleteFile = (fileName: File) => {
    const newFiles = filesToUpload?.filter((f: any) => f.path !== fileName);
    setRepeatedFiles(checkDuplicatedFiles(filesToUpload));
    setFilesToUpload(newFiles || []);
  };

  const handleAcceptConfirmationModal = (files?: any) => {
    setFilesToUpload(files !== undefined ? files : acceptedFilesToUpload);
  };
  const validateFiles = (files: any) => {
    const duplicatedFiles = getDuplicatedFiles(files);
    const notAllowedFiles = getNotAllowedFiles(files);
    // Archivos csv o txt
    const filesWithDelimiter = files?.filter((file: any)=> file.name.endsWith("txt") || file.name.endsWith("csv") )
    const filesWithoutDelimiter = files?.filter((file: any)=> !file.name.endsWith("txt") && !file.name.endsWith("csv") )
    if(filesWithDelimiter.length > 0 && (DataState?.periodsInfo?.length === 0 || (DataState?.periodsInfo?.length === undefined) ||
      (DataState?.periodsInfo?.length > 0 && DataState?.periodsInfo[0]?.padre === true))){
      setFilesWithDelimiter(filesWithDelimiter)
      setFilesWithoutDelimiter(filesWithoutDelimiter)
      setAcceptedFilesToUpload(files)
      setTimeout(() => {
        handleToggleDelimiterModal();
      }, 1000); 
      return
    }
    if (
      notAllowedFiles.length > 0 &&
      getRoute(location.pathname).startsWith("PHI/")
    ) {
      handleToggleWarningModal();
    } else {
      if (
        DataState?.periodsInfo?.length > 0 &&
        DataState?.periodsInfo[0]?.padre === false
      ) {
        files.forEach((f: File) => {
          if (isExcelFile(f.name)) {
            const reader = new FileReader();
            reader.onload = (e) => {
              // Convertir el archivo binario a un workbook usando SheetJS
              const data = e?.target?.result;
              const workbook = XLSX.read(data, { type: "binary" });

              // Obtener los nombres de las hojas
              const sheetNames = workbook.SheetNames;
              setAcceptedFilesToUpload((prev: File[]) => [
                ...prev,
                Object.assign(f, {
                  hojas: sheetNames.map((s: string) => ({ nombre: s, id: s })),
                })
              ]); 
            };
            // Leer el archivo como binario
            reader.readAsBinaryString(f);
          } else {
            setAcceptedFilesToUpload((prev: File[]) => [...prev, f]);
          }
        });
        setTimeout(() => {
          handleTogglePeriodsModal();
        }, 1000); 
      
      } else {
        if (duplicatedFiles.length > 0) {
          setAcceptedFilesToUpload(files);
          handleToggleConfirmationModal();
        } else {
          handleAcceptConfirmationModal(files);
        }
      }
    }
  };

  const getDuplicatedFiles = (files: any) => {
    const duplicatedFiles: any[] = [];
    files.forEach((fileToUpload: any) => {
      if (
        folderFiles
          .map((file: any) => {
            return `${cutFileExtension(file.nombre)}${getFileExtension(
              file.nombre
            ).toLowerCase()}`;
          })
          ?.includes(
            `${cutFileExtension(fileToUpload.name)}${getFileExtension(
              fileToUpload.name
            ).toLowerCase()}`
          )
      ) {
        duplicatedFiles.push(fileToUpload.name);
      }
    });
    return duplicatedFiles;
  };

  const getNotAllowedFiles = (files: any) => {
    const notAllowedFiles: any[] = [];
    files.forEach((fileToUpload: any) => {
      if (
        !validateAllowedFilesNameFormat(
          fileToUpload.name.substring(0, fileToUpload.name.lastIndexOf("."))
        )
      ) {
        notAllowedFiles.push(fileToUpload.name);
      }
    });
    return notAllowedFiles;
  };

  const validateAllowedFilesNameFormat = (file: string) => {
    // Construir la expresión regular dinámicamente
    const regexString = `^${
      getRoute(location.pathname).split("/")[
        getRoute(location.pathname).endsWith("/")
          ? getRoute(location.pathname).split("/").length - 2
          : getRoute(location.pathname).split("/").length - 1
      ]
    }-\\d{2}-\\d{4}$`;
    const regex = new RegExp(regexString);
    // Verificar si el nombre de archivo cumple con el formato
    return regex.test(file);
  };
  
  const handleClosePeriodsModal = ()=> {
    handleTogglePeriodsModal()
    setAcceptedFilesToUpload([])
    handleCloseModal()
  }

  const handleCloseDelimiterModal = ()=> {
    handleToggleDelimiterModal()
    setAcceptedFilesToUpload([])
    setFilesWithDelimiter([])
    setFilesWithoutDelimiter([])
    handleCloseModal()
  }

  const handleAcceptDelimiterModal = (files: any[])=>{
    callRefetchResources && callRefetchResources()
    if (
      DataState?.periodsInfo?.length > 0 &&
      DataState?.periodsInfo[0]?.padre === true
    ){
      callRefetchResources && callRefetchResources()
      setAcceptedFilesToUpload(files)
      setTimeout(() => {
        handleTogglePeriodsModal();
      }, 1000); 
      handleToggleDelimiterModal()
    }else{
      handleCloseDelimiterModal()
    }
  }

  return (
    <Stack sx={{ alignItems: "center" }}>
      <Stack sx={{ width: customWidth ? customWidth : "800px" }}>
        <Stack
          sx={{
            border: "2px dashed var(--light-grey)",
            padding: "50px 15px",
            height: "21vw",
            width: customWidth ? customWidth : undefined,
            margin: customWidth ? "auto" : undefined,
          }}
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} multiple />
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <p>{t("dataManagementTexts.uploadModalContent.dragSelectFiles")}</p>
            <UploadFileIcon
              sx={{
                fontSize: "60px",
                color: "#9A999E",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Stack>
        </Stack>
        <Stack sx={{ overflow: "auto" }}>
          {filesToUpload && filesToUpload?.length > 0 && (
            <>
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: "10px",
                }}
              >
                <Typography
                  variant="button"
                  gutterBottom
                  color="primary"
                  mt="10px"
                  sx={{ fontWeight: "bold" }}
                >
                  {t("dataManagementTexts.uploadModalContent.filesToUpload")}
                </Typography>
              </Stack>

              <List dense={true} sx={{ height: "150px" }}>
                {filesToUpload?.map((file: any, index: number) => {
                  return (
                    <ListItem
                      key={file.path}
                      secondaryAction={
                        <>
                          <Tooltip
                            disableFocusListener
                            disableTouchListener
                            title={t(
                              "dataManagementTexts.uploadModalContent.deleteFileFromListing"
                            )}
                            sx={{ verticalAlign: "center", fontSize: "16px" }}
                          >
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDeleteFile(file.path)}
                            >
                              <DeleteIcon
                                sx={{
                                  color: "var(--grey) !important",
                                  cursor: "pointer",
                                  transition: "all 1s linear",
                                  "& :hover": { color: "var(--magenta)" },
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor: !checkFileInUploadList(
                              file,
                              index,
                              filesToUpload,
                              repeatedFiles,
                              setRepeatedFiles
                            )
                              ? !checkFileInList(file, [])
                                ? "var(--light-grey) !important"
                                : "var(--light-yellow) !important"
                              : "var(--light-magenta) !important",
                          }}
                        >
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          color: !checkFileInUploadList(
                            file,
                            index,
                            filesToUpload,
                            repeatedFiles,
                            setRepeatedFiles
                          )
                            ? !checkFileInList(file, [])
                              ? "var(--blue) !important"
                              : "var(--dark-grey) !important"
                            : "var(--magenta) !important",

                          fontWeight: "bold",
                          maxWidth: "600px",
                        }}
                        primary={
                          <p
                            style={{
                              fontWeight: "bold",
                              margin: 0,
                              padding: 0,
                            }}
                          >{`${
                            file.path.split(".")[0].toUpperCase() +
                            "." +
                            file.path.split(".")[1]
                          } ${
                            !checkFileInUploadList(
                              file,
                              index,
                              filesToUpload,
                              repeatedFiles,
                              setRepeatedFiles
                            )
                              ? !checkFileInList(file, [])
                                ? ""
                                : `${t(
                                    "dataManagementTexts.uploadModalContent.fileAlreadyExistsRestatedCalculation"
                                  )} ${
                                    isRestartChecked
                                      ? t(
                                          "dataManagementTexts.uploadModalContent.itWillBeRestarted"
                                        )
                                      : ""
                                  }`
                              : t(
                                  "dataManagementTexts.uploadModalContent.fileAlreadyIncluded"
                                )
                          }`}</p>
                        }
                        secondary={file.size + " bytes"}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        </Stack>
      </Stack>
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleToggleConfirmationModal}
        handleAccept={() => handleAcceptConfirmationModal()}
        message={`${
          getDuplicatedFiles(acceptedFiles).length > 1
            ? `Los siguientes archivos: ${convertArrayToText(
                getDuplicatedFiles(acceptedFiles)
              )} ya existen en la carpeta y serán reemplazados.`
            : `El siguiente archivo: ${convertArrayToText(
                getDuplicatedFiles(acceptedFiles)
              )} ya existe en la carpeta y sera reemplazado.`
        } ¿Confirma que desea continuar?`}
        customMessage
        title="Confirmar"
      />
      <UploadAllowedFilesWarningModal
        open={openWarningModal}
        handleAccept={handleToggleWarningModal}
      />
      <SelectPeriodsAndFileModal
        open={openPeriodsModal}
        handleClose={handleClosePeriodsModal}
        filesToUpload={acceptedFilesToUpload as any[]}
        isValidationPrev={ DataState?.periodsInfo?.length > 0 &&
          DataState?.periodsInfo[0]?.padre === false}
      />
            <SelectDelimiterModal
        open={openDelimiterModal}
        handleClose={handleCloseDelimiterModal}
        handleAccept={handleAcceptDelimiterModal}
        filesToUpload={filesWithDelimiter as any[]}
        filesToUploadWithoutDelimiter={filesWithoutDelimiter}
      />
    </Stack>
  );
};

export default UploadModalContent;
