import { Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "../forms/Select";
import { selectStyles } from "../../styles/select.styles";
import useSingleSelect from "../../hooks/useSingleSelect";
import { Button } from "../buttons";
import useApi from "../../hooks/useApi";
import {
  CLUSTER_ALERT,
  CONFIGURATIONS_BY_SCHEMA_URL,
  EXECUTE_SCHEMA_URL,
  FILE_INFO_URL,
  SCHEMAS_URL,
  SCHEMA_CONFIGURATION_BY_ID,
} from "../../api/axios";
import { texts } from "../../texts.js";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import ShopIcon from "@mui/icons-material/Shop";
import ComponentLayout from "../layout/ComponentLayout";
import { ConfirmationModal } from "../dialog";
import useDialog from "../../hooks/useDialog";
import { formatOptions } from "../../utils/util";
import { form_label } from "../../styles/app-styles";
import { Tab, TabValue } from "../colorTabs/types";
import ColorTabs from "../colorTabs/ColorTabs";
import SchemeConfiguration from "./SchemeConfiguration";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../forms/types";
import SaveNameModal from "../dialog/SaveNameModal";
import ManageConfigurationsModal from "./ManageConfigurationsModal";
import AlertCluster from "../alertCluster/AlertCluster";

const SchemeExecution = () => {
  const schemaSelectRef: any = useRef(null);
  const formatSelectRef: any = useRef(null);
  const configurationRef: any = useRef(null);
  const [openConfirmExecuteSchemaModal, toggleConfirmExecuteSchemaModal] =
    useDialog();
  const [location, setLocation] = useState<string>("Carpeta raíz");
  const [selectedFile, setSelectedFile] = useState<any[]>([]);
  const [selectedFilesWithIds, setSelectedFilesWithIds] = useState<any[]>([]);
  const [selectedFileFolder, setSelectedFileFolder] = useState<string>("");
  const [dataFileState, setDataFileState] = useState<any>(null);
  const [tabValue, setTabValue] = useState<TabValue>("NUEVA CONFIGURACIÓN");
  const [openSaveConfigModal, toggleOpenSaveConfigModal] = useDialog();
  const [openConfigModal, toggleConfigsModal] = useDialog();
  const [openAlerModal, toggleAlertModal] = useDialog();
  const [configurationName, setConfigurationName] = useState("");
  const [runValidations, setRunValidations] = useState(true);

  /*************************** CARGA DE DATOS ***************************/

  const tabs: Tab[] = [
    {
      value: "NUEVA CONFIGURACIÓN",
      label: "NUEVA CONFIGURACIÓN",
    },
    {
      value: "CONFIGURACIONES GUARDADAS",
      label: "CONFIGURACIONES GUARDADAS",
    },
  ];

  const {
    data: dataSchemas,
    isLoading: isLoadingSchemasData,
    callApi: getSchemas,
  } = useApi(
    SCHEMAS_URL,
    "GET",
    texts.adminSchemas.getSchemas.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  const onSuccessGetAlert = (data: any) =>{
    if(data === true){
      handleExecuteSchema()
    }else{
      toggleAlertModal()
    }
    }

  const {
    data: dataAlert,
    isLoading: isLoadingAlert,
    callApi: getAlert,
  } = useApi(
    CLUSTER_ALERT,
    "GET",
    texts.adminSchemas.getSchemas.codes,
    undefined,
    onSuccessGetAlert,
    undefined,
    false
  );

  

  useEffect(() => {
    getSchemas();
  }, []);

  /*useEffect(() => {
    if (
      selectedFilesWithIds.length > 0 &&
      selectedFilesWithIds.length === selectedFile.length
    ) {
      executeSchema(undefined, {
        archivos: selectedFilesWithIds.map((file: any) => {
          return file.id;
        }),
        formato_archivo: formatSelectedOption?.value,
        url_destino: location === "Carpeta raíz" ? "" : location,
      });
    }
  }, [selectedFilesWithIds]);*/

  const [
    schemaSelectedOption,
    setSchemaSelectedOption,
    schemasOptionsChangeHandler,
  ] = useSingleSelect();

  useEffect(() => {
    if (schemaSelectedOption) {
      getConfigs(
        CONFIGURATIONS_BY_SCHEMA_URL(schemaSelectedOption?.value as number)
      );
    } else {
      setConfigurationSelectedOption(undefined);
      setFormatSelectedOption(null);
      setSelectedFileFolder("");
      setLocation("");
      setConfigurationName("");
      setSelectedFile([]);
    }
  }, [schemaSelectedOption]);

  const [
    formatSelectedOption,
    setFormatSelectedOption,
    formatOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    configurationSelectedOption,
    setConfigurationSelectedOption,
    configurationChangeHandler,
  ] = useSingleSelect();

  /******************************* LLAMADOS ******************************** */

  const { isLoading: isLoadingSaveConfig, callApi: saveConfig } = useApi(
    CONFIGURATIONS_BY_SCHEMA_URL(schemaSelectedOption?.value as number),
    "POST",
    texts.flows.postFlowConfigs.codes,
    {
      formato_archivo: formatSelectedOption?.value,
      ubicacion_destino: location === "Carpeta raíz" ? "" : location,
      ubicacion_archivos: selectedFileFolder,
      nombre: configurationName,
    },
    undefined,
    undefined,
    true
  );

  const { isLoading: isLoadingPutConfig, callApi: putConfig } = useApi(
    SCHEMA_CONFIGURATION_BY_ID(
      schemaSelectedOption?.value as number,
      configurationSelectedOption?.value as number
    ),
    "PUT",
    texts.flows.postFlowConfigs.codes,
    {
      formato_archivo: formatSelectedOption?.value,
      ubicacion_destino: location === "Carpeta raíz" ? "" : location,
      ubicacion_archivos: selectedFileFolder,
      nombre: configurationName,
    },
    undefined,
    undefined,
    true
  );

  const { isLoading: isLoadingExecuteSchema, callApi: executeSchema } = useApi(
    EXECUTE_SCHEMA_URL(schemaSelectedOption?.value),
    "POST",
    texts.executeSchemas.codes,
    {
      recursos:
        selectedFile &&
        selectedFile.length > 0 &&
        selectedFile?.map((file: any) => {
          return `${file.ubicacion}${file.nombre}`
        }),
      formato_archivo: formatSelectedOption?.value,
      url_destino: location === "Carpeta raíz" ? "" : location,
      esquema_con_validaciones: runValidations
    },
    ()=> cleanValues(),
    undefined,
    true
  );

  const onSuccessSendFileInfo = (data: any) => {
    setSelectedFilesWithIds((prevState: any) =>
      prevState ? [...prevState, ...data.map((file: any)=>{return {id: file}})] : [...data.map((file: any)=>{return {id: file}})]
    );
    /* executeSchema(undefined, {
        archivos:
          selectedFile.filter(
            (file: any) => file.id !== null && file.id !== undefined
          ).length > 0
            ? [
                data.id,
                ...selectedFile
                  .filter(
                    (file: any) => file.id !== null && file.id !== undefined
                  )
                  .map((file: any) => {
                    return file.id;
                  }),
              ]
            : [data.id],
        formato_archivo: formatSelectedOption?.value,
        url_destino: location === "Carpeta raíz" ? "" : location,
      });*/
  };

  const onErrorSendFileInfo = () => {
    setDataFileState(null);
  };

  const {
    data: dataFiles,
    isLoading: isLoadingFileInfo,
    callApi: sendFiles,
    error: errorFile,
    status: statusFile,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessSendFileInfo,
    onErrorSendFileInfo,
    false,
    undefined,
    undefined,
    { params: { refetchOn401: false } }
  );

  const {
    data: dataConfigs,
    isLoading: isLoadingGetConfigs,
    callApi: getConfigs,
    error: errorGetConfigs,
  } = useApi(
    CONFIGURATIONS_BY_SCHEMA_URL,
    "GET",
    texts.flows.getFlowConfigs.codes,
    undefined,
    undefined, //onSucces
    undefined, //onError
    false,
    undefined,
    undefined
  );

  /*********************************** HANDLERS *************************************** */
  const cleanValues = () => {
    setSelectedFile([]);
    setSchemaSelectedOption(undefined);
    schemaSelectRef.current?.clearValue();
    setFormatSelectedOption(undefined)
    formatSelectRef.current?.clearValue()
    setLocation("Carpeta raíz");
    setSelectedFileFolder("");
    setSelectedFilesWithIds([]);
    setRunValidations(true)
  };

  const handleExecuteSchema = () => {
    executeSchema();
    /*if (
      selectedFile.every(
        (file: any) => file.id !== undefined && file.id !== null
      )
    ) {
      executeSchema();
    } else {
      //sendFiles();
      if (
        selectedFile.some(
          (file: any) => file.id !== undefined && file.id !== null
        )
      ) {
        setSelectedFilesWithIds([
          ...selectedFile.filter(
            (file: any) => file.id !== undefined && file.id !== null
          ),
        ]);
      }
      const selectedFilesWithoutIds = selectedFile.filter((file: any) => file.id === undefined || file.id === null)
        sendFiles(undefined, {
          directorio: true,
          lista_archivos: selectedFilesWithoutIds.map((file: any)=>{return {
            path_archivo: `${selectedFileFolder}${
              selectedFileFolder.endsWith("/") || selectedFileFolder === ""
                ? ""
                : "/"
            }${file.nombre}`,
            tipo: "azure",
            origen_descarga: "esquema",
            actualizar_archivo: true
          }})
        });
    }*/
  };

  const handleChangeSelectedFolder = (location: string) => {
    if (location === "") {
      setLocation("Carpeta raíz");
    } else {
      setLocation(location);
    }
  };

  const handleSetSelectedFiles = (files: any[], type: string) => {
    setSelectedFile(files as any[]);
  };

  const handleChangeTabs = (
    event: React.SyntheticEvent,
    newValue: TabValue
  ) => {
    setTabValue(newValue);
    setFormatSelectedOption(null);
    setSelectedFileFolder("");
    setLocation("");
    setConfigurationName("");
    setSelectedFile([]);
    setConfigurationSelectedOption(undefined);
    setSchemaSelectedOption(undefined)
    schemaSelectRef?.current?.clearValue()
  };

  const handleChangeConfigSelectedOption = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    if (newValue !== null && newValue !== undefined) {
      const selectedConfig: any = dataConfigs.find(
        (config: any) => config.id === newValue?.value
      );
      setFormatSelectedOption({
        value: selectedConfig.formato_archivo,
        label: selectedConfig.formato_archivo,
      });
      setSelectedFileFolder(selectedConfig.ubicacion_archivos);
      setLocation(selectedConfig.ubicacion_destino);
      setConfigurationName(selectedConfig.nombre);
    }
    setTimeout(() => {
      configurationChangeHandler(newValue, actionMeta);
    }, 50);
  };

  const handleAcceptSaveConfig = () => {
    if (tabValue === "NUEVA CONFIGURACIÓN") {
      saveConfig();
    } else {
      putConfig();
    }
    if (openSaveConfigModal) {
      toggleOpenSaveConfigModal();
    }
    toggleConfirmExecuteSchemaModal();
  };

  return (
    <ComponentLayout title="Ejecución de esquemas" icon={<ShopIcon />}>
      <SimpleBackdrop
        open={isLoadingExecuteSchema || isLoadingFileInfo}
        message={texts.executeSchemas.loading}
      />
          <SimpleBackdrop
          open={isLoadingSaveConfig || isLoadingPutConfig || isLoadingAlert}
          message={texts.flows.postFlowConfigs.loading}
        />
      <Stack
        width="100%"
        height="75vh"
        overflow="auto"
        alignItems={"center"}
        gap={2}
      >
        <Stack width="100%" gap={2} justifyContent={"flex-start"}>
          <ColorTabs
            value={tabValue}
            handleChange={handleChangeTabs}
            tabs={tabs}
            marginBottom="10px"
          />
        </Stack>

        {!isLoadingExecuteSchema && (
          <Stack width="80%" gap={2}>
            {tabValue === "CONFIGURACIONES GUARDADAS" && dataConfigs && schemaSelectedOption !== undefined &&(
              <Typography
                sx={{
                  fontWeight: "600",
                  color: "var(--blue)",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  textTransform: "uppercase",
                  marginBottom: "0 !important",
                  marginLeft: "auto",
                  cursor: "pointer",
                }}
                onClick={toggleConfigsModal}
              >
                Gestionar configuraciones
              </Typography>
            )}
            <Stack>
              <InputLabel id="demo-simple-select-filled-label" sx={form_label}>
                Esquema
              </InputLabel>
              <Select
                reference={schemaSelectRef}
                styles={selectStyles(schemaSelectedOption)}
                options={dataSchemas && formatOptions(dataSchemas)}
                onChange={schemasOptionsChangeHandler}
                closeMenuOnSelect
                placeholder="Seleccionar esquema"
                isClearable
                isDisabled={isLoadingSchemasData}
              />
            </Stack>
          </Stack>
        )}
        {tabValue === "NUEVA CONFIGURACIÓN" && (
          <SchemeConfiguration
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            dataFileState={dataFileState}
            setDataFileState={setDataFileState}
            formatSelectRef={formatSelectRef}
            handleChangeSelectedFolder={handleChangeSelectedFolder}
            setSelectedFileFolder={setSelectedFileFolder}
            handleSetSelectedFiles={handleSetSelectedFiles}
            formatOptionsChangeHandler={formatOptionsChangeHandler}
            formatSelectedOption={formatSelectedOption}
            runValidations={runValidations}
            setRunValidations={setRunValidations}
          />
        )}
        {tabValue === "CONFIGURACIONES GUARDADAS" && (
          <>
            {schemaSelectedOption !== undefined &&
              dataConfigs !== undefined && (
                <Stack sx={{ width: "80%" }}>
                  <InputLabel sx={{ ...form_label }}>
                    Elija la configuración
                  </InputLabel>
                  <Select
                    reference={configurationRef}
                    styles={selectStyles(configurationSelectedOption)}
                    options={formatOptions(dataConfigs)}
                    onChange={handleChangeConfigSelectedOption}
                    closeMenuOnSelect
                    placeholder="Seleccionar configuración"
                    isClearable
                  />
                </Stack>
              )}
            {configurationSelectedOption !== undefined && (
              <SchemeConfiguration
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                dataFileState={dataFileState}
                setDataFileState={setDataFileState}
                formatSelectRef={formatSelectRef}
                handleChangeSelectedFolder={handleChangeSelectedFolder}
                setSelectedFileFolder={setSelectedFileFolder}
                handleSetSelectedFiles={handleSetSelectedFiles}
                formatOptionsChangeHandler={formatOptionsChangeHandler}
                formatSelectedOption={formatSelectedOption}
                customInitialRoute={selectedFileFolder}
                filesFolderLocation={location}
                configSelectedOption={configurationSelectedOption?.value}
                runValidations={runValidations}
                setRunValidations={setRunValidations}
              />
            )}
          </>
        )}

        <ConfirmationModal
          open={openConfirmExecuteSchemaModal}
          handleClose={toggleConfirmExecuteSchemaModal}
          handleAccept={()=> { getAlert()
             toggleConfirmExecuteSchemaModal()}}
          title="Ejecución de esquema"
          message="ejecutar el esquema seleccionado"
        />
      </Stack>
      <SaveNameModal
        open={openSaveConfigModal}
        handleClose={toggleOpenSaveConfigModal}
        handleAccept={handleAcceptSaveConfig}
        title={"Guardar configuración de cálculo"}
        text={"Nombre de la nueva configuración"}
        label={"Nombre de la nueva configuración"}
        placeholder={"Nombre de la nueva configuración"}
        dispatchFunction={setConfigurationName}
        valueState={configurationName}
        namesList={dataConfigs}
      />
      <ManageConfigurationsModal
        openConfigsModal={openConfigModal}
        refetchConfigs={getConfigs}
        dataConfigs={dataConfigs}
        handleClose={toggleConfigsModal}
        schemaSelectedOption={schemaSelectedOption}
      />
      <Stack
        width="100%"
        direction="row"
        justifyContent={"center"}
        marginTop={"auto"}
      >
        <Button
          title="Borrar datos"
          color="grey"
          type="button"
          onClick={cleanValues}
        />
        <Button
          title="Ejecutar esquema"
          color="blue"
          type="button"
          onClick={toggleConfirmExecuteSchemaModal}
          disabled={
            /*  (tabValue === "CARGAR ARCHIVOS POR URL" && !file) ||
              (tabValue === "CARGAR ARCHIVOS LOCALES" && !dataFileState) ||*/
            !schemaSelectedOption ||
            /* (isDirectory && selectedRows.length === 0) ||*/
            !formatSelectedOption ||
            selectedFile === undefined ||
            selectedFile?.length === 0 ||
            (tabValue === "CONFIGURACIONES GUARDADAS" &&
              configurationSelectedOption === undefined)
          }
        />
        <Button
          title="Ejecutar y guardar"
          color="blue-greeny"
          type="button"
          onClick={
            tabValue === "NUEVA CONFIGURACIÓN"
              ? toggleOpenSaveConfigModal
              : handleAcceptSaveConfig
          }
          disabled={
            !schemaSelectedOption ||
            !formatSelectedOption ||
            selectedFile === undefined ||
            selectedFile?.length === 0 ||
            (tabValue === "CONFIGURACIONES GUARDADAS" &&
              configurationSelectedOption === undefined)
          }
        />
      </Stack>
        <AlertCluster open={openAlerModal} handleClose={toggleAlertModal}/>
    </ComponentLayout>
  );
};

export default SchemeExecution;
