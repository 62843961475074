import styled, { css } from "styled-components";

export const StyledButton = styled.button<{
  margin?: boolean;
  width: string;
  bgcolor: string;
  disabled?: boolean;
}>`
  align-items: center;
  border-radius: 30px;
  border: none;
  color: white;
  display: flex;
  font-size: 14px;
  font-weight: 100;
  height: 45px;
  justify-content: center;
  letter-spacing: 2px;
  ${(props) =>
    props.margin === false
      ? css`
          margin: 0px;
        `
      : css`
          margin: 10px 10px;
        `};
  padding: 15px;
  text-transform: uppercase;
  transition: all 0.4s;
  ${(props) =>
    css`
      width: ${props.width};
    `};
  ${(props) =>
    props.bgcolor === "outlined"
      ? css`
          background-color: white;
          border: 3px solid var(--blue);
          color: var(--blue);
          font-weight: bold;
        `
      : css`
          background-color: ${props.bgcolor};
        `};
  &:hover {
    background-color: white;
    cursor: pointer;
    font-weight: bold;
    ${(props) =>
      props.bgcolor === "outlined"
        ? css`
            background-color: var(--blue);
            color: white;
          `
        : css`
            border: 2px solid ${props.bgcolor};
            color: ${props.bgcolor};
          `};
  }
  &:active {
    transform: scale(0.95);
    transition: 0.3s all;
  }
  ${(props) =>
    props.disabled === true &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      color: var(--light-grey);
      background-color: #4d4c50;
      &:hover {
        opacity: 0.5;
        cursor: not-allowed;
        color: var(--light-grey);
        background-color: #4d4c50;
        font-weight: 100;
        border: var(--light-grey);
      }
    `};
`;

export const StyledAddButton = styled.button<{
  disabled?: boolean;
  align?: boolean;
  width?: string;
  customColor?: string;
  margin?: string
}>`
  background: var(--blue-greeny);
  border-radius: 50%;
  border: 1px solid var(--blue-greeny);
  color: white;
  font-size: 30px;
  height: 38px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s;
  vertical-align: middle;
  width: 38px;
  min-width: 38px;
  margin: 0 0 0 10px;
  &:hover {
    background: white;
    cursor: pointer;
    font-weight: bold;
    color: var(--blue-greeny);
  }
  &:active {
    transform: scale(0.95);
    transition: 0.3s all;
  }
  ${(props) =>
    props.disabled === true &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      color: var(--light-grey);
      background-color: #4d4c50;
      &:hover {
        opacity: 0.5;
        cursor: not-allowed;
        color: var(--light-grey);
        background-color: #4d4c50;
        font-weight: 100;
        border: var(--light-grey);
      }
    `};
  ${(props) =>
    props.align === true &&
    css`
      margin: 0px;
    `};
  ${(props) =>
    props.width === "little" &&
    css`
      font-size: 19px;
      height: 26px;
      width: 26px;
      min-width: 26px;
    `};
      ${(props) =>
    props.width === "medium" &&
    css`
      font-size: 26px;
      height: 36px;
      width: 36px;
      min-width: 36px;
    `};
      ${(props) =>
    props.width === "tiny" &&
    css`
      font-size: 16px;
      height: 24px;
      width: 24px;
      min-width: 24px;
    `};
  ${(props) =>
    props.customColor &&
    css`
      background: ${props.customColor};
      border: 1px solid ${props.customColor};
    `};
      ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
`;
