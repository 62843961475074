import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import SpeedDialTooltipOpen from "../buttons/SpeedDialTooltipOpen";
import { getStringNames, truncateString } from "./utils";
import { Tooltip } from "@mui/material";
import { convertArrayToText } from "../../utils/util";

const CriteriaCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  showInfo,
  handleEditFile
}: any) => {
  return (
    <>
      <Stack sx={{ padding: "5px 10px 10px 10px" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "42px",
            wordBreak: "break-word",
          }}
        >
          <Tooltip title={item.nombre}>
            <Typography
              variant="h6"
              sx={{
                color: "var(--blue)",
                fontWeight: "800",
                textTransform: "uppercase",
                fontSize: "18px",
                lineHeight: "1",
                width: "98%",
              }}
            >
              {truncateString(item?.nombre, 35)}
            </Typography>
          </Tooltip>
        </Stack>

        <Divider sx={{ margin: "10px 0" }} />
        {showInfo && (
          <>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
              }}
              color="var(--blue)"
            >
              Cabecera:{" "}
              <span style={{ color: "var(--light-grey)" }}>
                {item?.indice_fila_encabezado || item?.exp_regular_encabezado
                  ? `${item?.indice_fila_encabezado !== null ?  `FILA ${item?.indice_fila_encabezado +1}. ` : ""}` +
                  `${(item?.exp_regular_encabezado !== "" && item?.exp_regular_encabezado !== null)  ? `COLUMNA: ${item?.exp_regular_encabezado.toUpperCase()} ` : ""}` 

                  : "Sin modificar"}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                marginTop: "5px",
              }}
              color="var(--blue)"
            >
              Filas a eliminar: <br />
              <span style={{ color: "var(--light-grey)" }}>
                {item.criterio_eliminacion?.length
                  ? convertArrayToText(
                      item.criterio_eliminacion.map(
                        (row: string) => `${parseInt(row) < 0 ? parseInt(row) : parseInt(row) + 1}`
                      )
                    )
                  : "-"}
              </span>
            </Typography>
            <Tooltip title={getStringNames(item?.renombre_cabecera)}>
              <span>
                <Typography
                  variant="subtitle1"
                  sx={{
                    textAlign: "left",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    letterSpacing: "-1px",
                  }}
                  color="var(--blue)"
                >
                  Columnas:{" "}
                  <span
                    style={{ color: "var(--light-grey)", fontSize: "15px" }}
                  >
                    {item?.renombre_cabecera && item?.renombre_cabecera.length > 0 ? truncateString(
                      getStringNames(item?.renombre_cabecera),
                      35
                    ) : "No se renombraron columnas"}
                  </span>
                </Typography>
              </span>
            </Tooltip>
            <Typography
                  variant="subtitle1"
                  sx={{
                    textAlign: "left",
                    fontWeight: "700",
                    letterSpacing: "-0.5px",
                  }}
                  color="var(--blue)"
                >
                  FECHA DE CREACIÓN:{" "}
                  <span style={{ color: "var(--light-grey)", wordBreak: "break-word",
 }}>              {new Date(item?.fecha_creacion).toLocaleString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false })}
                  </span>
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    textAlign: "left",
                    fontWeight: "700",
                    letterSpacing: "-0.5px",
                  }}
                  color="var(--blue)"
                >
                  ÚLTIMA MODIFICACIÓN:{" "}
                  <span style={{ color: "var(--light-grey)", wordBreak: "break-word",
 }}>              {new Date(item?.fecha_modificacion).toLocaleString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false })}
                  </span>
                </Typography>
          </>
        )}
        <SpeedDialTooltipOpen
          id={item.id}
          handleEdit={handleEdit}
          handleCopy={handleCopy}
          handleInfo={handleInfo}
          handleDelete={handleDelete}
        />
      </Stack>
    </>
  );
};

export default CriteriaCard;
