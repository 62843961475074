import Stack from "@mui/material/Stack";
import { useState } from "react";
import Dialog from "../dialog/Dialog";
import { Tooltip, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import DownloadIcon from "@mui/icons-material/Download";
import useApi from "../../hooks/useApi";
import { DOWNLOAD_FILE } from "../../api/axios";
import { texts } from "../../texts";
import { getFileExtension } from "../../utils/util";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";

interface ViewReportModalProps {
  openReportModal: boolean;
  handleToggleReportModal: () => void;
  report: any;
}

const ViewReportModal = ({
  openReportModal,
  handleToggleReportModal,
  report,
}: ViewReportModalProps) => {
  const [isIconClicked, setIsIconClicked] = useState(false);

  const { isLoading: isLoadingDownloadResource, callApi: downloadResource } =
    useApi(
      DOWNLOAD_FILE,
      "POST",
      texts.dataManagement.downloadResource.codes,
      {
        lista_archivos: [
          {
            tipo: "archivo",
            ruta: report,
          },
        ],
      },
      undefined,
      undefined,
      false,
      undefined,
      true,
      {
        fileName: report?.split("/").pop(),
        fileType: `${getFileExtension(report)}`,
      }
    );

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.
      writeText(report?.ubicacion_reporte);
      setIsIconClicked(true);
      setTimeout(() => setIsIconClicked(false), 2000);
    } catch (error) {
      console.error("Error al copiar al portapapeles:", error);
    }
  };

  return (
    <Dialog
      open={openReportModal}
      handleClose={handleToggleReportModal}
      title={`Reporte de cálculo del flujo`}
      maxWidth="xl"
    >
      <SimpleBackdrop
        open={isLoadingDownloadResource}
        message={texts.dataManagement.downloadResource.loading}
      />
      <Stack
        sx={{
          minWidth: "1000px",
          minHeight: "100px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "22px",
            color: "var(--dark-blue)",
            marginBottom: "15px",
          }}
        >
          Ubicación del reporte:
        </Typography>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            gap: "5px",
          }}
        >
          <Typography
            sx={{
              color: "var(--light-blue)",
              fontWeight: "bold",
              fontSize: "20px",
              wordBreak: "break-word",
            }}
          >
            {report}
          </Typography>
          <Tooltip
            title={isIconClicked ? "Copiado!" : "Copiar ubicación del archivo"}
            placement={"right"}
            sx={{
              pointerEvents: "fill",
            }}
          >
            {isIconClicked ? (
              <DoneOutlineIcon
                sx={{
                  cursor: "pointer",
                }}
                color={"primary"}
              />
            ) : (
              <ContentCopyIcon
                sx={{
                  cursor: "pointer",
                }}
                onClick={handleCopyClick}
                color={"primary"}
              />
            )}
          </Tooltip>
          <Tooltip
            title={"Descargar archivo"}
            placement={"right"}
            sx={{
              pointerEvents: "fill",
            }}
          >
            <DownloadIcon
              sx={{
                cursor: "pointer",
              }}
              onClick={() => downloadResource()}
              color={"primary"}
            />
          </Tooltip>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ViewReportModal;
