import FolderIcon from "@mui/icons-material/Folder";
import { Stack, Tooltip, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useNavigate } from "react-router";
import {  useContext, useState } from "react";
import useDialog from "../../hooks/useDialog";
import FileDetailModal from "./FileDetailModal";
import FileTraceabilityModal from "./FileTraceabilityModal";
import { getRouteWithFolder } from "../flowConfiguration/Modals/AddFont/utils";
import DataDropdownMenu from "./DataDropdownMenu";
import { Label } from "./ResourceLabels/types";
import { truncateText } from "../taskManager/utils";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { formatDate } from "../../utils/util";
import { menu_logo_alpha_styles, menu_logo_phi_folder_styles } from "../../styles/app-styles";
import logoPhi from "../../assets/logoPhi.png";
import DataManagementProvider from "../../context/DataManagementContext";



interface FolderCardProps {
  name: string;
  type: string;
  folderLocation: string;
  date: string;
  modifiedDate?: string;
  periodsData?: any
  viewType: "list" | "cards";
  refreshResources?: () => void;
  size?: number;
  report?: any;
  label?: Label;
  id?: number;
  isSelectedModeActive: boolean;
  setSelectedResource: (resource: any) => void;
  isSelectedGlobal: boolean;
  isExternalResource?: boolean;
  resourceId?:number;
}

const FolderCard = ({
  name,
  type,
  folderLocation,
  date,
  modifiedDate,
  size,
  id,
  report,
  refreshResources,
  label,
  isSelectedModeActive,
  viewType,
  setSelectedResource,
  isSelectedGlobal,
  isExternalResource,
  resourceId,
  periodsData
}: FolderCardProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const [openModal, handleToggleOpenModal] = useDialog();
  const [openTraceabilityModal, handleToggleTraceabilityOpenModal] =
    useDialog();
    const { DataState, dataDispatch } = useContext<
    React.ContextType<typeof DataManagementProvider>
  >(DataManagementProvider);

  /*********** Menú desplegable *****************/

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  /****************** ACTUALIZACIONES ****************************/

  const handleClickOnResource = (event: React.MouseEvent<HTMLElement>) => {
    if (isSelectedModeActive) {
      setSelectedResource({ name, type, folderLocation, id });
    } else {
      
      const target = event.target as any;
      const id = target.id;
      if (id === "viewDetails" ||target?.nearestViewportElement?.id === "viewDetails") {
        handleOpenUserMenu(event);
      } else {
        if(type === "carpeta"){
          navigate(
            "/administracion-datos" +
              getRouteWithFolder(
                `${
                  folderLocation.endsWith("/")
                    ? folderLocation.startsWith("/")
                      ? folderLocation
                      : "/" + folderLocation
                    : folderLocation + "/"
                }${name}`
              )
          )
          dataDispatch({
            type: "SET_PERIODS_INFO",
            payload: periodsData,
          });
        }else{
          handleToggleOpenModal();

        }
         
      }
    }
  };

  return (
    <>
      {viewType === "list" ? (
        <Stack
          sx={{
            backgroundColor: "var(--very-very-light-grey)",
            padding: "5px",
            borderRadius: "15px",
            width: "100%",
            alignItems: "center",
            cursor: "pointer",
            flexDirection: "row",
            margin: "0 !important",
          }}
          onClick={handleClickOnResource}
        >
          <Stack
            sx={{ width: "100%", flexDirection: "row", alignItems: "center" }}
          >
            <Stack
              sx={{
                margin: "0 !important",
                width: "40px",
                alignItems: "center",
              }}
            >
              {isSelectedModeActive &&
                (isSelectedGlobal ? (
                  <RadioButtonCheckedIcon
                    sx={{
                      fontSize: "22px",
                      color: "var(--blue)",
                      borderRadius: "50%",
                      backgroundColor: "white",
                    }}
                  />
                ) : (
                  <RadioButtonUncheckedIcon
                    sx={{
                      fontSize: "22px",
                      color: "var(--blue)",
                      borderRadius: "50%",
                      backgroundColor: "white",
                    }}
                  />
                ))}
            </Stack>
            {type === "carpeta" ? (
              name.toUpperCase() === "PHI" ?  <img style={menu_logo_alpha_styles}
              src={logoPhi}
              alt="logo"
            /> :
              <FolderIcon
                style={{
                  fontSize: 60,
                  color: label?.color ? label.color : "var(--dark-blue)",
                }}
              />
            ) : (
              <InsertDriveFileIcon
                style={{
                  fontSize: 60,
                  color: label?.color ? label.color : "var(--dark-blue)",
                }}
              />
            )}
            <Stack
              sx={{
                margin: "0 !important",
                marginLeft: "60px !important",
                width: "290px",
                marginRight: "50px !important",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{
                  fontSize: name.length < 18 ? "18px" : "14px",
                  color: "var(--blue)",
                  wordBreak: name.length < 20 ? undefined : "break-all",
                }}
              >
                  {name.toUpperCase() !== "PHI" ? name : ""}
              </Typography>
            </Stack>

            <Stack sx={{ margin: "0 !important", width: "150px" }}>
              {label?.nombre !== undefined && (
                <Tooltip title={label.nombre}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "white",
                      backgroundColor: label?.color,
                      borderRadius: "8px",
                      padding: "0px 4px",
                    }}
                  >
                    {truncateText(label?.nombre, 16)}
                  </Typography>
                </Tooltip>
              )}
            </Stack>
            <Typography
              sx={{
                fontSize: "14px",
                color: "var(--blue)",
                marginLeft: "100px",
                marginRight: "70px",
                width: "205px",
                wordBreak: name.length < 20 ? undefined : "break-all",
              }}
            >
              {formatDate(date)}
            </Typography>
            {size && (
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "var(--blue)",
                  wordBreak: name.length < 20 ? undefined : "break-all",
                }}
              >
                {`${size.toFixed(1)} mb`}
              </Typography>
            )}
          </Stack>

          <MoreVertIcon
            id="viewDetails"
            style={{
              fontSize: 30,
              color: "var(--dark-blue)",
              marginLeft: "auto",
              zIndex: "200",
            }}
          />
        </Stack>
      ) : (
        <Stack
          sx={{
            backgroundColor: "var(--very-very-light-grey)",
            boxShadow: "6px 13px 20px -10px rgba(0,0,0,01)",
            padding: "14px",
            paddingTop: label && label?.nombre?.length > 45 ? "22px" : "14px",
            borderRadius: "15px",
            width: "290px",
            alignItems: "center",
            cursor: "pointer",
            position: "relative",
            zIndex: "1000",
          }}
          onClick={handleClickOnResource}
        >
          {isSelectedModeActive &&
            (isSelectedGlobal ? (
              <RadioButtonCheckedIcon
                sx={{
                  fontSize: "22px",
                  color: "var(--blue)",
                  position: "absolute",
                  borderRadius: "50%",
                  top: 2,
                  left: 1,
                  backgroundColor: "white",
                }}
              />
            ) : (
              <RadioButtonUncheckedIcon
                sx={{
                  fontSize: "22px",
                  color: "var(--blue)",
                  position: "absolute",
                  borderRadius: "50%",
                  top: 2,
                  left: 1,
                  backgroundColor: "white",
                }}
              />
            ))}
          {label?.nombre !== undefined && (
            <Tooltip title={label.nombre}>
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "white",
                  position: "absolute",
                  top: 2,
                  right: 1,
                  backgroundColor: label?.color,
                  borderRadius: "8px",
                  padding: "0px 4px",
                  marginBottom: "20px",
                }}
              >
                {truncateText(label?.nombre, 16)}
              </Typography>
            </Tooltip>
          )}

          <Stack
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 !important",
              zIndex: "1500 !important",
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {type === "carpeta" ? (
                    name.toUpperCase() === "PHI" ?  <img style={menu_logo_phi_folder_styles}
                    src={logoPhi}
                    alt="logo"
                  /> :
                <FolderIcon
                  style={{
                    fontSize: 60,
                    color: label?.color ? label.color : "var(--dark-blue)",
                  }}
                />
              ) : (
                <InsertDriveFileIcon
                  style={{
                    fontSize: 60,
                    color: label?.color ? label.color : "var(--dark-blue)",
                  }}
                />
              )}

              <Typography
                sx={{
                  fontSize: name.length < 18 ? "18px" : "14px",
                  color: "var(--blue)",
                  marginRight: "auto",
                  marginLeft: "10px",
                  wordBreak: name.length < 20 ? undefined : "break-all",
                }}
              >
                {name.toUpperCase() !== "PHI" ? name : ""}
              </Typography>
            </Stack>
            <MoreVertIcon
              id="viewDetails"
              style={{
                fontSize: 30,
                color: "var(--dark-blue)",
                width: "50px",
                zIndex: "200",
              }}
            />
          </Stack>
        </Stack>
      )}

      <FileDetailModal
        name={name}
        type={type}
        id={id}
        date={date}
        modifiedDate={modifiedDate ? modifiedDate : ""}
        size={size}
        label={label}
        fileLocation={folderLocation}
        open={openModal}
        handleClose={handleToggleOpenModal}
        folderLocation={folderLocation}
        refreshResources={refreshResources}
        isExternalResource={isExternalResource}
        resourceId={resourceId}
      />
      <FileTraceabilityModal
        name={name}
        type={type}
        open={openTraceabilityModal}
        handleClose={handleToggleTraceabilityOpenModal}
        reporte={report}
      />
      <DataDropdownMenu
        anchorEl={anchorElUser}
        name={name}
        type={type}
        folderLocation={folderLocation}
        handleCloseUserMenu={handleCloseUserMenu}
        refreshResources={refreshResources}
        date={date}
        modifiedDate={modifiedDate}
        label={label}
        id={id}
        size={size}
        isExternalResource={isExternalResource}
        resourceId={resourceId}
      />
    </>
  );
};

export default FolderCard;
