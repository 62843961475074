import { InputLabel, Stack } from "@mui/material";
import useDialog from "../../hooks/useDialog";
import { Button } from "../buttons";
import Dialog from "../dialog/Dialog";
import { form_label } from "../../styles/app-styles";
import CheckmarkSelect from "./CheckmarkSelect";
import { getIdPeriod, getPeriodById, periods } from "./utils";
import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { texts } from "../../texts";
import { CALCULATED_PERIODS } from "../../api/axios";



const CalculatedPeriodsModal = () => {
  const [openConfigModal, toggleConfigModal] = useDialog();
  const [selectedPeriods, setSelectedPeriods] = useState<number[]>([]);

  const {
    isLoading: isLoadingPutCalculatedPeriods,
    callApi: putCalculatedPeriods,
  } = useApi(
    CALCULATED_PERIODS,
    "POST",
    texts.dataControl.putCalculatedPeriods.codes,
    undefined,
     undefined,
    undefined,
    false,
  );

  const onSuccessGetPeriods = (data: any)=>{
    setSelectedPeriods(data?.calculados?.length ? data?.calculados?.map((period: any) => getIdPeriod(period)) : [])
    }

  const {
    isLoading: isLoadingGetCalculatedPeriods,
    callApi: getCalculatedPeriods,
    data: dataPeriods
  } = useApi(
    CALCULATED_PERIODS,
    "GET",
    texts.dataControl.getCalculatedPeriods.codes,
    undefined,
    onSuccessGetPeriods,
    undefined,
    false,
  );

  const handleAccept = () => {
    toggleConfigModal();
    putCalculatedPeriods(undefined, {
        calculados: selectedPeriods?.map((p: any) => getPeriodById(p)),
    })
  };

  useEffect(() => {
    getCalculatedPeriods();
  }, []);

  return (
    <>
      <Stack sx={{ marginLeft: "auto" }}>
      <Button
          title="Períodos calculados"
          type="button"
          onClick={toggleConfigModal}
          disabled={false}
          margin={false}
          color="blue"
        />
      </Stack>
      <Dialog
        open={openConfigModal}
        handleClose={toggleConfigModal}
        maxWidth="xl"
        title={"Editar configuración de períodos"}
      >
        <Stack sx={{ width: "60vw" }}>
        <Stack sx={{ marginBottom: "8px", margin:"auto" }}>
                    <InputLabel sx={{ ...form_label }}>Periodos calculados:</InputLabel>
                    <CheckmarkSelect
                      dates={periods}
                      selectedPeriods={selectedPeriods as number[]}
                      setSelectedPeriods={setSelectedPeriods}
                    />
                  </Stack>
            <Stack direction="row" mb="15px" margin={"auto"}>
            <Button
              title="Cancelar"
              color="grey"
              type="button"
              onClick={toggleConfigModal}
            />
            <Button
              title="Aceptar"
              color="blue"
              type="button"
              onClick={handleAccept}
            />
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default CalculatedPeriodsModal;
