import { useEffect, useRef, useState } from "react";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts.js";
import { FLOW_ITEMS, GET_FLOW_URL, NEW_GET_COLUMNS_FLOW_URL } from "../../../api/axios";
import {
  checkIfAllColumnNotExists,
  checkIfColumnNotExists,
  checkIfNodeHasPendingEdges,
  filterIdFromColumns,
  setErrorFlowNodes,
  setInitialFlowEdges,
  setInitialFlowNodes,
} from "../utils";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import BaseModal from "./BaseModal";
import ShadowBox from "./ShadowBox";
import useDialog from "../../../hooks/useDialog";
import CheckmarkSelect from "../../forms/CheckmarkSelect";
import { TextField, Typography } from "@mui/material";
import { useStore } from "../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
import { getBackendId } from "../../../utils/util";
interface DeleteColumnProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  id: string;
  backendId?: any;
  parentIds?: any[];
  sourceItemId: string;
  nodeData?: any

}
const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
  setInitialNodes: store.setInitialNodes,
  setInitialEdges: store.setInitalEdges,
  updateIsFlowLoading: store.updateIsFlowLoading
});

const DeleteColumn = ({
  open,
  handleClose,
  dataItem,
  id,
  backendId,
  sourceItemId,
  parentIds,
  nodeData
}: DeleteColumnProps) => {
  const store = useStore(selector, shallow);
  const nodesRef = useRef<any[]>([]);
  nodesRef.current = store.nodes;
  const [comment, setComment] = useState<any>("");

  const [openConfirmationModal, toggleConfirmationModal] = useDialog();

  const [selectedColumns, setSelectedColumns] = useState<number[]>([]);

  /********************************* CARGA DE DATOS ***********************************/

  const {
    data: dataColumns,
    isLoading: isLoadingColumns,
    callApi: getColumns,
  } = useApi(
    undefined,
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if (open) {
      getColumns(
        NEW_GET_COLUMNS_FLOW_URL(
          store.flow.id,
          getBackendId(sourceItemId, store.nodes) as number
        )
      );
    }
  }, [open]);

  useEffect(() => {
    if (dataItem && open) {
      setComment(dataItem.comentario)
      setSelectedColumns(
        dataItem.detalle_item?.map((c: any) => {
          return { id: c.value, label: c.label };
        })
      );
    }
  }, [dataItem, open]);

  /******************************* POST Y PUT ITEM *********************************************/

  const onSuccessGetFlow = (data: any) => {
    store.setInitialNodes(setErrorFlowNodes(nodesRef.current , data.items));
  };

  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  
  useEffect(() => {
    store.updateIsFlowLoading(isLoadingFlow)
  }, [isLoadingFlow]);

  // Actualizamos el nodo insertandole el id del backend que es el que vamos a utilizar
  // cuando enviemos datos al back
  const onSuccessPostItem = (data: any) => {
    store.updateNode(id, {
      selectedColumns: selectedColumns
        .filter((col: any) => {
          return (
            col.id !== "todas" &&
            !checkIfColumnNotExists(
              col.label,
              dataColumns.map((col: any) => {
                return { label: col.columna };
              })
            )
          );
        })
        .map((column: any) => {
          return column.label;
        }),
        backendId: backendId ? backendId : data.id,
        error: data.posee_error,
        errorMessage: data.mensaje_error,
              comentario: comment
    });
    checkIfNodeHasPendingEdges(id, putConnection, data.id)
    getFlow(GET_FLOW_URL(parseInt(store.flow.id)));

  };

  const {
    isLoading: isLoadingPostDeleteColumn,
    callApi: postDeleteColumn,
    error: errorPostDeleteColumn,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutDeleteColumn,
    callApi: putDeleteColumn,
    error: errorPutDeleteColumn,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const saveDeleteInfo = () => {
    const columnsToSelect: any = [];
    selectedColumns
      .filter((col: any) => {
        return (
          col.id !== "todas" &&
          !checkIfColumnNotExists(
            col.label,
            dataColumns.map((col: any) => {
              return { label: col.columna };
            })
          )
        );
      })
      .map((column: any) => {
        columnsToSelect.push(column.label);
      });
    const completedInfo = {
      tipo: "seleccionar_columna",
      columna: columnsToSelect,
      comentario: comment,
      editar_detalle_item: true,
      calcular: true,
      parent_ids: [getBackendId(sourceItemId, store.nodes)],
      id: backendId ? backendId : undefined,
      ...nodeData.position

    };
    if (dataItem) {
      putDeleteColumn(undefined, [completedInfo]);
    } else {
      postDeleteColumn(undefined, completedInfo);
    }
    cleanAndClose();
    toggleConfirmationModal();
  };

  const {
    isLoading: isLoadingPutConnection,
    callApi: putConnection,
    error: errorPutConnection,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  /******************************* VALIDACIONES *********************************************/

  const isFormInCompleted = () => {
    return selectedColumns === undefined || selectedColumns?.length === 0 || selectedColumns?.length -1 === dataColumns?.length
      ? true
      : false ||
          (selectedColumns &&
            dataColumns &&
            checkIfAllColumnNotExists(selectedColumns, dataColumns));
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    setSelectedColumns([]);
    setComment("")
    handleClose();
  };

  return (
    <BaseModal
      open={open}
      title="Seleccionar columnas"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={saveDeleteInfo}
      confirmationModalMessage={
        selectedColumns && selectedColumns.length > 1
          ? "seleccionar las columnas"
          : "seleccionar la columna"
      }
      disabledAcceptButton={isFormInCompleted()}
      height="400px"
      hasResultsTable
      source_id={getBackendId(sourceItemId, store.nodes)}
    >
      <SimpleBackdrop open={isLoadingColumns} message={"Cargando datos..."} />
      {!dataItem &&   <ShadowBox margin>
            <TextField
              id="comment"
              label={"Comentario"}
              placeholder={"Comentario"}
              size="small"
              multiline
              variant="outlined"
              fullWidth
              value={comment}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setComment(event.target.value);
              }}
              FormHelperTextProps={{
                sx: {
                  color: "var(--magenta)",
                },
              }}
              required
            />
          </ShadowBox>}
    
      <ShadowBox margin>
        {selectedColumns &&
          dataColumns &&
          selectedColumns
            .filter((col: any) => col.id !== "todas")
            .some((col: any) =>
              checkIfColumnNotExists(
                col.label,
                dataColumns.map((item: any) => {
                  return { id: item.id, label: item.columna };
                })
              )
            ) && (
            <Typography
              sx={{
                color: "var(--red)",
                marginLeft: "10px",
                marginBottom: "10px",
              }}
            >
              Las columnas no existentes en la fuente actual no se tendrán en
              cuenta y se borrarán en pasos posteriores.
            </Typography>
          )}

        <CheckmarkSelect
          label="Seleccionar columnas"
          selectedItems={selectedColumns}
          setSelectedItems={setSelectedColumns}
          items={filterIdFromColumns(dataColumns)?.map((item: any) => {
            return { id: item.id, label: item.columna };
          })}
          width="100%"
        />
      </ShadowBox>
    </BaseModal>
  );
};

export default DeleteColumn;
