import { Stack, Typography } from "@mui/material";
import { Button } from "../buttons";
import Dialog from "../dialog/Dialog";

interface AlertClusterModalProps {
  open: boolean;
  handleClose: () => void;
}

const AlertClusterModal = ({
  open,
  handleClose,
}: AlertClusterModalProps) => {
  return (
    <Dialog open={open} handleClose={handleClose} maxWidth="xl" title={"No es posible realizar la ejecución"}>
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: "10px",
            color: "#172D40",
            marginTop: "30px",
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          No es posible realizar esta acción. Solicite el alta del cluster para poder calcular.
        </Typography>
        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Aceptar"
            type="button"
            onClick={handleClose}
            color="blue-greeny"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default AlertClusterModal;
