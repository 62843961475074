import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";
import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import { texts } from "../../../texts";
import {
  FILE_INFO_URL,
} from "../../../api/axios";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { isExcelFile } from "../../flowConfiguration/utils";
import { getRoute } from "../../flowConfiguration/Modals/AddFont/utils";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import DelimiterOptions from "./DelimiterOptions";
import DataManagementProvider from "../../../context/DataManagementContext";

interface SelectDelimiterModalProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: (files: any[]) => void;
  filesToUpload: any[] | null;
  filesToUploadWithoutDelimiter: any[] | null;
}

const SelectDelimiterModal = ({
  open,
  handleClose,
  handleAccept,
  filesToUpload,
  filesToUploadWithoutDelimiter
}: SelectDelimiterModalProps) => {
  const [filesState, setFilesState] = useState<any[]>([]);
  const [filesWithInfo, setFilesWithInfo] = useState<any[]>([]);
  const [totalConfigs, setTotalConfigs] = useState<any>(0);
  const [counter, setCounter] = useState<any>(0);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const axiosPrivate = useAxiosPrivate();
  const { DataState, dataDispatch } = useContext<
  React.ContextType<typeof DataManagementProvider>
>(DataManagementProvider);
  
  const insertFiles = async (
    fileToUpload: any,
    axiosPrivate: any,
    handleUploadFileResponse: any
  ) => {
      try {
        const fileInfo = filesState.find((f: any) => {
          return fileToUpload.name === f.nombre;
        });
        const formData = new FormData();
        formData.append("archivo", fileToUpload);
        fileInfo?.delimitador !== "inferir" && fileInfo?.delimitador !== undefined &&formData.append("delimitador", fileInfo?.delimitador);
        formData.append("tipo", "archivo_local");
        formData.append(
          "ruta_destino",
          decodeURIComponent(
            getRoute(`${location.pathname}`)
          )
        );
        formData.append("carga_datos_detalle", true as unknown as string);
        setIsLoadingFile(true);
        const response = await axiosPrivate.post(FILE_INFO_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
  
        handleUploadFileResponse(response.status, response.data);
      } catch (error: any) {
        if (error.code === "ERR_NETWORK") {
          handleUploadFileResponse(502, fileToUpload);
        } else {
          handleUploadFileResponse(500, fileToUpload);
        }
      }
    
   
  };

  const handleAcceptUpload = async () => {
    const totalValidations = filesState.filter((val: any)=> val.delimitador !== undefined)
    setTotalConfigs(totalValidations.length + (filesToUploadWithoutDelimiter?.length ? filesToUploadWithoutDelimiter?.length : 0) )
    const totalFiles = [...filesToUpload as any[], ...(filesToUploadWithoutDelimiter?.length ? filesToUploadWithoutDelimiter : [])]
    for (const file of totalFiles as any) {
      // Esperamos a que se complete el proceso de subida de cada archivo antes de pasar al siguiente
      await insertFiles(file, axiosPrivate, handleUploadFileResponse);
    }
  }

  const handleUploadFileResponse = (status: any, fileData: any) => {
    if (status !== 500 && status !== 502) {
      setCounter((prevState: any) => prevState+ 1);
      setFilesWithInfo(filesWithInfo?.length > 0 ? [...filesWithInfo, fileData]: [fileData])
    }
  };


  useEffect(() => {
    if (filesToUpload && filesToUpload.length > 0) {
      const validFiles: any[] = [];

      filesToUpload.forEach((file: any) => {
        if (!isExcelFile(file.nombre)) {
          validFiles.push({
            nombre: file.nombre ? file.nombre : file.name,
            id: file.id ? file.id : file.nombre,
          });
        }
      });

      setFilesState(validFiles);
    }
  }, [filesToUpload]);

  useEffect(() => {
    if (counter !== 0 && counter === totalConfigs) {
      setIsLoadingFile(false);
      handleAccept(filesWithInfo)
      clean()
      if( DataState?.periodsInfo?.length == 0 ||
        (DataState?.periodsInfo?.length > 0 && DataState?.periodsInfo[0]?.padre === false)){
        handleClose();
      }
    }
  }, [counter]);


  const clean = () => {
    setFilesState([]);
    setIsLoadingFile(false);
    setCounter(0)
    setTotalConfigs(0)
    
  };

  return (
    <Dialog
      open={open}
      handleClose={clean}
      maxWidth="xl"
      title={"Seleccionar delimitador"}
    >
          <SimpleBackdrop
        open={isLoadingFile}
        message={texts.dataManagement.postPeriodsAndFile.loading}
      />
      <Stack>
        {filesToUpload?.map((file: any, index: number) => {
          return (
            <DelimiterOptions
              file={file}
              key={index}
              setFilesState={setFilesState}
              filesState={filesState}
            />
          );
        })}
        <Stack
          direction="row"
          sx={{ marginTop: "25px", width: "100%", justifyContent: "center" }}
        >
          <Button
            title="Cancelar"
            type="button"
            onClick={clean}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={() => {
              handleAcceptUpload();
            }}
            color="blue-greeny"
            disabled={filesState.some((file: any)=> file.delimitador === undefined)}
          />

        </Stack>
     
      </Stack>
    </Dialog>
  );
};

export default SelectDelimiterModal;
