import {
  Box,
  Checkbox,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  deleteSlash,
  frecuencyOptions,
  getIdPeriod,
  getPeriodById,
  periods,
} from "./utils";
import { Button } from "../buttons";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { form_label } from "../../styles/app-styles";
import Select from "../forms/Select";
import useSingleSelect from "../../hooks/useSingleSelect";
import { optionalSelectStyles, selectStyles } from "../../styles/select.styles";
import CheckmarkSelect from "./CheckmarkSelect";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmationModal } from "../dialog";
import useDialog from "../../hooks/useDialog";
import useApi from "../../hooks/useApi";
import { texts } from "../../texts";
import { FONT_FILE_BY_ID } from "../../api/axios";
import ExploreFolders from "../dataManagement/UploadFileModal/ExploreFolders";
import Validations from "./Validations";
import InfoIcon from "@mui/icons-material/Info";

interface FontFileProps {
  index: any;
  editingIndex: any;
  handleEdit: (index: number | null) => void;
  fontFile: any;
  refetchFontFiles: () => void;
  onSuccessCreateFile: () => void;
}

const FontFile = ({
  index,
  fontFile,
  refetchFontFiles,
  editingIndex,
  handleEdit,
  onSuccessCreateFile,
}: FontFileProps) => {
  const [fileNameInput, setFileNameInput] = useState(fontFile.nombre);
  const [responsible, setResponsible] = useState(fontFile.responsable);
  const [selectedPeriods, setSelectedPeriods] = useState<number[]>(
    fontFile?.periodos?.map((period: any) => getIdPeriod(period))
  );
  const [isCriticalFile, setIsCriticalFile] = useState<boolean>(
    fontFile.critico
  );
  const [location, setLocation] = useState<string>(
    fontFile.ruta_directorio && fontFile.ruta_directorio !== "/"
      ? fontFile.ruta_directorio.endsWith("/")
        ? fontFile.ruta_directorio
        : fontFile.ruta_directorio + "/"
      : ""
  );
  const [validations, setValidations] = useState<any>(fontFile?.validacion);
  const [activeStep, setActiveStep] = useState<number>(0);

  const [openConfirmDeleteNameModal, toggleConfirmDeleteFileModal] =
    useDialog();

  const frecuencyRef: any = useRef(null);

  const [
    frecuencySelectedOption,
    setFrecuencySelectedOption,
    frecuencyChangeHandler,
  ] = useSingleSelect(undefined, undefined, {
    value: fontFile?.frecuencia_actualizacion,
    label: fontFile?.frecuencia_actualizacion,
  });

  const handleDelete = () => {
    toggleConfirmDeleteFileModal();
    deleteFile();
  };

  const cancelEdit = () => {
    handleEdit(null);
  };

  const handleAccept = () => {
    handleEdit(null);
    editFile(undefined, {
      nombre: fileNameInput,
      responsable: responsible,
      critico: isCriticalFile,
      periodos: selectedPeriods?.map((p: any) => getPeriodById(p)),
      frecuencia_actualizacion: frecuencySelectedOption?.value,
      ruta_directorio: location === "" ? "/" : deleteSlash(location),
      validacion: {
        archivo_base: validations?.withoutFile ? null : validations?.file.id,
        hoja_archivo_base: validations?.withoutFile
          ? null
          : validations?.sheet?.value,
        indice_fila_encabezado: validations?.header?.index
          ? parseInt(validations?.header.index)
          : null,
        lista_columnas_guardar: validations.finalColumns,
        exp_regular_encabezado: validations?.header?.exp_regular
          ? validations?.header.exp_regular
          : null,
        validacion_columnas_vacias:
          (validations?.header?.exp_regular && !validations?.header?.index) ||
          validations?.validations?.length === 0
            ? undefined
            : validations?.validations
                ?.filter((val: any) => val?.column !== "id")
                .map((val: any) => {
                  return {
                    columna: val?.column,
                    valor: val?.noEmptyValues.value,
                  };
                }),
        validacion_columnas_tipo_dato:
          (validations?.header?.exp_regular && !validations?.header?.index) ||
          validations?.validations?.length === 0
            ? undefined
            : validations?.validations?.map((val: any) => {
                return { tipo_dato: val.columnType, columna: val.column, formato: val.formato };
              })
      },
    });
  };

  /********************* LLAMADAS **************************/

  const { isLoading: isLoadingDeleteFile, callApi: deleteFile } = useApi(
    FONT_FILE_BY_ID(fontFile.id),
    "DELETE",
    texts.dataControl.deleteConfig.codes,
    undefined,
    () => {
      refetchFontFiles();
    },
    undefined,
    false
  );

  const onSuccesEditFile = async () => {
    await refetchFontFiles();
    onSuccessCreateFile();
  };

  const { isLoading: isLoadingEditFile, callApi: editFile } = useApi(
    FONT_FILE_BY_ID(fontFile.id),
    "PUT",
    texts.dataControl.editFile.codes,
    undefined,
    () => {
      onSuccesEditFile();
    },
    undefined,
    false
  );

  const handleChangeSelectedFolder = (location: string) => {
    if (location === "") {
      setLocation("/");
    } else {
      setLocation(location);
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };


  return (
    <Stack sx={{ width: "100%" }}>
      <SimpleBackdrop
        open={isLoadingDeleteFile}
        message={texts.dataControl.deleteConfig.loading}
      />
      <SimpleBackdrop
        open={isLoadingEditFile}
        message={texts.dataControl.editFile.loading}
      />

      {editingIndex === fontFile?.id ? (
        <>
          {activeStep === 0 && (
            <>
              <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                Nombre:
              </InputLabel>
              <Box sx={{ display: "flex", marginBottom: "10px" }}>
                <TextField
                  value={fileNameInput}
                  onChange={(e: any) => setFileNameInput(e.target.value)}
                  size="small"
                  sx={{ width: "30%" }}
                />
              </Box>
              <Box sx={{ marginBottom: "10px" }}>
                <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                  Responsable:
                </InputLabel>
                <TextField
                  value={responsible}
                  onChange={(event: any) => setResponsible(event.target.value)}
                  size="small"
                  sx={{ width: "30%" }}
                />
              </Box>
              <Box sx={{ marginBottom: "10px", width: "30%" }}>
                <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                  Frecuencia:
                </InputLabel>
                <Select
                  reference={frecuencyRef}
                  styles={optionalSelectStyles(frecuencySelectedOption)}
                  options={frecuencyOptions}
                  name="quantity"
                  onChange={frecuencyChangeHandler}
                  closeMenuOnSelect={true}
                  placeholder="Seleccionar frecuencia"
                  isClearable
                  defaultValue={frecuencySelectedOption}
                />
              </Box>
              <Box sx={{ marginBottom: "10px" }}>
                <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                  Periodos:
                </InputLabel>
                <CheckmarkSelect
                  dates={periods}
                  selectedPeriods={selectedPeriods}
                  setSelectedPeriods={setSelectedPeriods}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <InputLabel sx={{ ...form_label }}>Archivo crítico:</InputLabel>

                <Checkbox
                  checked={isCriticalFile}
                  onChange={() => {
                    setIsCriticalFile((prev) => !prev);
                  }}
                />
              </Box>
            </>
          )}
          {activeStep === 1 && (
            <>
              <InputLabel
                sx={{
                  ...form_label,
                  marginBottom: "5px",
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Ruta del directorio
                <Tooltip title="Ubicación donde se almacenará el archivo">
                  <InfoIcon
                    sx={{
                      color: "var(--blue)",
                      fontSize: "21px",
                      cursor: "pointer",
                      marginLeft: "1px",
                      marginBottom: "2px",
                    }}
                  />
                </Tooltip>
              </InputLabel>

              <ExploreFolders
                handleChangeSelectedFolder={handleChangeSelectedFolder}
                customFolder={location}
              />
            </>
          )}
          {activeStep === 2 && (
            <Validations
              setValidations={setValidations}
              validations={validations}
              originalValidations={fontFile.validacion}
            />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              title="Anterior"
              color="light-blue"
              type="button"
              onClick={handleBack}
              disabled={activeStep === 0}
            />

            <Button
              title="Siguiente"
              color="light-blue"
              type="button"
              onClick={handleNext}
              disabled={
                activeStep === 2 ||
                fileNameInput.length === 0 ||
                responsible.length === 0 ||
                selectedPeriods.length === 0 ||
                frecuencySelectedOption === undefined
              }
            />
          </Box>
          <Stack direction={"row"}>
            <Button
              title="Cancelar"
              color="grey"
              type="button"
              onClick={cancelEdit}
            />
            <Button
              title="Aceptar"
              color="blue"
              type="submit"
              onClick={handleAccept}
              disabled={
                validations.withoutFile && validations.header.exp_regular === ""
              }
            />
          </Stack>
        </>
      ) : (
        <Box
          sx={{
            margin: "5px 8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: "bold", marginRight: "5px" }}>
            {fontFile.nombre}
          </Typography>
          <IconButton
            onClick={() => handleEdit(fontFile?.id)}
            sx={{ padding: 0 }}
          >
            <EditIcon
              sx={{
                fontSize: "18px",
                marginLeft: "10px",
                color: "var(--light-grey)",
              }}
            />
          </IconButton>
          <Tooltip title={"Eliminar archivo"}>
            <DeleteIcon
              onClick={() => {
                toggleConfirmDeleteFileModal();
              }}
              sx={{
                cursor: "pointer",
                color: "var(--light-grey)",
              }}
            />
          </Tooltip>
        </Box>
      )}
      <ConfirmationModal
        open={openConfirmDeleteNameModal}
        handleClose={toggleConfirmDeleteFileModal}
        handleAccept={handleDelete}
        message={`eliminar el archivo fuente`}
        title={"Eliminar archivo fuente"}
      />
    </Stack>
  );
};

export default FontFile;
