import Stack from "@mui/material/Stack";
import CustomNoRowsOverlay from "./NoRows";
import CustomToolbar from "./CustomToolbar";
import { DataGridPro } from "@mui/x-data-grid-pro";

interface SimpleTableProps {
  rows: any;
  columns: any;
  toolbar?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  densitySelector?: boolean;
  exportButton?: boolean;
}

const SimpleTable = ({
  rows,
  columns,
  toolbar,
  columnsButton,
  filterButton,
  densitySelector,
  exportButton,
}: SimpleTableProps) => {
  return (
    <Stack style={{ height: "500px", width: "100%" }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        /*pagination
         pageSize={pageSize}
        rowsPerPageOptions={[6, 15, 20, 50]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} */
        components={{
          Toolbar: () => (
            <CustomToolbar
              toolbar={toolbar}
              columnsButton={columnsButton}
              filterButton={filterButton}
              densitySelector={densitySelector}
              exportButton={exportButton}
            />
          ),
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        disableColumnMenu={true}
        hideFooter={true}
        checkboxSelection={false}
        disableSelectionOnClick
        columnBuffer={10}  // Número de columnas a renderizar por fuera de la vista visible
        rowBuffer={10}
        disableColumnSelector
        columnVisibilityModel={{}} // Optimizar la visibilidad de columnas
        sx={{
          boxShadow: 3,
          fontSize: "12px",
          padding: "0 10px 10px 10px",
          "& .MuiPaper-root":{color: "black"},
          "& p":{
            textTransform: "initial !important"
          }
        }}
      />
    </Stack>
  );
};

export default SimpleTable;
