import { StyledAddButton } from "./styled";

interface AddButtonProps {
  onClick?: (() => void) | ((e: any) => void) | undefined;
  disabled?: boolean;
  align?: boolean;
  width?: "little" | "big"| "tiny"|"medium"
  customColor?: string
  margin?: string
}

const AddButton = ({ onClick, disabled, align, width, customColor, margin}: AddButtonProps) => {
  return (
    <StyledAddButton type="button" onClick={onClick} disabled={disabled} align={align} width={width} customColor={customColor} margin={margin}>
      +
    </StyledAddButton>
  );
};

export default AddButton;
