import {
  Avatar,
  Chip,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import Dialog from "../dialog/Dialog";
import { truncateText } from "../taskManager/utils";
import {BarChart} from "../charts/BarChart";
import useApi from "../../hooks/useApi";
import { FILE_INFO_URL } from "../../api/axios";
import { texts } from "../../texts";

interface FileTreaceabilityProps {
  name: string;
  type: string;
  open: boolean;
  handleClose: () => void;
  data?: any[];
  reporte?: any;
}

const FileTreaceability = ({
  name,
  type,
  data,
  open,
  handleClose,
  reporte,
}: FileTreaceabilityProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isIconClicked, setIsIconClicked] = useState(false);

  /*********** Menú desplegable *****************/

  const settings = [
    "Editar nombre",
    "Editar permisos",
    "Descargar",
    "Ver trazabilidad del archivo",
    "Eliminar archivo",
  ];

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSetting = (setting: string) => {
    switch (setting) {
      case "Ver trazabilidad del archivo":
        // navigate("/account");
        break;
      case "Dashboard":
        // navigate("/dashboard");
        break;
      default:
        break;
    }
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(reporte?.ubicacion_reporte);
      setIsIconClicked(true);
      setTimeout(() => setIsIconClicked(false), 2000);
    } catch (error) {
      console.error("Error al copiar al portapapeles:", error);
    }
  };

  const getTooltip = (value: any) => {
    return `<div><b>${value}${
      value < 2 ? " error de este tipo" : " errores de este tipo"
    } </b></div>`;
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      maxWidth="xl"
      title={name}
      draggable={true}
      sx={{ zIndex: "100" }}
    >
      <Stack sx={{ width: "1100px" }}>
        <Stack direction="row" sx={{ gap: 2, marginBottom: "15px" }}>
          <Typography
            sx={{
              color: "var(--blue)",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Trazabilidad del archivo
          </Typography>
          <MoreHorizIcon
            sx={{
              cursor: "pointer",
              color: "var(--blue)",
              marginLeft: "auto",
              marginRight: "5px",
              fontSize: "30px",
            }}
            onClick={(e: any) => handleOpenUserMenu(e)}
          />
        </Stack>

        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "18px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Pipelines utilizados
        </Typography>
        <Stack direction="row" sx={{ gap: 2, marginBottom: "15px" }}>
          <Tooltip
            title={"Abrir pipeline"}
            sx={{
              pointerEvents: "fill",
            }}
          >
            <Chip
              label="Pipeline 1"
              variant="outlined"
              sx={{
                width: "190px",
                color: "var(--blue)",
                fontSize: "15px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
          <Tooltip
            title={"Abrir pipeline"}
            sx={{
              pointerEvents: "fill",
            }}
          >
            <Chip
              label="Pipeline 2"
              variant="outlined"
              sx={{
                width: "190px",
                color: "var(--blue)",
                fontSize: "15px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </Stack>
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "18px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Esquemas utilizados
        </Typography>
        <Stack direction="row" sx={{ gap: 2, marginBottom: "15px" }}>
          <Tooltip
            title={"Abrir esquema"}
            sx={{
              pointerEvents: "fill",
            }}
          >
            <Chip
              label="Esquema ventas"
              variant="outlined"
              sx={{
                width: "190px",
                color: "var(--blue)",
                fontSize: "15px",
                cursor: "pointer",
              }}
            />
          </Tooltip>

          <Tooltip
            title={"Abrir esquema"}
            sx={{
              pointerEvents: "fill",
            }}
          >
            <Chip
              label="Esquema México"
              variant="outlined"
              sx={{
                width: "190px",
                color: "var(--blue)",
                fontSize: "15px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </Stack>
        <Stack sx={{ marginBottom: "15px" }}>
          <Typography
            sx={{
              color: "var(--blue)",
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Flujos
          </Typography>
          <Tooltip
            title={"Abrir flujo"}
            sx={{
              pointerEvents: "fill",
            }}
          >
            <Chip
              label="Consolidado México"
              variant="outlined"
              sx={{
                width: "190px",
                color: "var(--blue)",
                fontSize: "15px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </Stack>
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "16px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Archivos utilizados en el flujo
        </Typography>
        <Stack direction="row" sx={{ gap: 2, marginBottom: "30px" }}>
          <Chip
            avatar={
              <Avatar sx={{ bgcolor: "#cd7f32", color: "white !important" }}>
                B
              </Avatar>
            }
            label="VENTAS-MEXICO.csv"
            variant="outlined"
            sx={{
              width: "220px",
              color: "#cd7f32",
              fontSize: "15px",
              cursor: "pointer",
            }}
          />
          <Chip
            avatar={<Avatar>P</Avatar>}
            label="Consolidado-mexico.csv"
            variant="outlined"
            sx={{
              width: "220px",
              color: "var(--blue)",
              fontSize: "15px",
              cursor: "pointer",
            }}
          />
        </Stack>
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "18px",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          Reporte de validaciones del esquema {reporte?.esquema}
        </Typography>
        <Stack
          sx={{
            minWidth: "1000px",
            minHeight: "100px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "var()",
          }}
        >
          <Stack
            direction="row"
            sx={{ width: "100%", justifyContent: "center" }}
          >
            <Stack sx={{ marginLeft: "20px" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: "var(--dark-blue)",
                  marginBottom: "15px",
                }}
              >
                Archivos ejecutados con error: {reporte?.archivos_con_error}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: "var(--dark-blue)",
                  marginBottom: "15px",
                }}
              >
                Hojas con error: {reporte?.hojas_con_error}
              </Typography>
              <Typography
                sx={{
                  color: "var(--dark-blue)",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                Ubicación del reporte generado:
              </Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  width: "100%",
                  gap: "5px",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--light-blue)",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  {truncateText(reporte?.ubicacion_reporte, 48)}
                </Typography>
                <Tooltip
                  title={isIconClicked ? "Copiado!" : "Copiar"}
                  placement={"right"}
                  sx={{
                    pointerEvents: "fill",
                  }}
                >
                  {isIconClicked ? (
                    <DoneOutlineIcon
                      sx={{
                        cursor: "pointer",
                      }}
                      color={"primary"}
                    />
                  ) : (
                    <ContentCopyIcon
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={handleCopyClick}
                      color={"primary"}
                    />
                  )}
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <BarChart results={reporte?.grafico} tooltip={getTooltip} />
        <Menu
          sx={{ mt: "45px", zIndex: "200" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting: string) => (
            <MenuItem key={setting} onClick={() => handleSetting(setting)}>
              <Typography
                textAlign="center"
                sx={{
                  color: "var(--greeny)",
                }}
              >
                {setting}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Stack>
    </Dialog>
  );
};

export default FileTreaceability;
