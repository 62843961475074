import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import SearchIcon from "@mui/icons-material/Search";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { nanoid } from "nanoid";

export type Period = {
  id: number;
  fecha: string;
  prorrateado: boolean;
  preprorrateado: boolean;
  exportable: boolean;
  posee_gastos: boolean;
  habilitado: boolean;
  empresa: number;
  estado?: string;
};
export type Periods = Period[];

export const periods = [
  { id: 1, fecha: "01-2021" },
  { id: 2, fecha: "02-2021" },
  { id: 3, fecha: "03-2021" },
  { id: 4, fecha: "04-2021" },
  { id: 5, fecha: "05-2021" },
  { id: 6, fecha: "06-2021" },
  { id: 7, fecha: "07-2021" },
  { id: 8, fecha: "08-2021" },
  { id: 9, fecha: "09-2021" },
  { id: 10, fecha: "10-2021" },
  { id: 11, fecha: "11-2021" },
  { id: 12, fecha: "12-2021" },
  { id: 13, fecha: "01-2022" },
  { id: 14, fecha: "02-2022" },
  { id: 15, fecha: "03-2022" },
  { id: 16, fecha: "04-2022" },
  { id: 17, fecha: "05-2022" },
  { id: 18, fecha: "06-2022" },
  { id: 19, fecha: "07-2022" },
  { id: 20, fecha: "08-2022" },
  { id: 21, fecha: "09-2022" },
  { id: 22, fecha: "10-2022" },
  { id: 23, fecha: "11-2022" },
  { id: 24, fecha: "12-2022" },
  { id: 25, fecha: "01-2023" },
  { id: 26, fecha: "02-2023" },
  { id: 27, fecha: "03-2023" },
  { id: 28, fecha: "04-2023" },
  { id: 29, fecha: "05-2023" },
  { id: 30, fecha: "06-2023" },
  { id: 31, fecha: "07-2023" },
  { id: 32, fecha: "08-2023" },
  { id: 33, fecha: "09-2023" },
  { id: 34, fecha: "10-2023" },
  { id: 35, fecha: "11-2023" },
  { id: 36, fecha: "12-2023" },
  { id: 37, fecha: "01-2024" },
  { id: 38, fecha: "02-2024" },
  { id: 39, fecha: "03-2024" },
  { id: 40, fecha: "04-2024" },
  { id: 41, fecha: "05-2024" },
  { id: 42, fecha: "06-2024" },
  { id: 43, fecha: "07-2024" },
  { id: 44, fecha: "08-2024" },
  { id: 45, fecha: "09-2024" },
  { id: 46, fecha: "10-2024" },
  { id: 47, fecha: "11-2024" },
  { id: 48, fecha: "12-2024" },
  { id: 49, fecha: "01-2025" },
  { id: 50, fecha: "02-2025" },
  { id: 51, fecha: "03-2025" },
  { id: 52, fecha: "04-2025" },
  { id: 53, fecha: "05-2025" },
  { id: 54, fecha: "06-2025" },
  { id: 55, fecha: "07-2025" },
  { id: 56, fecha: "08-2025" },
  { id: 57, fecha: "09-2025" },
  { id: 58, fecha: "10-2025" },
  { id: 59, fecha: "11-2025" },
  { id: 60, fecha: "12-2025" },
  { id: 61, fecha: "01-2026" },
  { id: 62, fecha: "02-2026" },
  { id: 63, fecha: "03-2026" },
  { id: 64, fecha: "04-2026" },
  { id: 65, fecha: "05-2026" },
  { id: 66, fecha: "06-2026" },
  { id: 67, fecha: "07-2026" },
  { id: 68, fecha: "08-2026" },
  { id: 69, fecha: "09-2026" },
  { id: 70, fecha: "10-2026" },
  { id: 71, fecha: "11-2026" },
  { id: 72, fecha: "12-2026" },
  { id: 73, fecha: "01-2027" },
  { id: 74, fecha: "02-2027" },
  { id: 75, fecha: "03-2027" },
  { id: 76, fecha: "04-2027" },
  { id: 77, fecha: "05-2027" },
  { id: 78, fecha: "06-2027" },
  { id: 79, fecha: "07-2027" },
  { id: 80, fecha: "08-2027" },
  { id: 81, fecha: "09-2027" },
  { id: 82, fecha: "10-2027" },
  { id: 83, fecha: "11-2027" },
  { id: 84, fecha: "12-2027" },
  { id: 85, fecha: "01-2028" },
  { id: 86, fecha: "02-2028" },
  { id: 87, fecha: "03-2028" },
  { id: 88, fecha: "04-2028" },
  { id: 89, fecha: "05-2028" },
  { id: 90, fecha: "06-2028" },
  { id: 91, fecha: "07-2028" },
  { id: 92, fecha: "08-2028" },
  { id: 93, fecha: "09-2028" },
  { id: 94, fecha: "10-2028" },
  { id: 95, fecha: "11-2028" },
  { id: 96, fecha: "12-2028" },
  { id: 97, fecha: "01-2029" },
  { id: 98, fecha: "02-2029" },
  { id: 99, fecha: "03-2029" },
  { id: 100, fecha: "04-2029" },
  { id: 101, fecha: "05-2029" },
  { id: 102, fecha: "06-2029" },
  { id: 103, fecha: "07-2029" },
  { id: 104, fecha: "08-2029" },
  { id: 105, fecha: "09-2029" },
  { id: 106, fecha: "10-2029" },
  { id: 107, fecha: "11-2029" },
  { id: 108, fecha: "12-2029" },
  { id: 109, fecha: "01-2030" },
  { id: 110, fecha: "02-2030" },
  { id: 111, fecha: "03-2030" },
  { id: 112, fecha: "04-2030" },
  { id: 113, fecha: "05-2030" },
  { id: 114, fecha: "06-2030" },
  { id: 115, fecha: "07-2030" },
  { id: 116, fecha: "08-2030" },
  { id: 117, fecha: "09-2030" },
  { id: 118, fecha: "10-2030" },
  { id: 119, fecha: "11-2030" },
  { id: 120, fecha: "12-2030" },
];

export const frecuencyOptions = [
  { value: "transaccional", label: "Transaccional" },
  { value: "fijo", label: "Fijo" },
  { value: "base_maestro", label: "Base maestro" },
];

export const stateOptions = [
  { value: "no_cargado", label: "No cargado" },
  { value: "cargado", label: "Cargado" },
  { value: "con_errores", label: "Con errores" },
];

export const quantityOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
];

export const steps = {
  steps: ["Configuración base", "Ruta del archivo", "Validaciones"],
};

type FilterType =
  | "ID"
  | "FECHA"
  | "PRORRATEADO"
  | "PREPRORRATEADO"
  | "EXPORTABLE"
  | "POSEE_GASTOS"
  | "HABILITADO";

export const filterPeriods = (
  periods: Periods,
  filter_type: FilterType,
  condition: string | number | boolean
) => {
  const filteredPeriods = periods.filter((period: Period) => {
    switch (filter_type) {
      case "ID":
        return period.id === condition;
      case "FECHA":
        return period.fecha === condition;
      case "PRORRATEADO":
        return period.prorrateado === condition;
      case "PREPRORRATEADO":
        return period.preprorrateado === condition;
      case "EXPORTABLE":
        return period.exportable === condition;
      case "POSEE_GASTOS":
        return period.posee_gastos === condition;
      case "HABILITADO":
        return period.habilitado === condition;
      default:
        return false;
    }
  });
  return filteredPeriods;
};

//Función para extraer el año de un período
export const getYear = (period: Period) => {
  return period?.fecha?.split("-")[1];
};

//Función para detectar si un año está incluido dentro de un array de años
export const isYearInArray = (year: string, years: string[]) => {
  return years.includes(year);
};

//Función que recibe como parametro un año en string, y un array de Period y devuelve los ids de los Period que contienen ese año
export const getIdPeriodsByYear: (
  year: string,
  periods: Periods
) => number[] = (year: string, periods: Periods) => {
  return periods
    .filter((period: Period) => {
      return getYear(period) === year;
    })
    .map((period: Period) => period.id);
};

//Función que recibe dos parámetros para ordenar, el cual ordena las fechas de menor a mayor
export const sortByDate = (a: any, b: any) => {
  const [mesA, añoA] = a && a.fecha ? a.fecha.split("-") : ["", ""];
  const [mesB, añoB] = b && b.fecha ? b.fecha.split("-") : ["", ""];

  // Compara primero por año y luego por mes
  if (añoA < añoB) {
    return -1;
  }
  if (añoA > añoB) {
    return 1;
  }
  // Si los años son iguales, compara por mes
  if (mesA < mesB) {
    return -1;
  }
  if (mesA > mesB) {
    return 1;
  }
  return 0;
};

//Función que recibe como parametro un array de fechas, y devuelve un array con solo aquellos items con gastos.
export const filterDatesWithExpense = (array: any) => {
  return array.filter((item: any) => item.posee_gastos);
};

//Función que recibe un array de Periods y devuelve un array con los year sin repetir
export const getTotalPeriods = (periods: Periods | undefined) => {
  const years: string[] = [];
  periods?.forEach((period: Period) => {
    const year = getYear(period);
    if (!isYearInArray(year, years)) {
      years.push(year);
    }
  });
  return years;
};

//Función que elimina números repetidos de un array
export const removeDuplicates = (array: number[]) => {
  return array.filter((a, b) => array.indexOf(a) === b);
};

//función para eliminar strings repetidos en un array
export const removeDuplicatesString = (array: string[]) => {
  return array.filter((a, b) => array.indexOf(a) === b);
};

//Función que recibe un array de id de Periods y devuelve el array de objetos Period completo
export const getPeriodsById = (periods: Periods, ids: number[]) => {
  return periods.filter((period: Period) => {
    return ids.includes(period.id);
  });
};

//obtiene el array getIdPeriodsByYear y evalúa si está contenido en otro array
export const isIdPeriodsByYearInArray: (
  year: string,
  dates: Periods,
  selectedYears: number[]
) => boolean = (year: string, dates: Periods, selectedYears: number[]) => {
  const idPeriodsByYear = getIdPeriodsByYear(year, dates);
  return idPeriodsByYear.every((id) => selectedYears.includes(id));
};

//Recorrer un array de elementos y crear un array que repita cada elemento dos veces
export const repeatArray = (array: string[]) => {
  const repeatedArray: string[] = [];
  array.forEach((element) => {
    repeatedArray.push(element);
    repeatedArray.push(element);
  });
  return repeatedArray;
};

//Función que recibe como parametro un año en string, y un array de Period y devuelve los Period que contienen ese año
export const getPeriodsByYear: (year: string, periods: Periods) => Period[] = (
  year: string,
  periods: Periods
) => {
  return periods.filter((period: Period) => {
    return period.fecha.split("-")[1] === year;
  });
};

//Función que de un array de Periodos devuelve el array de los ids de esos Periodos
export const getPeriodsId = (periods: Periods) => {
  return periods.map((period: Period) => period.id);
};

export const getPeriodById = (id: number) => {
  const period = periods.find((period) => period.id === id);
  return period ? period.fecha : null;
};

export const getIdPeriod = (fecha: string) => {
  const period = periods.find((period) => period.fecha === fecha);
  return period ? period.id : null;
};

export const formatFileOptions = (data: any[]) => {
  return data?.map((file: any) => {
    return {
      value: file.id,
      label: file.nombre,
      ...file,
    };
  });
};

export const orderTableKeys = (keys: any) => {
  const specialFields = [
    "TEMPLATE",
    "RESPONSABLE",
    "ARCHIVOS_FUENTES",
    "RUTA_DIRECTORIO",
    "CRITICO",
  ];
  const specialItems = keys?.filter((item: any) =>
    specialFields.includes(item.field)
  );
  const monthItems = keys?.filter(
    (item: any) => !specialFields.includes(item.field)
  );

  // Unir los arrays, colocando primero los elementos especiales
  return [...specialItems, ...monthItems];
};

/**
 * @name transformDataTaskManager
 * @description Función que transforma los datos de la tabla. Debe recibir la data con las key como columnas, o bien se puede enviar un array con las columnas ya predefinidas.
 * @param data
 */

export const transformDataSimpleTable = (
  data: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  numeration?: boolean,
  rawData?: any,
  handleClickOnEdit?: (row: number, period: string) => void,
  handleClickOnLocation?: (row: number, location: string) => void,
  handleGroupByTemplate?: (template: string) => void,
  handleGroupByPeriod?: (period: string) => void,
  handleClickOnDetail?: (row: number, period: string) => void
) => {
  const tableColumns = optionalColumns
    ? optionalColumns
    : data && data.length > 0
    ? Object.keys(data[0])
    : [];

  // Esta función utiliza la raw data para luego setearle el type a la columna (decimal, texto, etc)
  const getColumnType = (column: string) => {
    const key = Object.keys(rawData[0]).find((c: any) => {
      return c.substring(0, c.lastIndexOf("(")) === column;
    });
    return key && key.substring(key.lastIndexOf("(") + 1, key.lastIndexOf(")"));
  };

  // Función que convierte los valores "no_cargado", "cargado" y "con_errores"
  // Además aplica el color correspondiente y el icono
  const getStatusLabel = (value: string) => {
    switch (value) {
      case "no_cargado":
        return {
          color: "var(--red)",
          icon: <CancelIcon sx={{ color: "var(--red)" }} />,
          tooltip: "No cargado",
        };
      case "cargado":
        return {
          color: "green",
          icon: <CheckCircleIcon sx={{ color: "green" }} />,
          tooltip: "Cargado",
        };
      case "con_errores":
        return {
          color: "orange",
          icon: <WarningIcon sx={{ color: "orange" }} />,
          tooltip: "Con errores",
        };
      case "validando":
        return {
          color: "blue",
          icon: <HourglassBottomIcon sx={{ color: "var(--blue)" }} />,
          tooltip: "Validando",
        };
      default:
        return { label: value, color: "black", icon: null }; // color por defecto si no es ninguno de los tres
    }
  };

  // Función para verificar si una columna tiene formato "mm-yyyy"
  const isMonthYearColumn = (columnName: string) => {
    const regex = /^\d{2}-\d{4}$/; // Verifica el formato "mm-yyyy"
    return regex.test(columnName);
  };

  const arrayColumns = tableColumns
    ?.filter((item: any) => item !== "id" && item !== "archivo_fuente_id")
    ?.map((columna: string) => ({
      field: columna,
      type: rawData ? getColumnType(columna) : undefined,
      headerClassName: "es_header_column",
      headerName: columna,
      description: columna,
      minWidth: 100,
      flex: 1,
      renderHeader: (params: any) => {
        const isMonthYear = isMonthYearColumn(columna);
        return (
          <Stack direction="row" alignItems="center" justifyContent="center">
            {isMonthYear && handleGroupByPeriod && (
              <Tooltip title="Agrupar por período">
                <IconButton
                  color="primary"
                  onClick={() => handleGroupByPeriod(columna)}
                  sx={{ marginLeft: 1 }}
                >
                  <GroupWorkIcon sx={{ color: "white", fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            )}
            <Typography
              sx={{
                color: "white",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "14px",
                lineHeight: 1,
              }}
            >
              {columna.substring(0, columna.lastIndexOf("("))}
              <br />
              {columna.substring(columna.lastIndexOf("("))}
            </Typography>
            {/* Si es columna de tipo "mm-yyyy", agregamos el ícono de agrupar */}
          </Stack>
        );
      },
      renderCell: (params: any) => {
        const { label, color, icon, tooltip } = getStatusLabel(params.value);
        return (
          <Tooltip title={tooltip}>
            <Stack direction="row" alignItems="center">
              {icon}
              <Typography
                sx={{
                  color: color,
                  fontWeight: "bold",
                  marginLeft: icon ? "8px" : "0", // Espacio entre icono y texto
                }}
              >
                {label}
              </Typography>
              {/* Aquí agregamos el ícono de editar */}
              {params.field !== "TEMPLATE" &&
              params.field !== "RESPONSABLE" &&
              params.field !== "ARCHIVOS_FUENTES" &&
              params.field !== "CRITICO" &&
              label !== "-" &&
              !params.field?.startsWith("20") &&
              params.field !== "total" ? (
                <IconButton
                  color="primary"
                  onClick={() =>
                    params.field === "RUTA_DIRECTORIO"
                      ? handleClickOnLocation &&
                        handleClickOnLocation(params.row.id, label as string)
                      : handleClickOnEdit &&
                        handleClickOnEdit(params.row.id, params.field)
                  }
                  sx={{ marginLeft: "0px" }}
                >
                  <EditIcon sx={{ fontSize: "16px" }} />
                </IconButton>
              ) : null}
              {(params.value === "cargado" ||
                params.value === "con_errores") && (
                <Tooltip title="Ver detalles">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      handleClickOnDetail &&
                      handleClickOnDetail(params.row.id, params.field)
                    }
                    sx={{ marginLeft: "0px" }}
                  >
                    <SearchIcon sx={{ fontSize: "20px" }} />
                  </IconButton>
                </Tooltip>
              )}
              {params.field === "TEMPLATE" ? (
                <Tooltip title="Agrupar por template">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      handleGroupByTemplate &&
                      handleGroupByTemplate(label as string)
                    }
                    sx={{ marginLeft: "0px" }}
                  >
                    <GroupWorkIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                </Tooltip>
              ) : null}
              {params.field === "CRITICO" ? (
                <Typography>
                  {params.value === true
                    ? "Sí"
                    : params.value === "-"
                    ? ""
                    : "No"}
                </Typography>
              ) : null}
            </Stack>
          </Tooltip>
        );
      },
    }));

  const arrayRows =
    data && data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          for (const key in fila) {
            obj[key] = fila[key];
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};

interface Row {
  id: number | string;
  TEMPLATE: string;
  RESPONSABLE: string;
  ARCHIVOS_FUENTES: string;
  [key: string]: string | number;
}

interface GroupedRows {
  [template: string]: Row[];
}

export function handleGroupByTemplate(
  template: string,
  rows: any,
  setRows: any
): void {
  // Filtrar las filas que coincidan con el TEMPLATE proporcionado
  const filteredRows = rows.filter((row: any) => row.TEMPLATE === template);

  // Lista para las filas actualizadas (solo las que coinciden con el template)
  const updatedRows: Row[] = [];

  // Si hay filas que coinciden con el template
  if (filteredRows.length > 0) {
    // Agrupar las filas filtradas por el campo TEMPLATE
    const groupedByTemplate: GroupedRows = filteredRows.reduce(
      (acc: any, row: any) => {
        const template = row.TEMPLATE;
        if (!acc[template]) {
          acc[template] = [];
        }
        acc[template].push(row);
        return acc;
      },
      {} as GroupedRows
    );

    // Iterar sobre cada grupo (agrupado por TEMPLATE)
    for (const [template, group] of Object.entries(groupedByTemplate)) {
      // Inicializar la fila resultante (la fila condensada)
      const resultRow: Row = {
        id: nanoid(),
        TEMPLATE: template,
        RESPONSABLE: "-",
        ARCHIVOS_FUENTES: "-",
        RUTA_DIRECTORIO: "-",
        CRITICO: "-",
      };

      // Variable para determinar si se debe marcar como "no_cargado"
      let hasCriticoTrueAndNoCargado = false;

      // Obtener todos los periodos (mes-año) de las filas en el grupo
      const periods = new Set<string>();
      group.forEach((row) => {
        Object.keys(row).forEach((key) => {
          if (
            key !== "TEMPLATE" &&
            key !== "RESPONSABLE" &&
            key !== "ARCHIVOS_FUENTES" &&
            key !== "RUTA_DIRECTORIO" &&
            key !== "CRITICO"
          ) {
            periods.add(key); // Agregar todos los periodos (MM-YYYY)
          }
        });
      });

      // Iterar sobre cada periodo encontrado
      periods.forEach((period) => {
        // Extraer los valores de ese periodo para cada fila
        const values = group.map((row) => row[period] || "no_cargado");

        // Verificar si alguna fila tiene critico: true y algún valor "no_cargado"
        if (group.some((row) => row.CRITICO && row[period] === "no_cargado")) {
          hasCriticoTrueAndNoCargado = true;
        }

        // Determinar el valor del periodo según las reglas
        if (values.every((value) => value === "cargado")) {
          resultRow[period] = "cargado";
        } else if (
          values.some((value) => value === "con_errores") ||
          values.some((value) => value === "cargado") ||
          values.some((value) => value === "validando")
        ) {
          resultRow[period] = "con_errores";
        } else {
          if (period !== "id") {
            resultRow[period] = "no_cargado";
          }
        }
      });

      // Si tiene critico: true y hay algún "no_cargado", marcar la fila como "no_cargado"
      if (hasCriticoTrueAndNoCargado) {
        Object.keys(resultRow).forEach((key) => {
          if (
            key !== "TEMPLATE" &&
            key !== "id" &&
            key !== "CRITICO" &&
            key !== "RESPONSABLE" &&
            key !== "ARCHIVOS_FUENTES" &&
            key !== "RUTA_DIRECTORIO" &&
            resultRow[key] !== "cargado"
          ) {
            resultRow[key] = "no_cargado";
          }
        });
      }

      // Agregar la fila agrupada y actualizada al resultado (solo una fila por cada TEMPLATE)
      updatedRows.push(resultRow);
    }
  }

  // Crear un nuevo array combinando las filas no modificadas y las actualizadas
  const finalRows = rows
    .filter((row: any) => row.TEMPLATE !== template) // Filtramos las filas que no coinciden con el template
    .concat(updatedRows); // Añadimos solo la fila condensada para ese template

  setRows(finalRows);
}

export function handleGroupByYear(
  periodo: string,
  rows: any,
  setRows: any,
  setColumns: any,
  handleClickOnEdit: any,
  handleGroupTemplate: any,
  handleGroupByPeriod: any
): void {
  // Extraer el año del periodo (por ejemplo, '02-2021' -> '2021')
  const year = periodo.split("-")[1]; // '2021'

  const updatedRows: any[] = [];

  rows.forEach((row: any) => {
    // Nueva fila para la fila condensada (con una columna por año)
    const resultRow: any = {
      id: row.id,
      TEMPLATE: row.TEMPLATE,
      RESPONSABLE: row.RESPONSABLE,
      ARCHIVOS_FUENTES: row.ARCHIVOS_FUENTES,
    };

    // Filtrar las claves que corresponden al año
    const valuesForYear = Object.keys(row)
      .filter((key) => key.split("-")[1] === year) // Filtramos solo las claves correspondientes al año
      .map((key) => row[key]); // Obtenemos los valores de esas claves

    // Verificamos el estado de los valores para ese año
    const allCargado = valuesForYear.every((value) => value === "cargado");
    const allNoCargado = valuesForYear.every((value) => value === "no_cargado");
    const allDash = valuesForYear.every((value) => value === "-");
    const allLoading = valuesForYear.every((value) => value === "validando");
    const anyConErrores = valuesForYear.some(
      (value) => value === "con_errores"
    );
    if (
      row.CRITICO === true &&
      valuesForYear.some((value) => value === "no_cargado")
    ) {
      resultRow[year] = "no_cargado"; // Marcamos como "no_cargado"
    } else {
      // Asignamos el estado del año según los resultados
      if (allDash) {
        resultRow[year] = "-"; // Si todos los valores son "-", asignamos "-"
      } else if (allNoCargado) {
        resultRow[year] = "no_cargado"; // Si todos son "no_cargado", asignamos "no_cargado"
      } else if (allCargado) {
        resultRow[year] = "cargado"; // Si todos son "cargado", asignamos "cargado"
      } else if (allLoading) {
        resultRow[year] = "validando";
      } else if (anyConErrores) {
        resultRow[year] = "con_errores"; // Si hay valores "con_errores", asignamos "con_errores"
      } else {
        resultRow[year] = "con_errores"; // Si hay valores mixtos, asignamos "con_errores"
      }
    }

    // Agregar las claves que no pertenecen al año al resultado
    Object.keys(row).forEach((key) => {
      const [month, rowYear] = key.split("-");

      // Solo agregar las claves que no son del año agrupado
      if (rowYear !== year) {
        resultRow[key] = row[key]; // Mantenemos los valores de otras columnas (que no son del año)
      }
    });

    // Agregar la fila procesada a las filas actualizadas
    updatedRows.push(resultRow);
  });

  // Transformación final: Eliminar las claves correspondientes al año agrupado
  const result = updatedRows.map((item: any) => {
    const newItem = { ...item }; // Hacer una copia del objeto para no modificar el original
    Object.keys(newItem).forEach((key) => {
      // Verificar si la clave contiene el año
      if (key.includes(`-${year}`)) {
        delete newItem[key]; // Eliminar las columnas mes-año
      }
    });
    return newItem; // Devolver el objeto modificado
  });


  // Actualizar las filas con el nuevo agrupamiento
  setRows(result);

  // Eliminar las columnas de mes-año correspondientes a ese año
  const filteredColumns = Object.keys(updatedRows[0]).filter((key) => {
    const [month, rowYear] = key.split("-");
    return rowYear !== year; // Excluir columnas que correspondan al año agrupado
  });

  // Actualizar las columnas después de la transformación
  setColumns(
    orderTableKeys(
      transformDataSimpleTable(
        updatedRows,
        undefined,
        undefined,
        undefined,
        updatedRows,
        handleClickOnEdit,
        handleGroupTemplate,
        handleGroupByPeriod
      ).columns.filter((col: any) => filteredColumns.includes(col.field)) // Filtrar las columnas
    )
  );
}

export function handleGroupTotal(
  rows: any,
  setRows: any,
  setColumns: any,
  handleClickOnEdit: any,
  handleGroupTemplate: any,
  handleGroupByPeriod: any
): void {
  const updatedRows: any[] = [];

  rows.forEach((row: any) => {
    // Nueva fila para la fila condensada
    const resultRow: any = {
      id: row.id,
      TEMPLATE: row.TEMPLATE,
      RESPONSABLE: row.RESPONSABLE,
      ARCHIVOS_FUENTES: row.ARCHIVOS_FUENTES,
    };

    // Filtramos las claves que corresponden a los periodos
    const valuesForPeriods = Object.keys(row)
      .filter((key) => key.includes("-")) // Filtramos solo las claves que son de tipo mes-año
      .map((key) => row[key]); // Obtenemos los valores de esos periodos

    // Verificamos el estado de los valores para todos los periodos
    const allCargado = valuesForPeriods.every((value) => value === "cargado");
    const allNoCargado = valuesForPeriods.every(
      (value) => value === "no_cargado"
    );
    const allDash = valuesForPeriods.every((value) => value === "-");
    const anyConErrores = valuesForPeriods.some(
      (value) => value === "con_errores"
    );
    const allLoading = valuesForPeriods.every((value) => value === "validando");

    // Si la fila tiene CRITICO como true y al menos un periodo está "no_cargado", marcar como "no_cargado"
    if (row.CRITICO === true && valuesForPeriods.some((value) => value === "no_cargado")) {
      resultRow["total"] = "no_cargado"; // Marcamos como "no_cargado"
    } else {
      // Asignamos el estado total según los resultados
      if (allDash) {
        resultRow["total"] = "-"; // Si todos los valores son "-", asignamos "-"
      } else if (allNoCargado) {
        resultRow["total"] = "no_cargado"; // Si todos son "no_cargado", asignamos "no_cargado"
      } else if (allCargado) {
        resultRow["total"] = "cargado"; // Si todos son "cargado", asignamos "cargado"
      } else if (allLoading) {
        resultRow["total"] = "validando"; // Si todos son "validando", asignamos "validando"
      } else if (anyConErrores) {
        resultRow["total"] = "con_errores"; // Si hay valores "con_errores", asignamos "con_errores"
      } else {
        resultRow["total"] = "con_errores"; // Si hay valores mixtos, asignamos "con_errores"
      }
    }

    // Agregar las claves que no son de tipo mes-año (sin modificar)
    Object.keys(row).forEach((key) => {
      if (!key.includes("-")) {
        // Si la clave no corresponde a un periodo mes-año
        resultRow[key] = row[key]; // Mantenemos los valores de otras columnas (que no son de periodos)
      }
    });

    // Agregar la fila procesada a las filas actualizadas
    updatedRows.push(resultRow);
  });

  // Transformación final: Eliminar las columnas de los periodos (mes-año)
  const result = updatedRows.map((item: any) => {
    const newItem = { ...item }; // Hacer una copia del objeto para no modificar el original
    Object.keys(newItem).forEach((key) => {
      // Verificar si la clave es de tipo mes-año y eliminarla
      if (key.includes("-")) {
        delete newItem[key]; // Eliminar las columnas mes-año
      }
    });
    return newItem; // Devolver el objeto modificado
  });

  // Actualizar las filas con el nuevo agrupamiento
  setRows(result);

  // Eliminar las columnas de mes-año correspondientes a los periodos
  const filteredColumns = Object.keys(updatedRows[0]).filter(
    (key) => !key.includes("-")
  ); // Filtrar las columnas que son mes-año

  // Actualizar las columnas después de la transformación
  setColumns(
    orderTableKeys(
      transformDataSimpleTable(
        updatedRows,
        undefined,
        undefined,
        undefined,
        updatedRows,
        handleClickOnEdit,
        handleGroupTemplate,
        handleGroupByPeriod
      ).columns.filter((col: any) => filteredColumns.includes(col.field)) // Filtrar las columnas
    )
  );
}


export function deleteSlash(str: string) {
  if (str.endsWith("/")) {
    return str.slice(0, -1);
  }
  return str; // Si no tiene barra, retorna la cadena original
}

export function filterPeridos(calculated: any[], data: any[]) {
  return data.map(item => {
      // Crear una copia del objeto actual para no modificar el original
      const nuevoItem = { ...item };
      
      // Eliminar las claves que coincidan con los períodos calculados
      calculated.forEach(periodo => {
          delete nuevoItem[periodo];
      });
      
      return nuevoItem;
  });
}

export function getValidationType(type: string) {
  if (type === "string") {
    return "Texto";
  } else if (type === "float") {
    return "Decimal";
  } else if (type === "numerico") {
    return "Númerica";
  } else if (type === "date") {
    return "Fecha";
  } else {
    return "Tipo no reconocido"; 
  }
}

export function deleteId(array: any[]) {
  return array?.map(({ id, ...resto }) => resto);
}