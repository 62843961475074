import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

export const transformDataSimpleTable: (
  data: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  numeration?: boolean,
  rawData?: any,
  buttonsActions?: any
) => {
  columns: GridColDef<any, any, any>[];
  rows: any;
} = (
  data: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  numeration?: boolean,
  rawData?: any,
  buttonsActions?: any
) => {
  const tableColumns = optionalColumns
    ? optionalColumns
    : data && data.length > 0
    ? Object.keys(data[0])
    : [];

  const idColumn = tableColumns?.find((columna: string) => columna === "id");

  //Esta función utiliza la raw data para luego setearle el type a la columna (decimal, texto, etc)
  const getColumnType = (column: string) => {
    const key = Object.keys(rawData[0]).find((c: any) => {
      return c.substring(0, c.lastIndexOf("(")) === column;
    });
    return key && key.substring(key.lastIndexOf("(") + 1, key.lastIndexOf(")"));
  };

  if (numeration === true) {
    const arrayColumns = [
      idColumn && {
        field: "Nº",
        headerClassName: "es_header_column",
        headerName: "Nº",
        description: "Nº",
        mixWidth: 50,
        maxWidth: 50,
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => (
          <strong
            style={{
              color: "white",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            {transformColumnNames ? transformColumnNames("Nº") : "Nº"}
          </strong>
        ),
        renderCell: (params: any) => (
          <Stack direction="row" alignItems="center">
            {params.value}
          </Stack>
        ),
      },
      ...tableColumns
        .filter((columna: string) => columna !== "id")
        .map((columna: string) => ({
          field: columna,
          headerClassName: "es_header_column",
          headerName: columna,
          description: columna,
          minWidth: 100,
          flex: 1,
          renderHeader: () => (
            <Typography
              sx={{
                color: "white",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "14px",
                lineHeight: 1,
              }}
            >
              {columna.substring(0, columna.lastIndexOf("("))}
              <br />
              {columna.substring(columna.lastIndexOf("("))}
            </Typography>
          ),
          renderCell: (params: any) => (
            <Stack direction="row" alignItems="center">
              {renderHistoryCell(columna, params, buttonsActions)}
            </Stack>
          ),
        })),
    ];
    const arrayRows =
      data && data.length > 0
        ? data?.map((fila: any) => {
            const obj: any = {};
            obj["Nº"] = fila.id;
            for (const key in fila) {
              if (key !== "Nº") {
                obj[key] = fila[key];
              }
            }
            return obj;
          })
        : [];

    const columns: GridColDef[] = arrayColumns.map((col) => ({
      field: "",
      ...col,
      headerAlign: "center",
      align: "center",
    }));

    const rows = arrayRows?.map((row: any, index: number) => ({
      ...row,
      id: index,
    }));

    return { columns, rows };
  } else {
    const arrayColumns = tableColumns
      ?.filter((item: any) => item !== "id")
      ?.map((columna: string) => ({
        field: columna,
        type: rawData ? getColumnType(columna) : undefined,
        headerClassName: "es_header_column",
        headerName: columna,
        description: columna,
        minWidth: 100,
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => (
          <Typography
            sx={{
              color: "white",
              textAlign: "center",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: 1,
            }}
          >
            {columna.substring(0, columna.lastIndexOf("("))}
            <br />
            {columna.substring(columna.lastIndexOf("("))}
          </Typography>
        ),
        renderCell: (params: any) => (
          <Stack direction="row" alignItems="center">
            {renderHistoryCell(columna, params, buttonsActions)}
          </Stack>
        ),
      }));
    const arrayRows =
      data && data.length > 0
        ? data?.map((fila: any, index: number) => {
            const obj: any = {};
            /* obj["id"] = fila.id; */
            for (const key in fila) {
              /*  if (key !== "id") { */
              obj[key] = fila[key];
              /*  } */
            }
            return { ...obj, id: index };
          })
        : [];

    const columns: GridColDef[] = arrayColumns.map((col) => ({
      ...col,
      headerAlign: "center",
      align: "center",
    }));

    const rows = arrayRows?.map((row: any) => ({
      ...row,
    }));

    return { columns, rows };
  }
};

export const renderHistoryCell = (
  column: string,
  params: any,
  buttonsActions: any
) => {
  switch (column) {
    case "extra":
      return (
        <Button
          startIcon={<ZoomInIcon />}
          variant="text"
          sx={{ ":hover": { color: "var(--blue-greeny)" } }}
          onClick={() => buttonsActions.viewModal(params.row.extra)}
        >
          {"Ver detalle"}
        </Button>
      );
      case "fecha":
        return (
          <Typography
          sx={{fontSize:"12px"}}
          >
            {new Date(params.value).toLocaleString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false })}
          </Typography>
        );
    default:
      return params.value;
  }
};
