import {
  Box,
  Chip,
  InputLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useRef, useState } from "react";
import Dialog from "../dialog/Dialog";
import useApi from "../../hooks/useApi";
import {
  DATA_URL,
  FILE_BY_ID,
  FILE_INFO_URL,
  GET_FILE_INFO_URL,
  RELOAD_EXTERNAL_RESOURCE,
} from "../../api/axios";
import { texts } from "../../texts";
import useSingleSelect from "../../hooks/useSingleSelect";
import { form_label } from "../../styles/app-styles";
import Select from "../forms/Select";
import { selectStyles } from "../../styles/select.styles";
import { formatSimpleSheets } from "../fileColumns/utils";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import { transformData } from "../virtualizedTable/transformData";
import VirtualizatedTable from "../virtualizedTable/VirtualizedTable";
import DataDropdownMenu from "./DataDropdownMenu";
import { Label } from "./ResourceLabels/types";
import SelectResourceLabel from "./ResourceLabels/SelectResourceLabel";
import { SingleValue } from "react-select";
import { IdOption } from "../forms/types";
import { formatDate } from "../../utils/util";
import ReloadResources from "./ReloadResources";

interface FileDetailModalProps {
  name: string;
  type: string;
  fileLocation: string;
  date: string;
  modifiedDate: string
  open: boolean;
  handleClose: () => void;
  folderLocation: string;
  refreshResources?: () => void;
  label?: Label;
  size?: number;
  id?: number;
  resourceId?: number;
  isExternalResource?: boolean;
}

const FileDetailModal = ({
  name,
  date,
  modifiedDate,
  type,
  fileLocation,
  open,
  handleClose,
  folderLocation,
  refreshResources,
  label,
  size,
  id,
  isExternalResource,
  resourceId,
}: FileDetailModalProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [tableData, setTableData] = useState<any>(null);
  const [fileData, setFileData] = useState<any>([]);
  const [delayHelper, setDelayHelper] = useState<boolean>(false);
  const [sheetsData, setSheetsData] = useState<any[]>([]);
  const [selectedLabel, setSelectedLabel] = useState<
    SingleValue<IdOption> | undefined
  >();
  const sheetRef: any = useRef(null);
  const [
    sheetSelectedOption,
    setSheetSelectedOption,
    sheetOptionsChangeHandler,
  ] = useSingleSelect();

  /*********** Menú desplegable *****************/

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  /******************************* LLAMADAS ************************************/

  const onSuccessGetFileInfo = (data: any) => {
    setFileData(data);
    setSheetSelectedOption({
      value: data.hojas[0].id,
      label: data.hojas[0].nombre,
    });
    if (data.muestra) {
      setSheetsData((prevState: any) =>
        prevState
          ? [
              ...prevState,
              { sheetId: data.hojas[0].id, sheetInfo: data.muestra },
            ]
          : [{ sheetId: data.hojas[0].id, sheetInfo: data.muestra }]
      );
    }
    setTimeout(() => {
      setDelayHelper(true);
    }, 2);
  };

  const onErrorGetFileInfo = (data: any) => {
    setFileData([]);
  };

  const {
    data: dataFileById,
    isLoading: isLoadingFileInfoById,
    callApi: getFileInfoById,
    error: errorFileInfoById,
  } = useApi(
    FILE_BY_ID(id),
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    undefined,
    undefined
  );

  const {
    data: dataFile,
    isLoading: isLoadingFileInfo,
    callApi: getFileInfo,
    error: errorFile,
    status: statusFile,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    {
      path_archivo: `${fileLocation}${name}`,
      directorio: false,
      tipo: "azure",
      carga_datos_detalle: true,
      actualizar_archivo: true,
    },
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    undefined,
    undefined,
    { params: { refetchOn401: false } }
  );

  const onSuccessGetTableData = (data: any) => {
    setTableData(data.body);
    setSheetsData((prevState: any) =>
      prevState
        ? [
            ...prevState,
            { sheetId: sheetSelectedOption?.value, sheetInfo: data.body },
          ]
        : [{ sheetId: sheetSelectedOption?.value, sheetInfo: data.body }]
    );
  };

  const onErrorGetTableData = (data: any) => {
    setTableData(null);
  };

  const {
    data: dataGetTable,
    isLoading: isLoadingGetTable,
    error: isErrorGetTable,
    callApi: getTable,
  } = useApi(
    GET_FILE_INFO_URL(
      id ? id : dataFile?.id,
      sheetSelectedOption?.value as number,
      false
    ),
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetTableData,
    onErrorGetTableData,
    false
  );

  const {
    isLoading: isLoadingEditResourceLabel,
    callApi: callEditResourceLabel,
  } = useApi(
    DATA_URL,
    "PUT",
    texts.dataManagement.editResource.codes,
    {
      ruta: `${folderLocation}${name}`,
      etiqueta: selectedLabel,
    },
    () => {
      refreshResources && refreshResources();
    },
    undefined,
    false
  );

  const {
    isLoading: isLoadingPostFileLabel,
    callApi: postFileLabel,
    error: errorPutFileLabel,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    () => {
      refreshResources && refreshResources();
    },
    undefined,
    false,
    undefined,
    undefined,
    { params: { refetchOn401: false } }
  );

  const {
    isLoading: isLoadingReloadExternalResource,
    callApi: reloadExternalResource,
  } = useApi(
    RELOAD_EXTERNAL_RESOURCE(resourceId as number),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    { archivo: id ? id : dataFile?.id },
    onSuccessGetFileInfo,
    undefined,
    false
  );

  /******************* CARGA DE DATOS ************************/

  useEffect(() => {
    if (open) {
      if (type === "archivo") {
        if (id !== undefined && id !== null) {
          getFileInfoById(`${FILE_BY_ID(id)}?carga_datos_detalle=true`);
        } else {
          getFileInfo();
        }
      } else {
        setTimeout(() => {
          setDelayHelper(true);
        }, 2);
      }
      label?.nombre !== undefined &&
        setSelectedLabel({
          value: label?.id as number,
          label: label?.nombre as string,
          color: label?.color as string,
        } as SingleValue<IdOption>);
    }
  }, [open]);

  /*useEffect(() => {
    if (dataFile && sheetSelectedOption) {
      getTable();
    }
  }, [dataFile]);

  useEffect(() => {
    if (dataFileById && sheetSelectedOption) {
      getTable();
    }
  }, [dataFileById]);*/

  useEffect(() => {
    if (sheetSelectedOption) {
      const tableInfo = sheetsData.find(
        (sheet: any) => sheet.sheetId === sheetSelectedOption.value
      );
      if (tableInfo) {
        setTableData(tableInfo.sheetInfo);
      } else {
        getTable(
          `${GET_FILE_INFO_URL(
            id ? id : dataFile?.id,
            sheetSelectedOption?.value as number,
            false
          )}&carga_datos_detalle=true`
        );
      }
    }
  }, [sheetSelectedOption]);

  /******************** HANDLERS ********************/
  const hanldeChangeLabel = (label: SingleValue<IdOption> | undefined) => {
    if (type === "archivo" && selectedLabel === undefined) {
      postFileLabel(undefined, {
        path_archivo: `${folderLocation}${name}`,
        directorio: false,
        etiqueta: label !== undefined ? label?.value : "",
        tipo: "azure",
        actualizar_archivo: false,
      });
    } else {
      callEditResourceLabel(undefined, {
        lista_recursos: [
          {
            ruta: `${folderLocation}${name}`,
            tipo: type,
            etiqueta: label !== undefined ? label?.value : "",
          },
        ],
      });
    }
  };
  /********************* LIMPIEZA *******************/

  const cleanAndClose = () => {
    setFileData(null);
    setTableData(null);
    setSheetSelectedOption(undefined);
    sheetRef?.current?.clearValue();
    setDelayHelper(false);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      handleClose={cleanAndClose}
      maxWidth="xl"
      title={name}
      draggable={true}
      sx={{ zIndex: "100" }}
    >
      <SimpleBackdrop
        open={isLoadingFileInfo || isLoadingFileInfoById}
        message={`Cargando información ${
          type === "archivo" ? "del archivo" : "de la carpeta"
        }...`}
      />
      <SimpleBackdrop
        open={isLoadingEditResourceLabel || isLoadingPostFileLabel}
        message={texts.dataManagement.editResource.loading}
      />
      <Stack sx={{ width: "1100px" }}>
        <Stack direction="row" sx={{ gap: 2, marginBottom: "10px" }}>
          <Typography
            sx={{
              color: "var(--blue)",
              fontSize: "22px",
              fontWeight: "bold",
            }}
          >
            Información{" "}
            {`${type === "archivo" ? "del archivo" : "de la carpeta"}`}
          </Typography>
          <Stack direction="row" marginLeft="auto">
            {isExternalResource && (
              <ReloadResources
                isSingleResource
                refetchResources={reloadExternalResource}
              />
            )}

            <MoreHorizIcon
              sx={{
                cursor: "pointer",
                color: "var(--blue)",
                marginRight: "5px",
                marginLeft: "4px",
                fontSize: "30px",
              }}
              onClick={(e: any) => handleOpenUserMenu(e)}
            />
          </Stack>
        </Stack>
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "18px",
            fontWeight: "bold",
            marginBottom: "0px",
          }}
        >
          Fecha de creación:
        </Typography>
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "18px",
          }}
        >
          {formatDate(date)}
        </Typography>
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "18px",
            fontWeight: "bold",
            marginBottom: "0px",
          }}
        >
          Fecha de modificación:
        </Typography>
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "18px",
          }}
        >
          {modifiedDate && formatDate(modifiedDate)}
        </Typography>
        {size !== undefined && (
          <>
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
            >
              Peso del archivo:
            </Typography>
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "18px",
              }}
            >
              {`${size.toFixed(1)} mb`}
            </Typography>
          </>
        )}
        {delayHelper && (
          <SelectResourceLabel
            setLabel={setSelectedLabel}
            selectedLabel={selectedLabel}
            customHandleChange={hanldeChangeLabel}
            customMargin="0px"
            customFontSize="18px"
          />
        )}
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "18px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Permisos
        </Typography>
        <Stack direction="row" sx={{ gap: 2, marginBottom: "15px" }}>
          <Chip
            icon={<AutoStoriesIcon />}
            label="Lectura"
            variant="outlined"
            sx={{
              width: "140px",
              color: "var(--blue)",
              fontSize: "15px",
            }}
          />
          <Chip
            icon={<EditIcon />}
            label="Edición"
            variant="outlined"
            sx={{
              width: "140px",
              color: "var(--blue)",
              fontSize: "15px",
            }}
          />
          <Chip
            icon={<DeleteIcon />}
            label="Eliminación"
            variant="outlined"
            sx={{
              width: "140px",
              color: "var(--blue)",
              fontSize: "15px",
            }}
          />
        </Stack>
        {type === "archivo" && (
          <>
            <Stack sx={{ marginBottom: "30px" }}>
              <Typography
                sx={{
                  color: "var(--blue)",
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Previsualización
              </Typography>
              {fileData &&
                fileData?.hojas?.length &&
                sheetSelectedOption !== undefined &&
                delayHelper && (
                  <Stack sx={{ marginBottom: "10px" }}>
                    <InputLabel sx={form_label}>Hoja </InputLabel>
                    <Select
                      reference={sheetRef}
                      styles={selectStyles(sheetSelectedOption)}
                      options={formatSimpleSheets(fileData?.hojas)}
                      name="Hoja"
                      onChange={sheetOptionsChangeHandler}
                      closeMenuOnSelect
                      placeholder="Seleccionar hoja del archivo"
                      isClearable={false}
                      defaultValue={sheetSelectedOption}
                    />
                  </Stack>
                )}
              {(isLoadingGetTable ||
                isLoadingFileInfo ||
                isLoadingReloadExternalResource) && (
                <Box sx={{ width: "100%" }}>
                  <CustomSkeleton height={5} />
                </Box>
              )}
              {!isLoadingGetTable &&
                !isLoadingReloadExternalResource &&
                tableData &&
                tableData?.length > 0 && (
                  <VirtualizatedTable
                    columns={transformData(tableData)?.columns}
                    rows={transformData(tableData)?.rows}
                    rowsPerPageOptions={[5, 10, 20]}
                    rowHeight={35}
                    tableHeight={400}
                    hideFooter={true}
                    toolbar={false}
                    columnsButton={false}
                    filterButton={false}
                    densitySelector={false}
                    exportButton={false}
                    checkboxSelection={false}
                  />
                )}
            </Stack>
            {/*
                    <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Totales y acceso a reportes de data
            </Typography>
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Validación de códigos
            </Typography>
            <Stack direction="row" sx={{ gap: 2, marginBottom: "15px" }}>
              <Tooltip
                title={"Abrir validación"}
                sx={{
                  pointerEvents: "fill",
                }}
              >
                <Chip
                  label="Validación ventas totales"
                  variant="outlined"
                  sx={{
                    width: "200px",
                    color: "var(--blue)",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
              <Tooltip
                title={"Abrir validación"}
                sx={{
                  pointerEvents: "fill",
                }}
              >
                <Chip
                  label="Validación códigos SKU"
                  variant="outlined"
                  sx={{
                    width: "200px",
                    color: "var(--blue)",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Stack>
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Resumén de información
            </Typography>
            <Stack direction="row" sx={{ gap: 2, marginBottom: "15px" }}>
              <Tooltip
                title={"Abrir resumen"}
                sx={{
                  pointerEvents: "fill",
                }}
              >
                <Chip
                  label="Resumen ventas"
                  variant="outlined"
                  sx={{
                    width: "200px",
                    color: "var(--blue)",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
              <Tooltip
                title={"Abrir resumen"}
                sx={{
                  pointerEvents: "fill",
                }}
              >
                <Chip
                  label="Resumen códigos SKU"
                  variant="outlined"
                  sx={{
                    width: "200px",
                    color: "var(--blue)",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Stack>
            */}
          </>
        )}
      </Stack>
      <DataDropdownMenu
        anchorEl={anchorElUser}
        name={name}
        id={id}
        type={type}
        folderLocation={folderLocation}
        handleCloseUserMenu={handleCloseUserMenu}
        refreshResources={refreshResources}
        date={date}
        modifiedDate={modifiedDate}
        label={label}
        size={size}
        isExternalResource={isExternalResource}
        resourceId={resourceId}
      />
    </Dialog>
  );
};

export default FileDetailModal;
