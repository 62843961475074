import { Stack } from "@mui/material";
import ComponentLayout from "../layout/ComponentLayout";
import HistoryIcon from "@mui/icons-material/History";
import SimpleTable from "../tablesTools/SimpleTable";
import { useApiQuery } from "../../hooks/useApiQuery";
import { HISTORY_URL } from "../../api/axios";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import FilterTable from "../tablesTools/FilterTable";
import { GridFilterModel } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { transformDataSimpleTable } from "./utils";
import useDialog from "../../hooks/useDialog";
import HistoryDetail from "./HistoryDetail";

const History = () => {
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [dataDetail, setDataDetail] = useState<any>();
  const [openModal, handleToggleOpenModal] = useDialog();


  const {
    data: data,
    isLoading: isLoadingData,
    refetch: refetchData,
  } = useApiQuery(HISTORY_URL, false, undefined);

  const viewModal = (data: any) => {
    setDataDetail(data)
    handleToggleOpenModal();
  };

  const buttonsActions = {
    viewModal,
  };

  return (
    <ComponentLayout title="Historial de acciones" icon={<HistoryIcon />}>
      <Stack sx={{ width: "100%" }}>
        {isLoadingData ? (
          <CustomSkeleton height={6} />
        ) : (
          <FilterTable
            columns={
              transformDataSimpleTable(
                data.map((item: any) => {
                  const { extra, ...rest } = item;
                  return { ...rest, extra };
                }),
                undefined,
                undefined,
                undefined,
                undefined,
                buttonsActions
              ).columns
            }
            rows={
              transformDataSimpleTable(
                data,
                undefined,
                undefined,
                undefined,
                undefined,
                buttonsActions
              ).rows
            }
            toolbar={true}
            exportButton={true}
            filterButton={true}
            filterModel={filterModel}
            setFilterModel={setFilterModel}
          />
        )}
        <HistoryDetail data={dataDetail} openDetailModal={openModal} handleToggleDetailModal={handleToggleOpenModal}/>
      </Stack>
    </ComponentLayout>
  );
};

export default History;
