import {
  Box,
  Checkbox,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import useApi from "../../hooks/useApi";
import { FILE_BY_ID, FILE_INFO_URL, GET_FILE_INFO_URL } from "../../api/axios";
import { texts } from "../../texts";
import { useEffect, useRef, useState } from "react";
import { Button } from "../buttons";
import { form_label } from "../../styles/app-styles";
import Select from "../forms/Select";
import useSingleSelect from "../../hooks/useSingleSelect";
import { selectStyles } from "../../styles/select.styles";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import UploadFileContent from "../dataManagement/UploadFileModal/UploadFileContent";
import { formatSheets, formatSimpleSheets } from "../fileColumns/utils";
import InfoIcon from "@mui/icons-material/Info";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import SimpleTable from "../tablesTools/SimpleTable";
import { transformDataSimpleTable } from "../tablesTools/transformDataSimpleTable";
import ColumnsValidationsModal from "./ColumnsValidationsModal";
import useDialog from "../../hooks/useDialog";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../forms/types";
import HeaderByColumnModal from "../pipelines/Step2/HeaderByColumnModal";
import FinalColumns from "./FinalColumns";
import { deleteId } from "./utils";

interface IValidationsProps {
  setValidations: (val: any) => void;
  validations: any;
  originalValidations?: any 
}

const Validations = ({ setValidations, validations, originalValidations }: IValidationsProps) => {
  const [selectedFile, setSelectedFile] = useState<string[]>(
    validations?.file?.nombre !== undefined
      ? validations?.file?.nombre
      : validations?.file?.nombre_archivo !== undefined
      ? validations?.file?.nombre_archivo
      : []
  );
  const [dataFileState, setDataFileState] = useState<any>(
    validations?.file ? [validations?.file] : []
  );
  const [sheetsFileOptions, setSheetsFileOptions] = useState<any>(
    validations?.file?.hojas
      ? validations?.file?.hojas
      : validations?.file?.contenido?.hojas
      ? validations?.file?.contenido?.hojas
      : []
  );
  const [selectedFileFolder, setSelectedFileFolder] = useState<string>(
    validations?.file?.ruta ? validations?.file?.ruta : ""
  );
  const [headerIndex, setHeaderIndex] = useState<number | string | undefined>(
    validations?.header?.index ? validations?.header.index : undefined
  );
  const [openValidationsModal, toggleValidationsModal] = useDialog();
  const [columnsValidations, setColumnsValidations] = useState<any>(
    validations?.validations ? validations?.validations : undefined
  );
  const [openHeaderByColumnModal, toggleHeaderByColumnModal] = useDialog();
  const [withoutFile, setWithoutFile] = useState<boolean>(
    validations?.withoutFile !== undefined ?validations?.withoutFile: false
  );
  const [finalColumns, setFinalColumns] = useState<any[]>(
    validations?.finalColumns !== undefined ?validations?.finalColumns: []
  );


  const sheetRef: any = useRef(null);
  const [
    sheetSelectedOption,
    setSheetSelectedOption,
    sheetOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    validations?.sheet?.value ? validations?.sheet : undefined
  );

  useEffect(() => {
    // Cada vez que cambiamos la hoja vamos a pedir nuevamente la muestra
    if (sheetSelectedOption !== undefined && dataFileState?.length > 0) {
      getTable();
    }
  }, [sheetSelectedOption]);

  /********************* LLAMADAS **************************/

  const onSuccessGetFileEditInfo = (data: any) => {
    setDataFileState([
      {
        ...data,
        contenido: data.hojas.map((sheet: any) => {
          return { ...sheet, hoja: sheet.nombre };
        }),
      },
    ]);
    setValidations({ ...validations, file: data });
    setSheetsFileOptions(formatSimpleSheets(data.hojas));
  };

  const onErrorGetFileEditInfo = (data: any) => {
    setDataFileState([]);
    setSheetsFileOptions([]);
  };

  const {
    data: dataFileById,
    isLoading: isLoadingFileInfoById,
    callApi: getFilesInfoEdit,
    error: errorFileInfoById,
  } = useApi(
    FILE_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileEditInfo,
    onErrorGetFileEditInfo,
    false,
    undefined,
    undefined
  );

  const onSuccessGetFileInfo = (data: any) => {
    sheetRef?.current?.clearValue();
    setSheetSelectedOption(undefined);
    setDataFileState([data]);
    setValidations({ ...validations, file: data });
    setSheetsFileOptions(formatSheets(data.contenido));
  };

  const onErrorGetFileInfo = (data: any) => {
    setDataFileState([]);
  };

  const {
    data: dataFile,
    isLoading: isLoadingFileInfo,
    callApi: getFilesInfo,
    error: errorFile,
    status: statusFile,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    undefined,
    undefined,
    { params: { refetchOn40: false } }
  );

  const {
    data: dataGetTable,
    isLoading: isLoadingGetTable,
    error: isErrorGetTable,
    callApi: getTable,
    setData: setDataTable
  } = useApi(
    GET_FILE_INFO_URL(
      dataFileState[0]?.id,
      sheetSelectedOption?.value as number,
      false
    ),
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    // Cada vez que se actualiza la muestra actualizamos las validaciones
    const fileName =
      originalValidations?.file?.nombre || validations?.file?.nombre_archivo;
    if (dataGetTable && dataGetTable?.body && dataGetTable?.body?.length > 0) {
      if (fileName !== selectedFile) {
        const columns = Object.keys(deleteId(dataGetTable?.body)[0]);
        const columnsFormatted = columns.map((col: string) => {
          return {
            noEmptyValues: {
              value: "permitir-vacios",
              label: "Permitir valores vacios",
            },
            column: col,
            columnType: "string",
          };
        });
        setValidations({ ...validations, validations: columnsFormatted, finalColumns: Object.keys(deleteId(dataGetTable?.body)[0]).map((col: string)=> {return {columna: col, alias: []}}) });
        setColumnsValidations(columnsFormatted);
        setFinalColumns(Object.keys(deleteId(dataGetTable?.body)[0]).map((col: string)=> {return {columna: col, alias: []}}))
      } else {
        const columns = Object.keys(deleteId(dataGetTable?.body)[0]);
        const columnsFormatted = columns.map((col: string) => {
          if (validations?.validations?.find((val: any) => val.column == col)) {
            return validations?.validations?.find(
              (val: any) => val.column === col
            );
          } else {
            return {
              noEmptyValues: {
                value: "permitir-vacios",
                label: "Permitir valores vacios",
              },
              column: col,
              columnType: "string",
            };
          }
        });
        setValidations({ ...validations, validations: columnsFormatted, finalColumns: (validations?.finalColumns?.length === 0 || validations?.finalColumns?.length === undefined ) ? Object.keys(deleteId(dataGetTable?.body)[0]).map((col: string)=> {return {columna: col, alias: []}}): validations?.finalColumns });
        setColumnsValidations(columnsFormatted);
        (validations?.finalColumns?.length === 0 || validations?.finalColumns?.length === undefined )&& setFinalColumns(Object.keys(deleteId(dataGetTable?.body)[0]).map((col: string)=> {return {columna: col, alias: []}}))

      }
    }
  }, [dataGetTable]);

  useEffect(() => {
    // Cada vez que se actualiza el header index actualizamos las validaciones
    const fileName =
      validations?.file?.nombre || validations?.file?.nombre_archivo;
    if (dataGetTable && dataGetTable?.body && dataGetTable?.body?.length > 0) {
      if (fileName !== selectedFile) {
        let unnamedCount = 0;  // Contador para los nombres "Unnamed"
        const columns =
          headerIndex !== undefined && headerIndex !== ""
            ? Object.values(deleteId(dataGetTable?.body)[headerIndex as number])
            : Object.keys(deleteId(dataGetTable?.body)[0]);

        const columnsFormatted = columns.map((col: any) => {
          // Verificar si el valor de col es NaN o null
          if (col === null || col === "nan" || col === "NaN" || col ==="null") {
            // Asignar "Unnamed" con un número incrementado
            col = `Unnamed ${unnamedCount}`;
            unnamedCount++;  // Incrementamos el contador
          }
        
          return {
            noEmptyValues: {
              value: "permitir-vacios",
              label: "Permitir valores vacíos",
            },
            column: col,
            columnType: "string",
          };
        });
        setFinalColumns(columnsFormatted.map((col: any)=> {return {columna: col.column, alias: []}}))
        setValidations({ ...validations, validations: columnsFormatted , finalColumns: columnsFormatted.map((col: any)=> {return {columna: col.column, alias: []}})});
        setColumnsValidations(columnsFormatted);
      } else {
        let unnamedCount = 0;  // Contador para los nombres "Unnamed"
        const columns =
          headerIndex !== undefined && headerIndex !== ""
            ? Object.values(deleteId(dataGetTable?.body)[headerIndex as number])
            : Object.keys(deleteId(dataGetTable?.body)[0]);
        
        const columnsFormatted = columns.map((col: any) => {
          // Verificar si el valor de col es NaN o null
          if (col === null || col === "nan" || col === "NaN" || col ==="null"|| col ==="") {
            // Asignar "Unnamed" con un número incrementado
            col = `Unnamed ${unnamedCount}`;
            unnamedCount++;  // Incrementamos el contador
          }
        
          return {
            noEmptyValues: {
              value: "permitir-vacios",
              label: "Permitir valores vacíos",
            },
            column: col,
            columnType: "string",
          };
        });
        setValidations({ ...validations, validations: columnsFormatted, finalColumns: columnsFormatted.map((col: any)=> {return {columna: col.column, alias: []}}) });
        setColumnsValidations(columnsFormatted);
        setFinalColumns(columnsFormatted.map((col: any)=> {return {columna: col.column, alias: []}}))
      }
    }
  }, [headerIndex]);

  /**************************************** HANDLERS ********************************************************* */

  const handleSelectFile = (
    fileState: any[],
    clickedFile: any,
    deletedFile?: boolean
  ) => {
    setSelectedFile(fileState);
    setSheetSelectedOption(undefined)
    setDataTable([])
    setHeaderIndex("")
    setFinalColumns([])
    sheetRef?.current?.clearValue()
    if (clickedFile !== "delete all") {
      if (deletedFile) {
        setDataFileState((prevState: any) =>
          prevState.filter((file: any) => file.nombre !== clickedFile.nombre)
        );
      } else {
        if (clickedFile && clickedFile !== "typingFile") {
          if (clickedFile.id === null || clickedFile.id === undefined) {
            getFilesInfo(undefined, {
              path_archivo:
                clickedFile === "chooseFile"
                  ? selectedFile
                  : `${selectedFileFolder}${clickedFile.nombre}`,
              directorio: false,
              tipo: "azure",
              columnas: true,
              posicion: "left",
              actualizar_archivo: true,
            });
          } else {
            getFilesInfoEdit(
              `${FILE_BY_ID(clickedFile.id)}?columnas=True&posicion=left`
            );
          }
        } else {
          clickedFile !== "typingFile" && setDataFileState([]);
        }
      }
    }
  };

  const handleSetHeaderByColumn = () => { 
    toggleHeaderByColumnModal()
   };

  const handleChangeSheet = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    sheetOptionsChangeHandler(newValue, actionMeta);
    setValidations({ ...validations, sheet: newValue });
  };

  const handleAcceptValidationsModal = (validationsParam: any[]) => {
    toggleValidationsModal();
    setColumnsValidations(validationsParam);
    setValidations({ ...validations, validations: validationsParam });
  };

  const handleAcceptHeaderByColumn = (col: string | undefined)=>{
    toggleHeaderByColumnModal()
    setValidations({
      ...validations,
      header: {...validations.header, exp_regular: col }
    });
  }

  const handleChangeFinalColumns = (cols: any[])=>{
    setFinalColumns(cols)
    setValidations({
      ...validations,
      finalColumns: cols
    });
  }

  return (
    <Stack sx={{ width: "100%" }}>
      <SimpleBackdrop
        open={isLoadingFileInfo || isLoadingFileInfoById}
        message="Cargando archivo..."
      />
      <Stack sx={{ width: "100%" }}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "var(--blue)",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          Validaciones
        </Typography>
        <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
          Elija el archivo base:
        </InputLabel>
        <Stack direction={"row"} alignItems={"center"}>
        { /* 
        <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
          No elegir archivo base
        </InputLabel>
        <Checkbox
                    checked={withoutFile}
                    onChange={(e, checked) => {
                      setWithoutFile((prev) => !prev);
                      setValidations({ ...validations, withoutFile: checked });
                    }}
                    sx={{marginBottom:"8px"}}
                  />
        */ }
        
        </Stack>
        {!withoutFile &&   <UploadFileContent
          areMultipleFiles={false}
          selectedFile={selectedFile}
          setSelectedFile={handleSelectFile}
          dataFileState={dataFileState}
          setDataFileState={setDataFileState}
          setSelectedFileFolder={setSelectedFileFolder}
          // onSuccessChargeLocalFiles={onSuccessGetFileInfo}
          customWidth="600px"
          customInitialRoute={selectedFileFolder}
        />}
      
        {dataFileState && !withoutFile &&(
          <Stack
            direction="row"
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputLabel sx={form_label}>Hoja </InputLabel>
                <Select
                  reference={sheetRef}
                  styles={selectStyles(sheetSelectedOption)}
                  options={sheetsFileOptions}
                  name="Hoja"
                  onChange={handleChangeSheet}
                  closeMenuOnSelect
                  placeholder="Seleccionar hoja del archivo"
                  isClearable
                  defaultValue={
                    validations?.sheet
                      ? validations?.sheet
                      : sheetSelectedOption
                  }
                />
              </Grid>
            </Grid>
          </Stack>
        )}
        {    <Stack sx={{ marginBottom: "10px" }}>
        <Tooltip
                  title={
                     "Determina qué fila se convertirá en cabecera en base a una columna a buscar"
                  }
                  arrow
                >
                  <Stack sx={{marginLeft: withoutFile ? undefined:"auto"}}>
                    <Button
                      title="Setear cabecera por columna"
                      color="blue-greeny"
                      type="button"
                      onClick={handleSetHeaderByColumn}
                      width={200}
                    />
                  </Stack>
                </Tooltip>
                {!withoutFile &&    <Stack direction="row" marginTop={"10px"}>
            <InputLabel id="demo-simple-select-filled-label" sx={form_label}>
              Indice de la cabecera
            </InputLabel>
         
            <Tooltip title="Si no se envia indice de cabecera se utiliza la cabecera original">
              <InfoIcon
                sx={{
                  color: "var(--blue)",
                  fontSize: "21px",
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginBottom: "2px",
                }}
              />
            </Tooltip>
          </Stack>}
       
        {!withoutFile &&   <Stack direction="row" alignItems={"center"}>
            <TextField
              type="number"
              id="rangeToRows"
              label={""}
              placeholder={"Índice de la cabecera"}
              size="small"
              multiline
              variant="outlined"
              fullWidth
              value={headerIndex}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const regex = /^[0-9\b]+$/;
                if (
                  event.target.value === "" ||
                  regex.test(event.target.value)
                ) {
                  setHeaderIndex(event.target.value);
                  setValidations({
                    ...validations,
                    header: {...validations.header, index:event.target.value }
                  });
                }
              }}
              FormHelperTextProps={{
                sx: {
                  color: "var(--magenta)",
                },
              }}
            />
            {dataGetTable && (
              <>
                <Button
                  title={"Validaciones"}
                  color={"blue"}
                  type={"button"}
                  onClick={toggleValidationsModal}
                />
                <ColumnsValidationsModal
                  open={openValidationsModal}
                  handleClose={toggleValidationsModal}
                  handleAccept={handleAcceptValidationsModal}
                  columns={deleteId(dataGetTable?.body)}
                  originalValidations={columnsValidations}
                  header={headerIndex}
                />
              </>
            )}
          </Stack>}
        
        </Stack>}
    
        {isLoadingGetTable ? (
          <Box sx={{ width: "100%" }}>
            <CustomSkeleton height={5} />
          </Box>
        ) : (
          dataGetTable &&
          dataGetTable?.body?.length > 0 && !withoutFile && (
            <SimpleTable
              columns={
                transformDataSimpleTable(
                  dataGetTable?.body,
                  undefined,
                  undefined,
                  true
                ).columns
              }
              rows={
                transformDataSimpleTable(
                  dataGetTable?.body,
                  undefined,
                  undefined,
                  true
                ).rows
              }
              toolbar={false}
            />
          )
        )}
      </Stack>
      <FinalColumns columns={finalColumns} setColumns={handleChangeFinalColumns}/>

      <HeaderByColumnModal
            open={openHeaderByColumnModal}
            handleClose={toggleHeaderByColumnModal}
            handleAccept={handleAcceptHeaderByColumn}
            defaultValue={ validations?.header?.exp_regular? validations?.header?.exp_regular: undefined}
            />
    </Stack>
  );
};

export default Validations;
