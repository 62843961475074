import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";
import { InputLabel, Typography } from "@mui/material";
import Dialog from "../../dialog/Dialog";
import { form_label } from "../../../styles/app-styles";
import { AddButton, Button } from "../../buttons";
import useSingleSelect from "../../../hooks/useSingleSelect";
import Select from "../../forms/Select";
import { selectStyles } from "../../../styles/select.styles";
import CheckmarkSelect from "../../dataControl/CheckmarkSelect";
import DataManagementProvider from "../../../context/DataManagementContext";
import { formatFontFilesOptions } from "../../../utils/util";
import ShadowBox from "../../flowConfiguration/Modals/ShadowBox";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../forms/types";
import { isExcelFile } from "../../flowConfiguration/utils";
import useMultiSelect from "../../../hooks/useMultiSelect";
import SheetsOptions from "./SheetsOptions";

interface FileOptions {
  file: any;
  filesState: any[];
  setFilesState: (state: any) => void;
  isValidationPrev?: boolean
}

const FileOptions = ({ file, setFilesState, filesState, isValidationPrev}: FileOptions) => {
  const [selectedPeriods, setSelectedPeriods] = useState<number[]>([]);
  const [fileSelectedOption, setFileSelectedOption, fileChangeHandler] =
    useSingleSelect();
  const [sheetsSelectedOption, setSheetsSelectedOption, sheetsChangeHandler] =
    useMultiSelect();
  const [numberOfConfigs, setNumberOfConfigs] = useState<any[]>([1]);
  const [
    delimiterSelectedOption,
    setDelimiterSelectedOption,
    delimiterChangeHandler,
  ] = useSingleSelect();

  const delimiterOptions = [
    {
      value: "inferir",
      label: "Inferir",
    },
    {
      value: ",",
      label: ",",
    },
    {
      value: ";",
      label: ";",
    },
    {
      value: "|",
      label: "|",
    }
  ];

  const handleChangeDelimiterSelectedOption = (
    newValue: any,
    actionMeta: ActionMeta<IdOption>
  ) => {
    delimiterChangeHandler(newValue, actionMeta);
    setFilesState(
      filesState.map((f: any) => {
        return f.nombre === file.nombre || f.nombre === file.name
          ? { ...f, delimitador: newValue?.value }
          : f;
      })
    );
  };


  const { DataState, dataDispatch } = useContext<
    React.ContextType<typeof DataManagementProvider>
  >(DataManagementProvider);

  const handleChangeSelectedPeriod = (periods: any) => {
    setSelectedPeriods(periods);
    setFilesState(
      filesState.map((f: any) => {
        return (f.nombre === file.nombre || f.nombre === file.name)? { ...f, periods: periods } : f;
      })
    );
  };

  const handleChangeFileSelectedOption = (
    newValue: any,
    actionMeta: ActionMeta<IdOption>
  ) => {
    fileChangeHandler(newValue, actionMeta);
    setFilesState(
      filesState.map((f: any) => {
        return (f.nombre === file.nombre || f.nombre === file.name)? { ...f, file: {file: newValue?.value, type: newValue.type }} : f;
      })
    );
  };

  const handleAddConfig = ()=> {
    setNumberOfConfigs([...numberOfConfigs, "newConfig"])
  }



  return (
    <ShadowBox margin>
      {isExcelFile(file.nombre ? file.nombre : file.name) ? (
        <Stack
          sx={{
            width:"fit-content",
            alignItems: "center",
            minHeight: "200px",
            justifyContent: "space-evenly",
          }}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: "bold", color: "var(--blue)" }}
          >
            {file?.nombre ? file.nombre : file.name}:
          </Typography>
 
            { numberOfConfigs.map((number: any, index: number) => (
              <SheetsOptions
                file={file}
                filesState={filesState}
                setFilesState={setFilesState}
                key={file.nombre? file.nombre +index : file.name+index}
                id={file.nombre? file.nombre +index : file.name+index}
              />
            ))}
            <AddButton
                onClick={handleAddConfig}
                disabled={false}
                margin={"0"}
              />
          
          {fileSelectedOption && (
            <>
              <InputLabel
                sx={{ ...form_label, marginBottom: "2px", fontSize: "18px" }}
              >
                {`Seleccione los periodos asociados:`}
              </InputLabel>
              <CheckmarkSelect
                dates={fileSelectedOption?.periods}
                selectedPeriods={selectedPeriods}
                setSelectedPeriods={handleChangeSelectedPeriod}
              />
            </>
          )}
        </Stack>
      ) : (
        <Stack
          sx={{
            margin: "0 15px 5px 15px",
            alignItems: "center",
            minHeight: "200px",
            width:"fit-content",
            justifyContent: "space-evenly",
          }}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: "bold", color: "var(--blue)" }}
          >
            {file?.nombre ? file.nombre: file.name}:
          </Typography>
          <InputLabel
            sx={{ ...form_label, marginBottom: "2px", fontSize: "18px" }}
          >
            {`Seleccione el archivo asociado:`}
          </InputLabel>
          <Select
            styles={selectStyles(fileSelectedOption)}
            options={formatFontFilesOptions(DataState.periodsInfo)}
            name="File"
            onChange={handleChangeFileSelectedOption}
            closeMenuOnSelect
            defaultValue={fileSelectedOption}
            placeholder="Seleccionar archivo"
            isClearable
          />
          {fileSelectedOption && ( DataState.periodsInfo[0].padre === false
          ) &&(
            <>
              <InputLabel
                sx={{ ...form_label, marginBottom: "2px", fontSize: "18px" }}
              >
                {`Seleccione los periodos asociados:`}
              </InputLabel>
              <CheckmarkSelect
                dates={fileSelectedOption?.periods}
                selectedPeriods={selectedPeriods}
                setSelectedPeriods={handleChangeSelectedPeriod}
              />
            </>
          )}
          {(file?.name?.endsWith("csv") || file?.name?.endsWith("txt") || file?.nombre?.endsWith("txt") || file?.nombre?.endsWith("csv")) && isValidationPrev && <>
            <InputLabel
          sx={{ ...form_label, marginBottom: "2px", fontSize: "18px" }}
        >
          {`Seleccione el delimitador:`}
        </InputLabel>
        <Select
          styles={selectStyles(delimiterSelectedOption)}
          options={delimiterOptions}
          name="delimitador"
          onChange={handleChangeDelimiterSelectedOption}
          closeMenuOnSelect
          defaultValue={delimiterSelectedOption}
          placeholder="Seleccionar delimitador"
          isClearable
        />
          </> }
        </Stack>
      )}
    </ShadowBox>
  );
};

export default FileOptions;
