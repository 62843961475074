import { GridRowsProp } from "@mui/x-data-grid-pro";
import { IncomingSheetOption, SheetOptions } from "./types";

/* export const isFileUrlValid = (url: string) => {
  const urlRegex = /^https:\/\/dataacquiandpreprocdl.blob.core.windows.net/;
  return urlRegex.test(url);
}; */

export const isFileUrlExtensionValid = (url: string) => {
  return url.endsWith(".xls") || url.endsWith(".xlsx");
};

export const isFileUrlExtensionValidXlsCsvParquet = (url: string) => {
  return (
    url?.trim().endsWith(".xls") ||
    url?.trim().endsWith(".xlsx") ||
    url?.trim().endsWith(".xlsb") ||
    url?.trim().endsWith(".csv") ||
    url?.trim().endsWith(".parquet") ||
    url?.trim().endsWith(".txt")
  );
};

export const isFileValidXlsCsvParquet = (url: string) => {
  return /* isFileUrlValid(url) && */ isFileUrlExtensionValidXlsCsvParquet(url);
};

export const isFileValid = (url: string) => {
  return /* isFileUrlValid(url) && */ isFileUrlExtensionValid(url);
};

export const isDirectoryUrlValid = (url: string) => {
  return !isFileUrlExtensionValidXlsCsvParquet(url);
};

export const sheetOptions: SheetOptions = (
  sheetSelectOptions: IncomingSheetOption[] | undefined
) => {
  return sheetSelectOptions
    ? sheetSelectOptions.map((option: IncomingSheetOption) => {
        return {
          value: option.id,
          label: option.nombre,
        };
      })
    : [];
};

export const getRowByRowId = (
  rowId: number | undefined,
  rows: GridRowsProp
) => {
  const row = rows?.find((r) => r.id === rowId);
  return row;
};

export const parseHeaderColumns = (row: any) => {
  let columnIndex = 0;
  const copyOfRow: any = { ...row };
  const duplicatedRows: any = {};

  for (const clave in copyOfRow) {
    if (
      copyOfRow[clave] === "" ||
      copyOfRow[clave] == undefined ||
      copyOfRow[clave] == null
    ) {
      copyOfRow[clave] = `Unnamed: ${columnIndex}`;
    } else {
      if (isStringRepeated(Object.values(row), copyOfRow[clave])) {
        if (duplicatedRows[copyOfRow[clave]]) {
          duplicatedRows[copyOfRow[clave]].push(copyOfRow[clave]);
          copyOfRow[clave] = `${copyOfRow[clave]}.${
            duplicatedRows[copyOfRow[clave]].length - 1
          }`;
        } else {
          duplicatedRows[copyOfRow[clave]] = [copyOfRow[clave]];
        }
      }
    }
    if (clave !== "id") {
      columnIndex++;
    }
  }

  return copyOfRow;
};

export const isStringRepeated = (arr: string[], str: string) => {
  let count = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === str) {
      count++;
    }
  }
  return count > 1;
};

export const isNameRepeated = (arr: string[], name: string) => {
  return arr.includes(name);
};

export const changeName = (row: any, column: any) => {

  for (const key in row) {
    if (key === column) {
      return row[key];
    }
  }
  return undefined;
};

export const convertToText = (array: any): string => {
  return array?.length !== 0
    ? array
        ?.map((item: any) => {
          return " " + item.label;
        })
        .toString() || ""
    : "Todos";
};

export const truncateString = (str: any, length: number) => {
  return str?.length > length ? str.slice(0, length - 1) + "..." : str;
};

export function getStringNames(array: any[]) {
  const names = array?.reduce((acumulador, objeto, indice) => {
    if (indice === array?.length - 1) {
      return `${acumulador}${objeto.nombre_nuevo}`;
    } else {
      return `${acumulador}${objeto.nombre_nuevo}, `;
    }
  }, "");
  return names;
}

export const prepareRowsToDelete = (
  rows_to_delete_before_header_setting: string[],
  rows_to_delete_after_header_setting: string[]
) => {
  let rows_to_delete: number[] = [];
  if (
    rows_to_delete_before_header_setting &&
    rows_to_delete_before_header_setting?.length > 0 &&
    rows_to_delete_after_header_setting &&
    rows_to_delete_after_header_setting?.length > 0
  ) {
    rows_to_delete = rows_to_delete_before_header_setting
      ?.map((row: any) => parseInt(row))
      .concat(
        rows_to_delete_after_header_setting?.map((row: any) => parseInt(row))
      );
  } else {
    if (
      rows_to_delete_before_header_setting &&
      rows_to_delete_before_header_setting?.length > 0
    ) {
      rows_to_delete = rows_to_delete_before_header_setting?.map((row: any) =>
        parseInt(row)
      );
    }
    if (
      rows_to_delete_after_header_setting &&
      rows_to_delete_after_header_setting?.length > 0
    ) {
      rows_to_delete = rows_to_delete_after_header_setting?.map((row: any) =>
        parseInt(row)
      );
    }
  }
  return rows_to_delete;
};

export const filterDataByName = (
  data: any,
  value: string,
  setFilteredData: (value: any) => void
) => {
  if (value && data) {
    setFilteredData(
      data?.filter((object: any) => {
        return object.nombre.toLowerCase().includes(value?.toLowerCase());
      })
    );
  } else {
    data && setFilteredData(data);
  }
};

export const formatNullValidations = (validations: any) => {
  return validations.map((val: any) => {
    return { columna: val.column, valor: val.noEmptyValues.value };
  });
};

/**
 * @description La función formatRowsToDelete formatea los ids de las filas a eliminar antes de enviarlas al back (las ultimas 5 filas deben enviarse con ids negativos)
 * @param {number[]} rowsToDelete - Array con los ids de las filas a eliminar
 * @param {any[]} data - Array con los ids de las filas a eliminar

 * @returns {number[]} Array con los ids formateados como los necesita el backend
 */

export const formatRowsToDelete = (rowsToDelete: number[], data: any[]) => {
  if(data && data?.length > 0){
  // Obtener los últimos 5 IDs de la data
  const lastFiveIds = data && data?.slice(-5).map((item) => item.id);

  // Mapear los IDs a reemplazar con los últimos 5 IDs de la data
  const idsToReplace: any = {
    [lastFiveIds[0]]: -1,
    [lastFiveIds[1]]: -2,
    [lastFiveIds[2]]: -3,
    [lastFiveIds[3]]: -4,
    [lastFiveIds[4]]: -5,
  };

  // Mapear rowsToDelete reemplazando los IDs según idsToReplace
  return rowsToDelete?.map((id: number) =>
    idsToReplace[id] !== undefined ? idsToReplace[id] : id
  );
  }

};

export const transformPipelineToEditRows = (
  data: any[],
  indiceCabecera: number | undefined,
  sinCabecera: boolean,
  criterioEliminacion: number[]
) => {
  let filteredData = [...data];

  // Convertir criterio de eliminación negativo a índice positivo
  criterioEliminacion.forEach((criterio) => {
    if (criterio < 0) {
      const index = data.length + criterio; // Calcular índice basado en el número negativo
      filteredData = filteredData.filter((item) => item.id !== data[index].id);
    } else {
      filteredData = filteredData.filter((item) => item.id !== criterio);
    }
  });
  // Encontrar la posición de la fila con id igual a indiceCabecera
  const startIndex = indiceCabecera
    ? data.findIndex((item) =>
        sinCabecera
          ? item.id + 1 === indiceCabecera
          : item.id === indiceCabecera
      )
    : undefined;
  // Filtrar las filas desde startIndex hasta el final según criterio de eliminación
  if (startIndex !== undefined) {
    filteredData = filteredData.slice(startIndex);
  }

  // Inicializar un arreglo para almacenar los resultados transformados
  const transformedData: any[] = [];

  // Si sinCabecera es true, agregar una fila de cabecera y ajustar las claves de los objetos
  if (sinCabecera && !indiceCabecera) {
    const headerRow: any = {};
    Object.keys(filteredData[0]).forEach((key, index) => {
      if (key !== "id") {
        headerRow[`Unnamed: ${index}`] = key;
      }
    });
    headerRow["id"] = -1; // Asegurarse de que la fila de cabecera tenga un ID único (ej. -1)
    transformedData.push(headerRow);

    // Ajustar las claves de los objetos en filteredData para que coincidan con la fila de cabecera
    filteredData.forEach((item) => {
      const newItem: any = {};
      Object.keys(item).forEach((key) => {
        if (key !== "id") {
          newItem[`Unnamed: ${Object.keys(newItem).length}`] = item[key];
        }
      });
      newItem["id"] = item["id"];
      transformedData.push(newItem);
    });
  } else {
    // Si sinCabecera es false, simplemente agregar los datos filtrados
    filteredData.forEach((item) => {
      transformedData.push(item);
    });
  }
  return transformedData;
};

export const formatPipelineToEditHeaderRename = (rename?: any) => {
  if(rename?.length > 0){
    const formattedRename: any = {};

    // Recorrer el arreglo `data` y construir `formattedData`
    rename.forEach((item: any) => {
      formattedRename[item.nombre_viejo] = item.nombre_nuevo;
    });
  
    // Convertir el objeto `formattedData` a un arreglo de un solo elemento
    return [formattedRename];
  }else{
    return []
  }
 
};

export const formatPipelineToEditHeader = (
  indice_cabecera: number,
  rename: any[],
  muestra: any[]
) => {
  const transformedObject: any = {};
  if (rename && rename.length > 0 /*&& (indice_cabecera === null ||indice_cabecera === undefined)*/) {
    rename.forEach((item: any) => {
      transformedObject[item.nombre_viejo] = item.nombre_nuevo;
    });
      return [transformedObject];
  }
  if (muestra && muestra?.length > 0 && rename?.length < 1 && (indice_cabecera !== null && indice_cabecera !== undefined) ) {
   return muestra?.find((fila: any)=> fila.id === indice_cabecera)
  }else{
    return []
  } 
};

export const transformNoHeaderRows = (noHeaderColumns: any[], rows: any[])=> {
  const transformedData = []
  const headerRow: any = {};
  Object.keys(rows[0]).forEach((key, index) => {
    if (key !== "id") {
      headerRow[`Unnamed: ${index -1}`] = key;
    }
  });
  headerRow["id"] = -1; // Asegurarse de que la fila de cabecera tenga un ID único (ej. -1)
  transformedData.push(headerRow);
  // Ajustar las claves de los objetos en filteredData para que coincidan con la fila de cabecera
  rows.forEach((item) => {
    const newItem: any = {};
    Object.keys(item).forEach((key) => {
      if (key !== "id") {
        newItem[`Unnamed: ${Object.keys(newItem).length}`] = item[key];
      }
    });
    newItem["id"] = item["id"];
    transformedData.push(newItem);
  }); 
  return transformedData
}

export const prepareValidations = (
  val_Empty_columns: any[],
  val_type_columns: any[]
) => {
  const transformedArray: any[] = [];

  // Iterar sobre validacion_columnas_vacias
  val_Empty_columns.forEach((item) => {
    const { columna, valor } = item;

    // Determinar el tipo de columna
    const columnType = val_type_columns.find((col: any)=> col.columna === columna)

    // Construir objeto transformado
    const transformedItem = {
      column: columna,
      columnType: columnType?.tipo_dato,
      formato: columnType?.formato,
      noEmptyValues: {
        value: valor,
        label:
          valor === "permitir-vacios"
            ? "Permitir valores vacíos"
            : valor === "no-permitir-valores-vacios"
            ? "No permitir valores vacíos"
            : "No permitir columna vacía",
      },
    };

    // Agregar objeto al array transformado
    transformedArray.push(transformedItem);
  });

  return transformedArray;
};



