import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import { renderTaskCell } from "./utils";

/**
 * @name transformDataTaskManager
 * @description Función que transforma los datos de la tabla.
 * @param dataColumnas
 * @param dataFilas
 */
export const transformDataTaskManager = (
  data: any[],
  tabValue: string,
  buttonsActions: any
) => {
  const getColumnNames = () => {
    switch (tabValue) {
      case "esquema":
        return ["id", "fecha", "nombre", "estado","porcentaje", "acción", "reporte"];
      case "flow":
        return ["id", "fecha", "nombre", "estado", "acción","porcentaje", "reporte"];
      case "informe":
        return ["id", "fecha", "tipo", "estado", "porcentaje","acción"];
      default:
        return [];
    }
  };

  const columns: GridColDef[] = getColumnNames().map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {columna}
      </strong>
    ),
    renderCell: (params: any) => (
      <Stack direction="row" alignItems="center">
        {renderTaskCell(tabValue, columna, params, buttonsActions)}
      </Stack>
    ),
  }));

  const rows = data
    ? data?.map((fila) => {
        const { ...rest } = fila;
        return rest;
      })
    : [];

  return { columns, rows };
};
