import Stack from "@mui/material/Stack";
import { InputLabel, Typography } from "@mui/material";
import { form_label } from "../../../styles/app-styles";
import useSingleSelect from "../../../hooks/useSingleSelect";
import Select from "../../forms/Select";
import { selectStyles } from "../../../styles/select.styles";
import ShadowBox from "../../flowConfiguration/Modals/ShadowBox";
import { ActionMeta } from "react-select";
import { IdOption } from "../../forms/types";

interface DelimiterOptions {
  file: any;
  filesState: any[];
  setFilesState: (state: any) => void;
}

const DelimiterOptions = ({
  file,
  setFilesState,
  filesState,
}: DelimiterOptions) => {
  const [
    delimiterSelectedOption,
    setDelimiterSelectedOption,
    delimiterChangeHandler,
  ] = useSingleSelect();

  const delimiterOptions = [
    {
      value: "inferir",
      label: "Inferir",
    },
    {
      value: ",",
      label: ",",
    },
    {
      value: ";",
      label: ";",
    },
    {
      value: "|",
      label: "|",
    }
  ];

  const handleChangeDelimiterSelectedOption = (
    newValue: any,
    actionMeta: ActionMeta<IdOption>
  ) => {
    delimiterChangeHandler(newValue, actionMeta);
    setFilesState(
      filesState.map((f: any) => {
        return f.nombre === file.nombre || f.nombre === file.name
          ? { ...f, delimitador: newValue?.value }
          : f;
      })
    );
  };

  return (
    <ShadowBox margin>
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          alignItems: "center",
          minHeight: "200px",
          width: "fit-content",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          sx={{ fontSize: "18px", fontWeight: "bold", color: "var(--blue)" }}
        >
          {file?.nombre ? file.nombre : file.name}:
        </Typography>
        <InputLabel
          sx={{ ...form_label, marginBottom: "2px", fontSize: "18px" }}
        >
          {`Seleccione el delimitador:`}
        </InputLabel>
        <Select
          styles={selectStyles(delimiterSelectedOption)}
          options={delimiterOptions}
          name="delimitador"
          onChange={handleChangeDelimiterSelectedOption}
          closeMenuOnSelect
          defaultValue={delimiterSelectedOption}
          placeholder="Seleccionar delimitador"
          isClearable
        />
      </Stack>
    </ShadowBox>
  );
};

export default DelimiterOptions;
