import Stack from "@mui/material/Stack";
import Dialog from "../dialog/Dialog";
import SimpleTable from "../tablesTools/SimpleTable";
import { transformDataSimpleTable } from "./utils";
import { convertArrayToText } from "../taskManager/utils";

interface HistoryDetailProps {
  openDetailModal: boolean;
  handleToggleDetailModal: () => void;
  data: any;
}

const HistoryDetail = ({
  openDetailModal,
  handleToggleDetailModal,
  data,
}: HistoryDetailProps) => {
  function processExtra(extra: any) {
    // Verificar si "extra" tiene la clave "nombre"
    if (
      extra &&
      extra.hasOwnProperty("nombre") &&
      extra.hasOwnProperty("cambios")
    ) {
      if (extra?.cambios?.responsable) {
        extra = {
          "responsable nuevo": extra?.cambios?.responsable?.nuevo,
          "responsable anterior": extra?.cambios?.responsable?.anterior,
        };
      }
      if (extra?.cambios?.periodos) {
        extra = {
          "períodos nuevos": convertArrayToText(extra?.cambios?.periodos?.nuevo) ,
          "períodos anteriores": convertArrayToText(extra?.cambios?.periodos?.anterior),
        };
      }
      if (extra?.cambios?.nombre?.nuevo) {
        extra = {
          "nombre nuevo": extra?.cambios?.nombre?.nuevo,
          "nombre anterior": extra?.cambios?.nombre?.anterior,
        };
      }
      if (extra?.cambios?.ruta_directorio) {
        extra = {
          "ruta directorio nueva": extra?.cambios?.ruta_directorio?.nuevo,
          "ruta directorio anterior": extra?.cambios?.ruta_directorio?.anterior,
        };
      }
      if (extra?.cambios?.frecuencia_actualizacion) {
        extra = {
          "frecuencia actualización nueva":
            extra?.cambios?.frecuencia_actualizacion?.nuevo,
          "frecuencia actualzacion anterior":
            extra?.cambios?.frecuencia_actualizacion?.anterior,
        };
       
        
      }
    }
    if (extra && extra.hasOwnProperty("tipo") && extra?.tipo === "validacion") {
      extra = { "validación finalizada": extra?.cambios?.finalizada?.nuevo };
    }

    if (extra && extra.hasOwnProperty("pesado")) {
      extra = { ...extra, pesado: extra.pesado === false ? "No" : "Si" };
    }
    // Retornar el objeto extra con o sin modificaciones
    return extra;
  }

  return (
    <Dialog
      open={openDetailModal}
      handleClose={handleToggleDetailModal}
      title={`Datos del historial`}
      maxWidth="xl"
    >
      <Stack
        sx={{
          minWidth: "1000px",
          minHeight: "100px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {data && (
          <SimpleTable
            columns={transformDataSimpleTable([processExtra(data)]).columns}
            rows={transformDataSimpleTable([processExtra(data)]).rows}
            toolbar={true}
            exportButton={true}
          />
        )}
      </Stack>
    </Dialog>
  );
};

export default HistoryDetail;
