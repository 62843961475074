import { useContext, useEffect, useState } from "react";
import PipelinesStepperProvider from "../../../context/PipelinesStepperProvider";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import StepLayout from "../StepLayout";
import VirtualizatedTable from "../../virtualizedTable/VirtualizedTable";
import { GridColDef } from "@mui/x-data-grid";
import { transformData } from "../../virtualizedTable/transformData";
import { Button } from "../../buttons";
import ModifyColumnsModal from "./ModifyColumnsModal";
import useDialog from "../../../hooks/useDialog";
import { getRowByRowId, parseHeaderColumns, prepareValidations } from "../utils";
import useApi from "../../../hooks/useApi";
import { GET_FILE_INFO_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import CustomSkeleton from "../../skeleton/CustomSkeleton";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import ValidationsModal from "./ValidationsModal";
import TransformationsResume from "./TransformationsResume";
import EditPipelineWarningModal from "./EditPipelineWarningModal";
import HeaderByColumnModal from "./HeaderByColumnModal";

interface Step2Props {
  pipelineToEdit?: any;
}

const Step2 = ({ pipelineToEdit }: Step2Props) => {
  const { pipelineState, pipelineDispatch } = useContext<
    React.ContextType<typeof PipelinesStepperProvider>
  >(PipelinesStepperProvider);
  const [openConfirmResetColumnsModal, toggleConfirmResetColumnsModal] =
    useDialog();
  const [
    openConfirmSetHeaderWithPreviousSelectedColumns,
    toggleConfirmSetHeaderWithPreviousSelectedColumns,
  ] = useDialog();
  const [openConfirmSetHeader, toggleConfirmSetHeader] = useDialog();

  const {
    selected_sheet,
    file,
    header,
    rows_to_delete,
    data,
    newData,
    actualColumns: actualColumnsGlobal,
    actualRows: actualRowsGlobal,
    no_header_sheet,
    selectedColumns,
    validations,
    pipeline_to_edit_no_header_columns,
    replacedFile,
    showEditWarningPopUp,
  } = pipelineState;

  const {
    data: dataGetTable,
    isLoading: isLoadingGetTable,
    error: isErrorGetTable,
    callApi: getTable,
  } = useApi(
    GET_FILE_INFO_URL(file.id, selected_sheet.value, no_header_sheet),
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    data: dataGetTableForNoHeaderChanges,
    isLoading: isLoadingDataGetTableForNoHeaderChanges,
    callApi: getTableForNoHeaderChanges,
  } = useApi(
    GET_FILE_INFO_URL(file.id, selected_sheet.value, no_header_sheet),
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [columnsPropsArray, setColumnsPropsArray] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [actualRows, setActualRows] = useState<any[]>([]);
  const [actualColumns, setActualColumns] = useState<
    GridColDef<any, any, any>[]
  >([
    {
      field: "",
      headerName: "",
      width: 1500,
      renderCell: () => <></>,
    },
  ]);
  const [openModifyColumnsModal, toggleModifyColumnsModal] = useDialog();
  const [openValidationsModal, toggleValidationsModal] = useDialog();
  const [openEditWarningModal, toggleEditWarningModal] = useDialog();
  const [openHeaderByColumnModal, toggleHeaderByColumnModal] = useDialog();


  const handleOpenValidationsModal = () => {
    toggleValidationsModal();
    showEditPipelineWarningModal();
  };

  const renameColumns = (columns: any) => {
    if (header.data.length > 0) {
      const newValidations = validations.map((val: any) => {
        return { ...val, column: header.data[0][val.column] };
      });
      pipelineDispatch({
        type: "SET_VALIDATIONS",
        payload: newValidations,
      });
      const headerCopy = { ...header.data[0] };
      for (const clave in headerCopy) {
        const columnClave = headerCopy[clave];

        if (clave !== "id") {
          headerCopy[clave] = columns[columnClave];
        }
      }
      if (header.renombre?.length > 0) {
        const renombreCopy = { ...header.renombre[0] };
        for (const clave in renombreCopy) {
          const columnClave = renombreCopy[clave];

          if (clave !== "id") {
            renombreCopy[clave] = columns[columnClave];
          }
        }
        pipelineDispatch({
          type: "SET_HEADER",
          payload: {
            ...header,
            data: [headerCopy],
            renombre: [renombreCopy],
          },
        });
      } else {
        pipelineDispatch({
          type: "SET_HEADER",
          payload: {
            ...header,
            data: [headerCopy],
            renombre: [columns],
          },
        });
      }
      delete headerCopy.id;
      const { columns: newColumns } = transformData([headerCopy], headerCopy);
      setActualColumns(newColumns);
      pipelineDispatch({
        type: "SET_ACTUAL_COLUMNS",
        payload: newColumns,
      });
    } else {
      pipelineDispatch({
        type: "SET_HEADER",
        payload: {
          ...header,
          data: [columns],
          renombre: [columns],
        },
      });
      delete columns.id;
      const { columns: newColumns, rows: newRows } = transformData(
        [columns],
        columns
      );
      setActualColumns(newColumns);
      pipelineDispatch({
        type: "SET_ACTUAL_COLUMNS",
        payload: newColumns,
      });
    }
    if (selectedColumns.length > 0) {
      const newSelectedColumns = selectedColumns.map((c: any) => {
        return { columna: columns[c.value], alias: [] };
      });
      pipelineDispatch({
        type: "SET_SELECTED_COLUMNS",
        payload: newSelectedColumns,
      });
    }
    toggleModifyColumnsModal();
  };

  const handleResetColumns = () => {
    if (selectedColumns.length > 0) {
      pipelineDispatch({ type: "SET_SELECTED_COLUMNS", payload: [] });
    }
    if (header.data.length > 0 && header.id) {
      const row = parseHeaderColumns(getRowByRowId(header.id, rows));
      pipelineDispatch({
        type: "SET_HEADER",
        payload: {
          ...header,
          data: [row],
          renombre: [],
        },
      });
      const { columns: newColumns } = transformData(data, row);
      setActualColumns(
        newColumns.filter((column: any) => column.field !== "id")
      );
      pipelineDispatch({
        type: "SET_ACTUAL_COLUMNS",
        payload: newColumns,
      });
    } else {
      pipelineDispatch({
        type: "SET_HEADER",
        payload: {
          id: null,
          data: [],
          renombre: [],
          column: ""
        },
      });
      const { columns: newColumns } = transformData(data);
      setActualColumns(
        newColumns.filter((column: any) => column.field !== "id")
      );
      pipelineDispatch({
        type: "SET_ACTUAL_COLUMNS",
        payload: newColumns,
      });
    }
    toggleConfirmResetColumnsModal();
    toggleModifyColumnsModal();
  };

  useEffect(() => {
    if (!pipelineToEdit || replacedFile) {
      if (data.length === 0 || Object.keys(data).length === 0) {
        if (!isErrorGetTable && dataGetTable && dataGetTable?.body?.length) {
          const { columns, rows } = transformData(dataGetTable?.body);
          pipelineDispatch({
            type: "SET_DATA",
            payload: rows,
          });
          pipelineDispatch({
            type: "SET_ACTUAL_ROWS",
            payload: rows,
          });
          pipelineDispatch({
            type: "SET_ACTUAL_COLUMNS",
            payload: columns,
          });
          setRows(rows);
          setActualColumns(columns);
          setActualRows(rows);
        } else {
          setRows([]);
        }
      } else {
        const { columns, rows } = transformData(data);
        setRows(rows);

        pipelineDispatch({
          type: "SET_ACTUAL_COLUMNS",
          payload: columns.filter((column: any) => column.field !== "id"),
        });
        if (newData.length > 0) {
          setActualRows(newData);
          pipelineDispatch({
            type: "SET_ACTUAL_ROWS",
            payload: newData,
          });
        } else {
          setActualRows(data);
          pipelineDispatch({
            type: "SET_ACTUAL_ROWS",
            payload: data,
          });
        }
        if (header.data.length > 0 && header.id) {
          const row = header.data[0];
          const { columns: newColumns, rows: newRows } = transformData(
            newData,
            row
          );
          setActualColumns(
            newColumns.filter((column: any) => column.field !== "id")
          );
          pipelineDispatch({
            type: "SET_ACTUAL_COLUMNS",
            payload: newColumns.filter((column: any) => column.field !== "id"),
          });
        } else {
          if (header.data.length > 0) {
            const row = header.data[0];
            const { columns: newColumns, rows: newRows } = transformData(
              data,
              row
            );
            setActualColumns(
              newColumns.filter((column: any) => column.field !== "id")
            );
            pipelineDispatch({
              type: "SET_ACTUAL_COLUMNS",
              payload: newColumns.filter(
                (column: any) => column.field !== "id"
              ),
            });
          } else {
            const { columns } = transformData(data);
            setActualColumns(
              columns.filter((column: any) => column.field !== "id")
            );
            pipelineDispatch({
              type: "SET_ACTUAL_COLUMNS",
              payload: columns.filter((column: any) => column.field !== "id"),
            });
          }
        }
      }
    } else {
      // const { columns: columnsContext, rows: rowsContext } = transformData(data);
      setRows(newData?.length > 0 ? newData : data);
      setActualRows(newData?.length > 0 ? newData : data);
      setActualColumns(actualColumnsGlobal);
    }
  }, [dataGetTable]);

 /* useEffect(() => {
    if (dataGetTableForNoHeaderChanges && dataGetTableForNoHeaderChanges?.body?.length  ) {
      const { columns, rows } = transformData(dataGetTableForNoHeaderChanges?.body);
      pipelineDispatch({
        type: "SET_DATA",
        payload: rows,
      });
      pipelineDispatch({
        type: "SET_ACTUAL_ROWS",
        payload: rows,
      });
      pipelineDispatch({
        type: "SET_ACTUAL_COLUMNS",
        payload: columns,
      });
      setRows(rows);
      setActualColumns(columns);
      setActualRows(rows);
    } else {
      setRows([]);
    }
    pipelineDispatch({
      type: "SET_HEADER",
      payload: {
        id: null,
        data: [],
        renombre: [],
        column: ""
      },
    });
    pipelineDispatch({
      type: "SET_ROWS_TO_DELETE",
      payload: [],
    });

    pipelineDispatch({
      type: "SET_VALIDATIONS",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_SELECTED_COLUMNS",
      payload: [],
    });

  }, [dataGetTableForNoHeaderChanges]);*/

  useEffect(() => {

    if ((data.length === 0 && !pipelineToEdit) || replacedFile) {
      getTable(
        `${GET_FILE_INFO_URL(file.id, selected_sheet.value, no_header_sheet)}&carga_datos_detalle=true`
      );
    }
  }, []);

  /*useEffect(() => { 
    data.length !== 0 && getTableForNoHeaderChanges(
        `${GET_FILE_INFO_URL(file.id, selected_sheet.value, no_header_sheet)}&carga_datos_detalle=true`
      );    
  }, [no_header_sheet]);*/

  //Este use effect es para resetear el estado de la tabla cuando el usuario hace click en el botón de "Deshacer todo"
  useEffect(() => {
    if (data.length > 0 && actualColumnsGlobal.length === 0) {
      const { columns, rows } = transformData(data);
      setActualColumns(columns.filter((column: any) => column.field !== "id"));
      setActualRows(rows);
      pipelineDispatch({
        type: "SET_ACTUAL_ROWS",
        payload: rows,
      });
      pipelineDispatch({
        type: "SET_ACTUAL_COLUMNS",
        payload: columns.filter((column: any) => column.field !== "id"),
      });
    }
  }, [actualColumnsGlobal, actualRowsGlobal]);

  const handleDeleteRows = () => {
    showEditPipelineWarningModal();
    setActualRows(
      actualRows.filter((row) => !selectedRows.some((item) => item === row.id))
    );
    pipelineDispatch({
      type: "SET_ACTUAL_ROWS",
      payload: actualRows.filter(
        (row) => !selectedRows.some((item) => item === row.id)
      ),
    });
    pipelineDispatch({
      type: "SET_NEW_DATA",
      payload: actualRows.filter(
        (row) => !selectedRows.some((item) => item === row.id)
      ),
    });
    if (header.id !== null) {
      pipelineDispatch({
        type: "SET_ROWS_TO_DELETE",
        payload: {
          ...rows_to_delete,
          rows_to_delete_after_header_setting: selectedRows,
        },
      });
    } else {
      pipelineDispatch({
        type: "SET_ROWS_TO_DELETE",
        payload: {
          ...rows_to_delete,
          rows_to_delete_before_header_setting: selectedRows,
        },
      });
    }
  };

  const undoDeleteRows = () => {
    if (header.data.length > 0 && header.id !== null) {
      setActualRows(
        rows.filter((row) => {
          return (
            parseInt(row.id) > parseInt(header.id) &&
            !rows_to_delete.rows_to_delete_before_header_setting.includes(
              row.id
            )
          );
        })
      );
      pipelineDispatch({
        type: "SET_ACTUAL_ROWS",
        payload: rows.filter(
          (row) =>
            row.id > header.id &&
            !rows_to_delete.rows_to_delete_before_header_setting.includes(
              row.id
            )
        ),
      });
      pipelineDispatch({
        type: "SET_NEW_DATA",
        payload: rows.filter(
          (row) =>
            row.id > header.id &&
            !rows_to_delete.rows_to_delete_before_header_setting.includes(
              row.id
            )
        ),
      });
      pipelineDispatch({
        type: "SET_ROWS_TO_DELETE",
        payload: {
          ...rows_to_delete,
          rows_to_delete_after_header_setting: [],
        },
      });
    } else {
      setActualRows(rows);
      pipelineDispatch({
        type: "SET_ACTUAL_ROWS",
        payload: rows,
      });

      pipelineDispatch({
        type: "SET_NEW_DATA",
        payload: rows,
      });
      pipelineDispatch({
        type: "SET_ROWS_TO_DELETE",
        payload: {
          rows_to_delete_before_header_setting: [],
          rows_to_delete_after_header_setting: [],
        },
      });
    }
  };
  const handleSetColumnsHeader = () => {
    showEditPipelineWarningModal();
    selectedColumns.length === 0
      ? toggleConfirmSetHeader()
      : toggleConfirmSetHeaderWithPreviousSelectedColumns();
  };

  const handleSetHeaderByColumn = () => { 
    toggleHeaderByColumnModal()
   };

  const setColumnsHeader = () => {
    if (selectedColumns.length > 0) {
      pipelineDispatch({ type: "SET_SELECTED_COLUMNS", payload: [] });
      toggleConfirmSetHeaderWithPreviousSelectedColumns();
    } else {
      toggleConfirmSetHeader();
    }
    const row =
      selectedRows.length === 1 &&
      parseHeaderColumns(getRowByRowId(selectedRows[0], actualRows));

    const rowWithoutId = Object.keys(row).reduce((object: any, key: any) => {
      if (key !== "id") {
        object[key] = row[key];
      }
      return object;
    }, {});
    pipelineDispatch({
      type: "SET_VALIDATIONS",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_HEADER",
      payload: {
        ...header,
        id: selectedRows[0],
        data: [rowWithoutId],
        renombre: [],
      },
    });
    const { columns: newColumns, rows: newRows } = transformData(
      newData.length > 0 && pipelineToEdit ? newData : data,
      row
    );

    setActualColumns(newColumns.filter((column: any) => column.field !== "id"));
    pipelineDispatch({
      type: "SET_ACTUAL_COLUMNS",
      payload: newColumns.filter((column: any) => column.field !== "id"),
    });

    const newFilteredRows =
      rows_to_delete.rows_to_delete_before_header_setting?.length > 0
        ? newRows.filter(
            (row: any) =>
              parseInt(row.id) > parseInt(selectedRows[0]) &&
              !rows_to_delete.rows_to_delete_before_header_setting.includes(
                row.id
              )
          )
        : newRows.filter(
            (row: any) => parseInt(row.id) > parseInt(selectedRows[0])
          );
    setActualRows(newFilteredRows);
    pipelineDispatch({
      type: "SET_ACTUAL_ROWS",
      payload: newFilteredRows,
    });
    pipelineDispatch({
      type: "SET_NEW_DATA",
      payload: newFilteredRows,
    });
  };

  const changeColumnsNames = () => {
    showEditPipelineWarningModal();
    toggleModifyColumnsModal();
  };

  const getColumnsNames = () => {
    return header.data.length > 0
      ? Object.keys(header.data[0])
          .filter((key) => key !== "id" && key !== "indice")
          .map((key) => header.data[0][key])
      : pipelineToEdit && newData && newData.length > 0
      ? Object?.keys(newData[0])?.filter(
          (key) => key !== "indice" && key !== "id"
        )
      : data && data.length > 0
      ? Object?.keys(data[0])?.filter((key) => key !== "indice" && key !== "id")
      : dataGetTable &&
        dataGetTable.body &&
        dataGetTable.body.length > 0 &&
        Object?.keys(dataGetTable.body[0])?.filter(
          (key) => key !== "indice" && key !== "id"
        );
  };

  const getColumnsValidationsNames = () => {
    return header.data.length > 0
      ? header.data[0]
      : data && data.length > 0
      ? data[0]
      : dataGetTable &&
        dataGetTable.body &&
        dataGetTable.body.length > 0 &&
        dataGetTable.body[0];
  };

  const handleAcceptValidations = (validations: any[]) => {
    pipelineDispatch({
      type: "SET_VALIDATIONS",
      payload: validations,
    });
    toggleValidationsModal();
  };

  const handleAcceptEditWarning = () => {
    toggleEditWarningModal();
    pipelineDispatch({
      type: "SET_SHOW_EDIT_WARNING_POP_UP",
      payload: false,
    });
    pipelineDispatch({
      type: "SET_UNDO_ALL",
      payload: true,
    });
    pipelineDispatch({
      type: "SET_NEW_DATA",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_HEADER",
      payload: {
        id: null,
        data: [],
        renombre: [],
        column: ""
      },
    });
    pipelineDispatch({
      type: "SET_ROWS_TO_DELETE",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_ACTUAL_COLUMNS",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_ACTUAL_ROWS",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_VALIDATIONS",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_SELECTED_COLUMNS",
      payload: [],
    });
  };

  const handleCloseEditWarning = () => {
    toggleEditWarningModal();
    pipelineDispatch({
      type: "SET_SHOW_EDIT_WARNING_POP_UP",
      payload: false,
    });
  };

  const showEditPipelineWarningModal = () => {
    if (showEditWarningPopUp && pipelineToEdit) {
      toggleEditWarningModal();
    }
  };

  return (
    <StepLayout stepTitle="Transformaciones del archivo">
      <Stack width="100%" height="100%">
        {pipelineToEdit && (
          <Stack
            sx={{ flexDirection: "row", width: "100%", alignItems: "center" }}
          >
            {" "}
            <Typography
              sx={{
                color: "var(--blue)",
                marginRight: "auto",
                fontWeight: "bold",
                width: "40%",
              }}
            >
              IMPORTANTE: Deshacer todos los cambios antes de comenzar a editar
              un pipeline para evitar errores.
            </Typography>
            <TransformationsResume config={pipelineToEdit} />
          </Stack>
        )}
        {replacedFile && (
          <Typography
            sx={{
              color: "var(--blue)",
              marginRight: "auto",
              fontWeight: "bold",
              width: "40%",
            }}
          >
            * Se muestran los datos originales del archivo reemplazado sin las
            transformaciones
          </Typography>
        )}

        {(pipelineToEdit &&
          (pipelineToEdit?.muestra_transformada?.length === 0 ||
            !pipelineToEdit.muestra ||
            !Array.isArray(pipelineToEdit?.muestra))) ||
        !file.id ? (
          pipelineToEdit?.muestra_transformada?.length === 0 ? (
            <Typography
              sx={{
                color: "var(--red)",
                margin: "auto !important",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              El pipeline es muy antiguo o fue reemplazado con un archivo
              distinto al del pipeline original. Debe crear un nuevo pipeline.
            </Typography>
          ) : (
            <Typography
              sx={{
                color: "var(--red)",
                margin: "auto !important",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              No es posible editar el pipeline porque el archivo utilizado ya no
              existe.
            </Typography>
          )
        ) : (
          <>
            {" "}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  marginLeft: "4px",
                  marginBottom: "3px",
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "var(--blue)",
                }}
              >
                Se muestran las primeras veinticinco y las últimas cinco filas
                del archivo.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  title="Validaciones"
                  color="blue-greeny"
                  type="button"
                  onClick={handleOpenValidationsModal}
                  disabled={isLoadingGetTable}
                />
                <Tooltip
                  title={
                    selectedRows.length !== 1
                      ? "Debe seleccionar sólo una fila"
                      : "Determina qué fila se convertirá en cabecera de las columnas"
                  }
                  arrow
                >
                  <span>
                    <Button
                      title="Setear cabecera manual"
                      color="blue"
                      type="button"
                      onClick={handleSetColumnsHeader}
                      disabled={
                        selectedRows.length !== 1 ||
                        selectedRows?.includes(
                          actualRows[actualRows.length - 1].id
                        ) ||
                        isLoadingGetTable
                      }
                    />
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                     "Determina qué fila se convertirá en cabecera en base a una columna a buscar"
                  }
                  arrow
                >
                  <span>
                    <Button
                      title="Setear cabecera por columna"
                      color="blue-greeny"
                      type="button"
                      onClick={handleSetHeaderByColumn}
                      width={200}
                    />
                  </span>
                </Tooltip>
                <Button
                  title="Renombrar columnas"
                  color="light-blue"
                  type="button"
                  onClick={() => changeColumnsNames()}
                  disabled={isLoadingGetTable || actualColumns.length < 1}
                />
                <Button
                  title={
                    (rows_to_delete?.rows_to_delete_before_header_setting
                      ?.length > 0 &&
                      !(header.id !== null)) ||
                    (rows_to_delete?.rows_to_delete_after_header_setting
                      ?.length > 0 &&
                      header.id !== null)
                      ? "Deshacer eliminar filas"
                      : "Eliminar filas"
                  }
                  color="grey"
                  type="button"
                  disabled={
                    (rows_to_delete?.rows_to_delete_before_header_setting
                      ?.length === 0 &&
                      !(header.id !== null) &&
                      selectedRows.length < 1) ||
                    (rows_to_delete?.rows_to_delete_after_header_setting
                      ?.length === 0 &&
                      header.id !== null &&
                      selectedRows.length < 1) ||
                    actualRows.length === selectedRows?.length ||
                    isLoadingGetTable
                  }
                  onClick={
                    (rows_to_delete?.rows_to_delete_before_header_setting
                      ?.length > 0 &&
                      !(header.id !== null)) ||
                    (rows_to_delete?.rows_to_delete_after_header_setting
                      ?.length > 0 &&
                      header.id !== null)
                      ? undoDeleteRows
                      : handleDeleteRows
                  }
                />
              </Box>
            </Box>
            {isLoadingGetTable ? (
              <Box sx={{ width: "98%" }}>
                <CustomSkeleton height={5} />
              </Box>
            ) : (
              actualColumns && actualRows &&
              <VirtualizatedTable
                columns={actualColumns && actualColumns}
                rows={actualRows && actualRows}
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={35}
                tableHeight={400}
                hideFooter={true}
                toolbar={false}
                columnsButton={false}
                filterButton={false}
                densitySelector={false}
                exportButton={false}
                setSelectedRows={setSelectedRows}
                setColumnsPropsArray={setColumnsPropsArray}
              />
            )}
            <ModifyColumnsModal
              open={openModifyColumnsModal}
              handleClose={toggleModifyColumnsModal}
              handleAccept={renameColumns}
              columns={getColumnsNames()}
              toggleConfirmResetColumnsModal={toggleConfirmResetColumnsModal}
              oldHeader={pipelineToEdit?.renombre_cabecera}
            />
            <ValidationsModal
              open={openValidationsModal}
              handleClose={toggleValidationsModal}
              handleAccept={handleAcceptValidations}
              columns={getColumnsValidationsNames()}
              originalValidations={pipelineToEdit && prepareValidations(pipelineToEdit?.validacion_columnas_vacias, pipelineToEdit?.validacion_columnas_tipo_dato)}
            />
            <EditPipelineWarningModal
              open={openEditWarningModal}
              handleClose={handleCloseEditWarning}
              handleAccept={handleAcceptEditWarning}
            />
            <HeaderByColumnModal
            open={openHeaderByColumnModal}
            handleClose={toggleHeaderByColumnModal}
            handleAccept={toggleHeaderByColumnModal}
            isPipeline={true}
            />

            <ConfirmationModal
              open={openConfirmResetColumnsModal}
              handleClose={toggleConfirmResetColumnsModal}
              handleAccept={handleResetColumns}
              title="Restablecer columnas"
              message="restablecer las columnas"
            />
            <ConfirmationModal
              open={openConfirmSetHeaderWithPreviousSelectedColumns}
              handleClose={toggleConfirmSetHeaderWithPreviousSelectedColumns}
              handleAccept={setColumnsHeader}
              title="Setear cabecera manual"
              message="¿Confirma que desea restablecer la cabecera? Será necesario que elijas nuevamente las columnas a conservar en el paso 3"
              customMessage
            />
            <ConfirmationModal
              open={openConfirmSetHeader}
              handleClose={toggleConfirmSetHeader}
              handleAccept={setColumnsHeader}
              title="Setear cabecera"
              message="¿Confirma que desea establecer una nueva cabecera? Será necesario que configure nuevamente las validaciones si ya las habia configurado ya que estas se eliminarán"
              customMessage
            />
          </>
        )}
      </Stack>
    </StepLayout>
  );
};
export default Step2;
