import { Stack } from "@mui/material";
import useDialog from "../../hooks/useDialog";
import { Button } from "../buttons";
import Dialog from "../dialog/Dialog";
import Configs from "./Configs";

interface IConfigsModal {
  refetchTable: () => void;
}

const ConfigsModal = ({ refetchTable }: IConfigsModal) => {
  const [openConfigModal, toggleConfigModal] = useDialog();

  const handleAccept = () => {
    toggleConfigModal();
    refetchTable();
  };

  return (
    <>
      <Stack sx={{ marginLeft: "auto" }}>
        <Button
          title="Configuración"
          type="button"
          onClick={toggleConfigModal}
          disabled={false}
          color="blue"
        />
      </Stack>
      <Dialog
        open={openConfigModal}
        handleClose={toggleConfigModal}
        maxWidth="xl"
        title={"Editar configuración"}
      >
        <Stack sx={{ width: "80vw" }}>
          <Configs handleAccept={handleAccept} />
        </Stack>
      </Dialog>
    </>
  );
};

export default ConfigsModal;
