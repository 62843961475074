import {
  Box,
  Chip,
  Collapse,
  InputLabel,
  Typography,
} from "@mui/material";

import ShadowBox from "../flowConfiguration/Modals/ShadowBox";
import { useEffect, useRef, useState } from "react";
import { form_label } from "../../styles/app-styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { selectStyles } from "../../styles/select.styles";
import Select from "../forms/Select";
import { formatFileOptions} from "./utils"
import { ActionMeta } from "react-select";
import { IdOption, IsMulti, OnChangeValue } from "../forms/types";
import useMultiSelect from "../../hooks/useMultiSelect";

interface IFileConfig {
  allowedFile: string
  fileState: any[]
  setFilesState: (payload: any)=> void
  fileOptions: any[]
  filesSelectedOptionParam?: any
}

const FileConfig = ({allowedFile, setFilesState, fileState, fileOptions, filesSelectedOptionParam }: IFileConfig) => {
  const [openSection, setOpenSection] = useState(true);
  const [delayHelper, setDelayHelper] = useState<boolean>(false);
  const filesRef: any = useRef(null);
  const [filesNames, setFilesNames] = useState<any>([]);

 
  const handleDelete = (tagToDelete: string) => {
    setFilesNames(filesNames.filter((tag: string) => tag !== tagToDelete));
    setFilesState(fileState.map((file: any)=>{return file.file === allowedFile ? {...file, filesNames: filesNames.filter((tag: string) => tag !== tagToDelete)} :file}))
  };

  const [
    filesSelectedOption,
    setFilesSelectedOption,
    filesChangeHandler,
  ] = useMultiSelect(filesSelectedOptionParam?.map((file: any)=> {return {...file,value: file.nombre, label: file.nombre}}));

  const handleChangeFiles = (newValue: OnChangeValue<IdOption, IsMulti>, actionMeta: ActionMeta<IdOption>) => {
    filesChangeHandler(newValue, actionMeta);
    setFilesState(fileState?.map((file: any)=> {return file.file === allowedFile ? {...file, files: newValue} : file}));
  };

    
 useEffect(() => {
  if(filesSelectedOptionParam){
    setDelayHelper(false);

   setFilesSelectedOption(filesSelectedOptionParam?.map((file: any)=> {return {...file,value: file.nombre, label: file.nombre}}))
  }
  setTimeout(() => {
    setDelayHelper(true);
  }, 100);
 }, [filesSelectedOptionParam]);


  return (
    <>
      <Typography
        sx={{
          fontSize: "22px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          marginTop: "15px",
          padding: "8px",
          backgroundColor: "var(--blue)",
          fontWeight: "550",
          color: "white",
          borderRadius: "5px",
        }}
        onClick={() => {
          setOpenSection(!openSection);
        }}
      >
        {allowedFile}
        {openSection ? (
          <ExpandLess sx={{ fontSize: "30px" }} />
        ) : (
          <ExpandMore sx={{ fontSize: "30px" }} />
        )}
      </Typography>
      <Collapse in={openSection} timeout={10} sx={{ width: "100%" }}>
        <ShadowBox margin>
          <Box sx={{ width: "50%", marginBottom: "20px" }}>
            <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
              Archivos:
            </InputLabel>
            {delayHelper &&  <Select
              reference={filesRef}
              styles={selectStyles(filesSelectedOption)}
              options={formatFileOptions(fileOptions)?.filter((file: any)=> !filesSelectedOption?.map((f: any)=> f.id).includes(file.value))}
              name="files"
              onChange={handleChangeFiles}
              closeMenuOnSelect={false}
              placeholder="Seleccione los archivos"
              isMulti
              isClearable
              defaultValue={filesSelectedOption}
            />}
           
            <Box mt={2}>
              {filesNames.map((tag: string, index: number) => (
                <Box key={index} display="inline-flex" alignItems="center">
                    <Box sx={{ margin: "0px 8px" }}>
                      <Chip label={tag} onDelete={() => handleDelete(tag)} />
                    </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </ShadowBox>
      </Collapse>
    </>
  );
};
export default FileConfig;
