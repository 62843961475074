import Stack from "@mui/material/Stack";
import {
  GridFilterOperator,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState, memo } from "react";
import { getUniqueValues } from "../../utils/util";
import CustomNoRowsOverlay from "../tablesTools/NoRows";
import CustomToolbar from "../tablesTools/CustomToolbar";
import ChangeStateModal from "./ChangeStateModal";
import ChangeLocationModal from "./ChangeLocationModal";
import DetailModal from "./DetailModal";


interface SimpleTableProps {
  rows: any;
  columns: any;
  toolbar?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  densitySelector?: boolean;
  exportButton?: boolean;
  filterModel?: any;
  setFilterModel?: any;
  openModal: boolean
  openLocationModal: boolean
  handleAcceptModal: (state: string)=> void
  handleCloseModal: ()=> void
  selectedPeriod: string
  periodLocation: string
  handleAcceptLocationModal: (location: string)=>void
  handleCloseLocationModal: ()=> void
  openDetailModal: boolean
  handleCloseDetailModal: ()=> void
  detail: any
}

const ControlTable = ({
  rows,
  columns,
  toolbar,
  columnsButton,
  filterButton,
  densitySelector,
  exportButton,
  filterModel,
  setFilterModel,
  openModal,
  openLocationModal,
  handleAcceptModal,
  handleCloseModal,
  selectedPeriod,
  periodLocation,
  handleAcceptLocationModal,
  handleCloseLocationModal,
  openDetailModal,
  handleCloseDetailModal,
  detail
}: SimpleTableProps) => {
  const AutoCompleteFilterTextField =  memo((props: any) => {
    const { item, applyValue, focusElementRef } = props;
    const [inputValue, setInputValue] = useState<string>(item.value);

    useEffect(() => {
      if (focusElementRef && focusElementRef.current) {
        focusElementRef.current.focus();
      }
    }, [focusElementRef]);

    // Obtener valores únicos de la columna
    const uniqueValues: any = getUniqueValues(item.columnField, rows);

      // Filtrar los valores únicos basados en el texto del input
  const filteredUniqueValues = inputValue ? uniqueValues.filter((value: any) =>
    value?.toLowerCase().includes(inputValue?.toLowerCase()) 
  ) : uniqueValues;

    const handleChange = (event: any, newValue: string | null) => {
      if (newValue !== null && newValue !== item.value) {
        applyValue({ ...item, value: newValue });
        setInputValue(newValue)
      }
    };

    return (
      <Autocomplete
        options={filteredUniqueValues}
        value={inputValue}
        onInputChange={(handleChange)}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Valor del filtro"
            variant="standard"
            size="small"
            placeholder="Valor del filtro"
            inputRef={focusElementRef}
          />
        )}
      />
    );
  });



  const operators: GridFilterOperator[] = [
    //...getGridStringOperators(),
    ...getGridStringOperators().filter((operator)=> operator.value !== "isAnyOf").map((operator) => ({
      ...operator,
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    })),
    ...getGridStringOperators().filter((operator)=> operator.value === "isAnyOf"),
    {
      label: "no es igual",
      value: "no_es_igual",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return params.value.toString() !== filterItem.value.toString();
        };
      },
      InputComponent: AutoCompleteFilterTextField

    },
    {
      label: "no contiene",
      value: "no_contiene",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return !params.value.toString().includes(filterItem.value.toString());
        };
      },
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    },
    {
      label: "no comienza con",
      value: "no_comienza_con",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return !params.value
            .toString()
            .startsWith(filterItem.value.toString());
        };
      },
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    },
    {
      label: "no termina con",
      value: "no_termina_con",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return !params.value.toString().endsWith(filterItem.value.toString());
        };
      },
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    },
  ];

  const numericOperators: GridFilterOperator[] = [
    {
      label: "es mayor",
      value: "es_mayor",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return parseInt(params.value) > parseInt(filterItem.value);
        };
      },
      InputComponent: AutoCompleteFilterTextField
    },
    {
      label: "es mayor o igual",
      value: "es_mayor_o_igual",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return parseInt(params.value) >= parseInt(filterItem.value);
        };
      },
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    },
    {
      label: "es menor",
      value: "es_menor",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return parseInt(params.value) < parseInt(filterItem.value);
        };
      },
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    },
    {
      label: "es menor o igual",
      value: "es_menor_o_igual",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return parseInt(params.value) <= parseInt(filterItem.value);
        };
      },
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    },
    {
      label: "no es cero",
      value: "no_es_cero",
      getApplyFilterFn: (filterItem: any) => {
        return (params: any): boolean => {
          return parseInt(params.value) !== 0;
        };
      },
      requiresFilterValue: false,
    },
    ...operators,
  ];



  return (
    <Stack style={{ height: "480px", width: "100%" }}>
      <DataGridPro
        rows={rows}
        columns={columns.map((col: any) => {
          return {
            ...col,
            filterOperators:
              col.type !== "decimal" && col.type != "entero"
                ? operators
                : numericOperators,
          };
        })}
        components={{
          Toolbar: () => (
            <CustomToolbar
              toolbar={true}
              columnsButton={columnsButton}
              filterButton={filterButton}
              densitySelector={densitySelector}
              exportButton={true}
            />
          ),
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        disableColumnMenu={true}
        hideFooter={true}
        checkboxSelection={false}
        disableSelectionOnClick
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        sx={{
          boxShadow: 3,
          fontSize: "12px",
          padding: "0 10px 10px 10px",
        }}
      />
      <ChangeStateModal open={openModal} handleClose={handleCloseModal} handleAccept={handleAcceptModal} period={selectedPeriod}/>
      <ChangeLocationModal open={openLocationModal} handleClose={handleCloseLocationModal} handleAccept={handleAcceptLocationModal} periodLocation={periodLocation}/>
      <DetailModal open={openDetailModal} handleClose={handleCloseDetailModal} data={detail}/>
    </Stack> 
  );
};

export default ControlTable;
