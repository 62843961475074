import LanIcon from "@mui/icons-material/Lan";
import BallotIcon from "@mui/icons-material/Ballot";
import ShopIcon from "@mui/icons-material/Shop";
import TableChartIcon from "@mui/icons-material/TableChart";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SchemaIcon from '@mui/icons-material/Schema';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HistoryIcon from "@mui/icons-material/History";

export const isOptionSelected = (page: string) => {
  if (page === "Administración de datos" && (location.pathname === "/administracion-datos" ||
  location.pathname === "/")) {
    return true;
  }
  if (
    page === "Consolidación de archivos" &&
    (location.pathname === "/consolidacion-archivos/administracion-pipelines" ||
      location.pathname === "/consolidacion-archivos/administracion-esquemas" ||
      location.pathname === "/consolidacion-archivos/configuracion-pipelines" ||
      location.pathname === "/consolidacion-archivos/configuracion-esquemas" ||
      location.pathname === "/consolidacion-archivos/ejecucion-esquema")
  ) {
    return true;
  }
  if (
    page === "Reporte de base" &&
    (location.pathname.startsWith("/reporte-base"))) {
    return true;
  }
  if (page === "Flujos" && (location.pathname === "/administracion-flujos" || location.pathname === "/ejecucion-flujos")  ) {
    return true;
  }
  if (page === "Gestor de tareas" && location.pathname === "/gestor-tareas") {
    return true;
  }
  if(page === "Control de archivos"&& location.pathname === "/control-archivos"){
    return true
  }
  if(page === "Historial de acciones"&& location.pathname === "/historial"){
    return true
  }
};

export const menuItemsOptions = [
  {
    title: "Administración de datos",
    url: "administracion-datos",
    icon: UploadFileIcon,
  },
  {
    title: "Control de archivos",
    url: "control-archivos",
  icon: FactCheckIcon
  },
  {
    title: "Consolidación de archivos",
    url: "",
    icon: DriveFileMoveIcon,
  },
  {
    title: "Reporte de base",
    url: "",
    icon: AssignmentIcon,
  },
  {
    title: "Flujos",
    url:"",
    icon: SchemaIcon,
  },
  {
    title: "Gestor de tareas",
    url: "gestor-tareas",
    icon: ShopIcon,
  },
  {
    title: "Historial de acciones",
    url: "historial",
    icon: HistoryIcon,
  },
 
];

export const fileConsolidationCollapseList = [
  {
    icon: LanIcon,
    title: "Administración de pipelines",
    url: "/consolidacion-archivos/administracion-pipelines",
  },
  {
    icon: BallotIcon,
    title: "Administración de esquemas",
    url: "/consolidacion-archivos/administracion-esquemas",
  },
  {
    icon: ShopIcon,
    title: "Ejecución de esquema",
    url: "/consolidacion-archivos/ejecucion-esquema",
  },
];

export const reportBaseCollapseList = [
  {
    icon: AccountTreeIcon,
    title: "Validación de códigos",
    url: "/reporte-base/administracion-validaciones",
  },
  {
    icon: TableChartIcon,
    title: "Resumen de información",
    url: "/reporte-base/administracion-resumenes",
  },
];

export const flowsCollapseList = [
  {
    url:"/administracion-flujos",
    icon: LanIcon,
    title: "Administración de flujos",

  },
  /*{
    icon: PlayCircleIcon,
    title: "Ejecución de flujos",
    url:"/ejecucion-flujos",
  }*/
];

export const redirectMenuItem = (url: string, navigate: any, handleDrawerClose: any) => {
  url !== "" && handleDrawerClose();
  url !== "" ? navigate(`/${url}`) : undefined;
};
