import Dialog from "../dialog/Dialog";
import Stack from "@mui/material/Stack";
import { Button } from "../buttons";
import {  useState } from "react";
import { InputLabel, TextField } from "@mui/material";
import { form_label } from "../../styles/app-styles";

interface FrecuencyModalProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: (state: string) => void;
}

const FrecuencyModal = ({
  open,
  handleClose,
  handleAccept,
}: FrecuencyModalProps) => {
  const [numberOfMonths, setNumberOfMonths] = useState("");

  const handleChange = (event: any) => {
    setNumberOfMonths(event.target.value);
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      maxWidth="md"
      title={"Elegir cantidad de meses"}
    >
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          width: "400px",
          alignItems: "center",
          minHeight: "200px",
          justifyContent: "space-evenly",
        }}
      >
        <InputLabel
          sx={{ ...form_label, marginBottom: "5px", fontSize: "18px" }}
        >
          Cantidad de meses
        </InputLabel>
        <TextField
          label="Escribe la cantidad de meses"
          variant="outlined"
          value={numberOfMonths}
          onChange={handleChange}
          fullWidth
          sx={{ marginRight: "10px", width: "90%" }}
        />

        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Cancelar"
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={() => handleAccept(numberOfMonths)}
            color="blue-greeny"
            disabled={numberOfMonths === ""}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default FrecuencyModal;
