import Dialog from "../dialog/Dialog";
import Stack from "@mui/material/Stack";
import { Button } from "../buttons";
import { useEffect, useState } from "react";
import { InputLabel } from "@mui/material";
import { form_label } from "../../styles/app-styles";
import ExploreFolders from "../dataManagement/UploadFileModal/ExploreFolders";

interface ChangeLocationProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: (state: string) => void;
  periodLocation: string;
}

const ChangeLocationModal = ({
  open,
  handleClose,
  handleAccept,
  periodLocation,
}: ChangeLocationProps) => {
  const [location, setLocation] = useState<string>(
   (periodLocation?.endsWith("/") ? periodLocation : periodLocation+"/" )
  );

  const handleChangeSelectedFolder = (location: string) => {
    if (location === "") {
      setLocation("/");
    } else {
      setLocation(location);
    }
  };

    

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      maxWidth="xl"
      title={"Editar ruta"}
    >
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          width: "900px",
          alignItems: "center",
          minHeight: "200px",
          justifyContent: "space-evenly",
        }}
      >
        <InputLabel
          sx={{ ...form_label, marginBottom: "5px", fontSize: "18px" }}
        >
          {`Ruta del directorio:`}{" "}
        </InputLabel>
        <ExploreFolders
          handleChangeSelectedFolder={handleChangeSelectedFolder}
          customFolder={periodLocation === "/" ? "" : (!periodLocation?.endsWith("/") ? periodLocation+"/" :periodLocation)}
        />
        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Cancelar"
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={() => handleAccept(location as string)}
            color="blue-greeny"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ChangeLocationModal;
