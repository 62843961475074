import { getIdPeriodsByYear, filterDatesWithExpense, Periods } from "./utils";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useLanguage from "../../context/LanguageProvider";
import {
  filterPeriods,
  getTotalPeriods,
  removeDuplicates,
  isIdPeriodsByYearInArray,
  removeDuplicatesString,
  repeatArray,
} from "./utils";
import ListYearItem from "./ListYearItem";
import ListMonthsItems from "./ListMonthsItems";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 270,
    },
  },
};

type DatesType =
  | {
      id: number;
      fecha: string; //debe venir en formato "SUBITEM-ITEM" ej: "columna1-GASTOS", "02-2022"
    }[]
  | [];
interface IMultipleSelectCheckmarks {
  dates: DatesType;
  selectedPeriods: number[];
  setSelectedPeriods: (selectedPeriods: number[]) => void;
}

export default function MultipleSelectCheckmarks({
  dates,
  selectedPeriods,
  setSelectedPeriods,
}: IMultipleSelectCheckmarks) {
  const totalYears =
    (dates && dates.length > 0 && getTotalPeriods(dates as Periods)) || [];

  const [selectedYears, setSelectedYears] = useState<string[]>([]);
  const [collapsedYears, setCollapsedYears] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedPeriods>) => {
    const newValue = event.target.value as number[];
    setSelectedPeriods([...newValue]);
  };

  useEffect(() => {
    let newYears: string[] = [...selectedYears];
    totalYears.forEach((year: string) => {
      if (isIdPeriodsByYearInArray(year, dates as Periods, selectedPeriods)) {
        newYears.push(year);
      } else {
        const newSelectedYears = newYears.filter((item) => item !== year);
        newYears = newSelectedYears;
      }
      setSelectedYears(removeDuplicatesString(newYears));
    });
  }, [selectedPeriods]);

  const yearHandleChange = (yearItem: string) => {
    setSelectedYears(
      selectedYears.includes(yearItem)
        ? selectedYears.filter((year: string) => year !== yearItem)
        : [...selectedYears, yearItem]
    );
    setSelectedPeriods(
      selectedYears.includes(yearItem)
        ? selectedPeriods.filter(
            (period: number) =>
              !getIdPeriodsByYear(yearItem, dates as Periods).includes(period)
          )
        : removeDuplicates([
            ...selectedPeriods,
            ...getIdPeriodsByYear(yearItem, dates as Periods),
          ])
    );
  };

  const handleToggleCollapseYear = (yearItem: string) => {
    setCollapsedYears(
      collapsedYears.includes(yearItem)
        ? collapsedYears.filter((year: string) => year !== yearItem)
        : [...collapsedYears, yearItem]
    );
  };


  return (
    <FormControl sx={{ m: 1, width: 500, margin: 0 }}>
      {dates && dates.length > 0 && (
        <>
          <InputLabel sx={{ top: "-2px", zIndex: 0 }}>
            {"Seleccione los períodos"}
          </InputLabel>

          <Select
            labelId="demo-multiple-checkbox-label"
            multiple
            value={selectedPeriods}
            onChange={(e) => handleChange(e)}
            renderValue={(selected) =>
              selected.map((id: number, index: number) =>
                filterPeriods(dates as Periods, "ID", id)[0]
                  ? filterPeriods(dates as Periods, "ID", id)[0].fecha +
                    (index !== selected.length - 1 ? ", " : "")
                  : ""
              )
            }
            MenuProps={MenuProps}
            sx={{
              height: "45px",
              verticalAlign: "center",
            }}
          >
            {repeatArray(totalYears).map((yearItem: string, index: number) => {
              if (index % 2 === 0) {
                return (
                  <ListYearItem
                    key={yearItem + index}
                    yearItem={yearItem}
                    yearHandleChange={yearHandleChange}
                    selectedYears={selectedYears}
                    handleToggleCollapseYear={handleToggleCollapseYear}
                  />
                );
              } else {
                return collapsedYears.includes(yearItem)
                  ? ListMonthsItems(dates as Periods, selectedPeriods, yearItem)
                  : null;
              }
            })}
          </Select>
        </>
      )}
    </FormControl>
  );
}
