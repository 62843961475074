import { Box, Stack, Typography } from "@mui/material";
import {  useEffect, useState } from "react";
import Dialog from "../dialog/Dialog";
import { Button } from "../buttons";
import CustomColumnInput from "./CustomValidationInput";

interface ValidationsModalProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: (validations: any[]) => void;
  columns: any | undefined;
  originalValidations?: any[]
  header?: number | string
}

const ValidationsModal = ({
  open,
  handleClose,
  handleAccept,
  columns,
  originalValidations,
  header
}: ValidationsModalProps) => {


  const [validations, setValidations] = useState<any[]>([]);
  const [formattedColumns, setFormattedColumns] = useState<any[]>([]);



  useEffect(() => {
    if (originalValidations !== undefined) {
      setValidations(originalValidations)
    }
  }, [originalValidations]);

  
  useEffect(() => {
   setFormattedColumns(formatColumns())
  }, [columns,  header]);


  const handleChangeValidation = (valObj: any) => {
    setValidations([
      ...validations.filter((validation: any) => {
        return validation.column !== valObj.column;
      }),
      valObj,
    ]);
  };


  const formatColumns = () => {
    let unnamedCount = 0; // Contador para los nombres "Unnamed"
    return columns && columns?.length > 0
      ? header !== undefined && header !== "" && header !== null
        ? Object.values(columns[parseInt(header as string)]).map((col: any) => {
            // Verificar si el valor de col es NaN o null
            if (col === null || col === "nan" || col === "NaN" || col ==="null" || col ===""){
              col = `Unnamed ${unnamedCount}`;
              unnamedCount++;  // Incrementar el contador para cada columna "Unnamed"
            }
            return col;
          })
        : Object.keys(columns[0]).filter((col: string) => col !== "id").map((col: any) => {
            // Verificar si el valor de col es NaN o null
            if (col === null || col === "nan" || col === "NaN" || col ==="null"|| col ==="") {
              col = `Unnamed${unnamedCount}`;
              unnamedCount++;  // Incrementar el contador para cada columna "Unnamed"
            }
            return col;
          })
      : [];
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      maxWidth="xl"
      title="Validaciones"
      draggable={true}
    >
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          width: "870px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: "10px",
              color: "#172D40",
              marginTop: "30px",
              fontSize: "18px",
              fontWeight: "600",
              textAlign: "center",
              alignSelf: "flex-start",
              marginLeft: "15px",
            }}
          >
            Columnas:
          </Typography>
        </Box>
        {formattedColumns &&
          formattedColumns?.map((column: any) => {
            return (
              <CustomColumnInput
                key={column}
                column={column}
                handleChangeValidation={handleChangeValidation}
                validations={validations}
              />
            );
          })}
        

        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Cancelar"
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={() => {
              handleAccept(validations);
            }}
            color="blue-greeny"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ValidationsModal;
