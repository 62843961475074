import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";
import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import FileOptions from "./FileOptions";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts";
import {
  CLUSTER_ALERT,
  FILE_INFO_URL,
  PERIODS_AND_FILE_CONFIG,
} from "../../../api/axios";
import { getPeriodById } from "../../dataControl/utils";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { isExcelFile } from "../../flowConfiguration/utils";
import { useNavigate } from "react-router";
import { Typography } from "@mui/material";
import useDialog from "../../../hooks/useDialog";
import AlertCluster from "../../alertCluster/AlertCluster";
import { getRoute } from "../../flowConfiguration/Modals/AddFont/utils";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

interface SelectPeriodsAndFileProps {
  open: boolean;
  handleClose: () => void;
  filesToUpload: any[] | null;
  isValidationPrev?: boolean;
}

const SelectPeriodsAndFileModal = ({
  open,
  handleClose,
  filesToUpload,
  isValidationPrev,
}: SelectPeriodsAndFileProps) => {
  const [filesState, setFilesState] = useState<any[]>([]);
  const [openAlerModal, toggleAlertModal] = useDialog();
  const axiosPrivate = useAxiosPrivate();
  const [filesToValidate, setFilesToValidate] = useState<any[]>([]);
  const [counter, setCounter] = useState<any>(0);
  const [totalConfigs, setTotalConfigs] = useState<any>(0);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  
  const insertFiles = async (
    fileToUpload: any,
    axiosPrivate: any,
    handleUploadFileResponse: any
  ) => {
    if(!isExcelFile(fileToUpload.name ? fileToUpload.name : fileToUpload.nombre
    )){
      const fileInfo = filesState.find((f: any) => {
        return fileToUpload.name === f.nombre;
      });
      try {
        const formData = new FormData();
        formData.append("archivo", fileToUpload);
        formData.append("tipo", "archivo_local");
        fileInfo?.delimitador !== "inferir" && fileInfo?.delimitador !== undefined &&formData.append("delimitador", fileInfo?.delimitador);
        formData.append(
          "ruta_destino",
          decodeURIComponent(
            getRoute(`${location.pathname}`) +
             (fileInfo.file.type === "transaccional"  ?`/${getPeriodById(fileInfo.periods[0])}` :"")
          )
        );
        formData.append("carga_datos_detalle", true as unknown as string);
        setIsLoadingFile(true);
        const response = await axiosPrivate.post(FILE_INFO_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
  
        handleUploadFileResponse(response.status, response.data);
      } catch (error: any) {
        if (error.code === "ERR_NETWORK") {
          handleUploadFileResponse(502, fileToUpload);
        } else {
          handleUploadFileResponse(500, fileToUpload);
        }
      }
    }else{
      const filesInfo = filesState.filter((f: any) => {
        return f?.identificador?.startsWith(fileToUpload.name)
      });

      filesInfo.forEach(async (f: any) => {
        try {
          const formData = new FormData();
          formData.append("archivo", fileToUpload);
          formData.append("tipo", "archivo_local");
          f?.delimitador !== "inferir" && f?.delimitador !== undefined &&formData.append("delimitador", f?.delimitador);
          formData.append(
            "ruta_destino",
            decodeURIComponent(
              getRoute(`${location.pathname}`) +
              (f.file.type === "transaccional"  ?`/${getPeriodById(f.periods[0])}` :"")
            )
          );
          formData.append("carga_datos_detalle", true as unknown as string);
          setIsLoadingFile(true);
          const response = await axiosPrivate.post(FILE_INFO_URL, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
    
          handleUploadFileResponse(response.status, {...response.data, sheets:f.sheets, periods: f.periods, file: f.file.file });
        } catch (error: any) {
          if (error.code === "ERR_NETWORK") {
            handleUploadFileResponse(502, fileToUpload);
          } else {
            handleUploadFileResponse(500, fileToUpload);
          }
        }
      });
    }
   
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (filesToUpload && filesToUpload.length > 0) {
      const validFiles: any[] = [];

      filesToUpload.forEach((file: any) => {
        if (!isExcelFile(file.nombre)) {
          validFiles.push({
            nombre: file.nombre ? file.nombre : file.name,
            id: file.id ? file.id : file.nombre,
          });
        }
      });

      setFilesState(validFiles);
    }
  }, [filesToUpload]);

  useEffect(() => {
    if (counter !== 0 && counter === totalConfigs) {
      setIsLoadingFile(false);
      sendConfig(undefined, filesToValidate);
      
    }
  }, [counter]);


  const onSuccessGetAlert = async (data: any) => {
    if (data === true) {
      if (isValidationPrev === undefined || isValidationPrev === false) {
        handleSendConfig();
      } else {
        const totalValidations = filesState.filter((val: any)=> val.file !== undefined)
        setTotalConfigs(totalValidations.length)
        for (const file of filesToUpload as any) {
          // Esperamos a que se complete el proceso de subida de cada archivo antes de pasar al siguiente
          await insertFiles(file, axiosPrivate, handleUploadFileResponse);
        }
      }
    } else {
      toggleAlertModal();
    }
  };


  const handleUploadFileResponse = (status: any, file: any) => {
    if (status !== 500 && status !== 502) {
      if(!isExcelFile(file.nombre)){
        const fileInfo = filesState.find((f: any) => {
          return file.nombre === f.nombre;
        });
        setFilesToValidate((prevState: any) =>
          prevState
            ? [
                ...prevState,
                {
                  archivo_carga: file.id,
                  hoja_archivo_base:
                    fileInfo?.sheets &&
                    !fileInfo?.sheets?.find((sheet: any) => sheet.id === "todas")
                      ? fileInfo?.sheets
                          ?.filter((sheet: any) => sheet.id !== "todas")
                          .map((sheet: any) => sheet.id)
                      : [],
                  archivo_fuente: fileInfo.file.file,
                  periodos: fileInfo.periods?.map((p: any) => getPeriodById(p)),
                },
              ]
            : [
                {
                  archivo_carga: file.id,
                  hoja_archivo_base:
                    fileInfo?.sheets &&
                    !fileInfo?.sheets?.find((sheet: any) => sheet.id === "todas")
                      ? fileInfo?.sheets
                          ?.filter((sheet: any) => sheet.id !== "todas")
                          .map((sheet: any) => sheet.id)
                      : [],
                  archivo_fuente: fileInfo.file.file,
                  periodos: fileInfo.periods?.map((p: any) => getPeriodById(p)),
                },
              ]
        );
      }else{
        setFilesToValidate((prevState: any) =>
          prevState
            ? [
                ...prevState,
                {
                  archivo_carga: file.id,
                  hoja_archivo_base:
                    file?.sheets &&
                    !file?.sheets?.find((sheet: any) => sheet.id === "todas")
                      ? file?.sheets
                          ?.filter((sheet: any) => sheet.id !== "todas")
                          .map((sheet: any) => sheet.id).map((sheet: any) => {
                            const hojaEncontrada = file?.hojas?.find((hoja: any) => hoja.nombre === sheet);
                            return hojaEncontrada ? hojaEncontrada.id : null;
                          })
                      : [],
                  archivo_fuente: file.file,
                  periodos: file.periods?.map((p: any) => getPeriodById(p)),
                },
              ]
            : [
                {
                  archivo_carga: file.id,
                  hoja_archivo_base:
                    file?.sheets &&
                    !file?.sheets?.find((sheet: any) => sheet.id === "todas")
                      ? file?.sheets
                          ?.filter((sheet: any) => sheet.id !== "todas")
                          .map((sheet: any) => sheet.id)
                      : [],
                  archivo_fuente: file.file.file,
                  periodos: file.periods?.map((p: any) => getPeriodById(p)),
                },
              ]
        );
      }
     
      setCounter((prevState: any) => prevState+ 1);
    }
  };

  const {
    data: dataAlert,
    isLoading: isLoadingAlert,
    callApi: getAlert,
  } = useApi(
    CLUSTER_ALERT,
    "GET",
    texts.adminSchemas.getSchemas.codes,
    undefined,
    onSuccessGetAlert,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPostConfig,
    callApi: sendConfig,
    error: errorConfig,
  } = useApi(
    PERIODS_AND_FILE_CONFIG,
    "POST",
    texts.dataManagement.postPeriodsAndFile.codes,
    undefined,
    () => {
      cleanAndClose();
    } // onSuccess
  );


  const handleSendConfig = () => {
    sendConfig(
      undefined,
      filesState.map((file: any) => {
        return {
          archivo_carga: file.id,
          hoja_archivo_base:
            file?.sheets &&
            !file?.sheets?.find((sheet: any) => sheet.id === "todas")
              ? file?.sheets
                  ?.filter((sheet: any) => sheet.id !== "todas")
                  .map((sheet: any) => sheet.id)
              : [],
          archivo_fuente: file.file.file,
          periodos:
            file.type !== "transaccional" && file.isPeriodFolder
              ? file.periods?.map((p: any) => getPeriodById(p))
              : [
                  window.location.href.substring(
                    window.location.href.lastIndexOf("/") + 1
                  ),
                ],
        };
      })
    );
  };

  const handleGoToDataControl = () => {
    navigate("/control-archivos");
  };

  const cleanAndClose = () => {
   clean()
  };

  const clean = () => {
    setFilesState([]);
    setFilesToValidate([])
    setCounter(0)
    setIsLoadingFile(false)
    setTotalConfigs(0)
    handleClose();
  };

  return (
    <Dialog
      open={open}
      handleClose={clean}
      maxWidth="xl"
      title={"Periodos del archivo fuente"}
    >
      <SimpleBackdrop
        open={isLoadingPostConfig || isLoadingAlert || isLoadingFile}
        message={texts.dataManagement.postPeriodsAndFile.loading}
      />
      <Stack>
        <Typography sx={{ color: "var(--red)", marginBottom: "10px" }}>
          IMPORTANTE: No es posible validar archivos con un peso mayor a 100mb
        </Typography>
        {filesToUpload?.map((file: any, index: number) => {
          return (
            <FileOptions
              file={file}
              key={index}
              setFilesState={setFilesState}
              filesState={filesState}
              isValidationPrev={isValidationPrev}
            />
          );
        })}
        <Stack
          direction="row"
          sx={{ marginTop: "25px", width: "100%", justifyContent: "center" }}
        >
          <Button
            title="Cancelar"
            type="button"
            onClick={clean}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={() => {
              getAlert();
            }}
            color="blue-greeny"
            /*disabled={filesState?.some(
              (file: any) =>
                file.periods?.length === 0 ||
                file.periods === undefined ||
                file.periods === null
            )}*/
          />
          <Button
            title="Ir a control de archivos"
            type="button"
            onClick={handleGoToDataControl}
            color="blue"
            width={210}
          />
        </Stack>
        <AlertCluster open={openAlerModal} handleClose={toggleAlertModal} />
      </Stack>
    </Dialog>
  );
};

export default SelectPeriodsAndFileModal;
