import {
  InputLabel,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import { useContext, useState } from "react";
import PipelinesStepperProvider from "../../../context/PipelinesStepperProvider";
import { form_label } from "../../../styles/app-styles";
import InfoIcon from "@mui/icons-material/Info";

interface HeaderByColumnModalProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: (col?: string | undefined) => void;
  isPipeline?: boolean
  defaultValue?: string
}

const HeaderByColumnModal = ({
  open,
  handleClose,
  handleAccept,
  isPipeline,
  defaultValue
}: HeaderByColumnModalProps) => {
  const { pipelineState, pipelineDispatch } = useContext<
    React.ContextType<typeof PipelinesStepperProvider>
  >(PipelinesStepperProvider);
  const header = isPipeline ? pipelineState.header : "";


  const [column, setColumn] = useState<string>(defaultValue ? defaultValue:"");

  const acceptHeader = ()=>{
    if(isPipeline){
      handleAccept()
    pipelineDispatch({
        type: "SET_HEADER",
        payload: {
          ...header,  
          column: column
        },
      });
    }else{
      handleAccept(column)
    }
    
  }

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      maxWidth="xl"
      title="Validaciones"
      draggable={true}
    >
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          width: "870px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
        <InputLabel
          sx={{
            ...form_label,
            marginBottom: "5px",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Columna a buscar
          <Tooltip title="Se buscara la columna a partir de si contiene o no el texto ingresado">
            <InfoIcon
              sx={{
                color: "var(--blue)",
                fontSize: "21px",
                cursor: "pointer",
                marginLeft: "1px",
                marginBottom: "2px",
              }}
            />
          </Tooltip>
        </InputLabel>

        <TextField
          id="filled-textarea"
          label={"Nombre de la columna"}
          placeholder={"Escriba el nombre de la columna a buscar"}
          size="small"
          multiline
          variant="outlined"
          error={column === ""}
          fullWidth
          value={column}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setColumn(event.target.value);
          }}
          FormHelperTextProps={{
            sx: {
              color: "var(--magenta)",
            },
          }}
          required
        />

        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Cancelar"
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={() => {
                acceptHeader();
            }}
            color="blue-greeny"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default HeaderByColumnModal;
