import Dialog from "../dialog/Dialog";
import Stack from "@mui/material/Stack";
import { Button } from "../buttons";
import Select from "../forms/Select";
import { selectStyles } from "../../styles/select.styles";
import { stateOptions } from "./utils";
import useSingleSelect from "../../hooks/useSingleSelect";
import { useRef } from "react";
import { InputLabel } from "@mui/material";
import { form_label } from "../../styles/app-styles";

interface ChangeStateProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: (state: string) => void;
  period: string
}

const ChangeStateModal = ({
  open,
  handleClose,
  handleAccept,
  period,
}: ChangeStateProps) => {
  const stateRef: any = useRef(null);

  const [
    stateSelectedOption,
    setStateSelectedOption,
    stateChangeHandler,
  ] = useSingleSelect();

  return (
    <Dialog open={open} handleClose={handleClose} maxWidth="md" title={"Editar estado"}>
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          width: "400px",
          alignItems: "center",
          minHeight: "200px",
          justifyContent: "space-evenly",
        }}
      >
        <InputLabel sx={{ ...form_label, marginBottom: "5px", fontSize:"18px" }}>
        {`Estado del periodo:  ${period}`}                </InputLabel>
        <Select
          reference={stateRef}
          styles={selectStyles(stateSelectedOption)}
          options={stateOptions}
          name="state"
          onChange={stateChangeHandler}
          closeMenuOnSelect={true}
          placeholder="Seleccionar estado"
          isClearable
          defaultValue={stateSelectedOption}
        />
        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Cancelar"
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={()=> handleAccept(stateSelectedOption?.value as string)}
            color="blue-greeny"
            disabled={
             stateSelectedOption === undefined
            }
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ChangeStateModal;
