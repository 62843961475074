import Dialog from "../dialog/Dialog";
import Stack from "@mui/material/Stack";
import { Button } from "../buttons";
import { Typography } from "@mui/material";
import { convertArrayToText, formatDate } from "../../utils/util";
import { BarDrilldownChart } from "../charts/BarChart";

interface DetailModalProps {
  open: boolean;
  handleClose: () => void;
  data: any;
}

const DetailModal = ({ open, handleClose, data }: DetailModalProps) => {
  const getTooltip = (value: any, tooltip?: any) => {
    return tooltip
      ? `<div><b>${tooltip} </b></div>`
      : `<div><b>${value}${
          value < 2 ? " error de este tipo" : " errores de este tipo"
        } </b></div>`;
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      maxWidth="xl"
      title={"Detalles de la validación"}
    >
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          width: "1000px",
          alignItems: "center",
          minHeight: "200px",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          variant={"subtitle1"}
          sx={{
            fontWeight: "bold",
          }}
        >
          Nombre del archivo:
          {" " +data?.archivo_nombre}
        </Typography>
        <Typography
          variant={"subtitle1"}
          sx={{
            fontWeight: "bold",
          }}
        >
          Ruta del archivo:
          {" " +data?.archivo_ruta}
        </Typography>
        <Typography
          variant={"subtitle1"}
          sx={{
            fontWeight: "bold",
          }}
        >
          Extensión:
          {" " +data?.extension}
        </Typography>
        <Typography
          variant={"subtitle1"}
          sx={{
            fontWeight: "bold",
          }}
        >
          Usuario:
          {" " +data?.usuario}
        </Typography>
        <Typography
          variant={"subtitle1"}
          sx={{
            fontWeight: "bold",
          }}
        >
          Fecha de carga:
          {(data?.fecha_carga_archivo !== undefined)? " "+ formatDate(data?.fecha_carga_archivo) : ""}
        </Typography>
        <Typography
          variant={"subtitle1"}
          sx={{
            fontWeight: "bold",
          }}
        >
          Hojas a validar:
          {data?.listado_de_hojas_validar &&
            convertArrayToText(
              data?.listado_de_hojas_validar?.map(
                (sheet: any) => sheet.nombre_hoja
              )
            )}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "20px",
            margin: "auto",
          }}
        >
          Reporte de validaciones:
        </Typography>
        <BarDrilldownChart
          results={data?.grafico?.grafico}
          tooltip={getTooltip}
          drilldownParam={data?.grafico?.drilldown}
        />

        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Cerrar"
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default DetailModal;
