import { Box, Stack, Typography } from "@mui/material";
import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import { useContext, useEffect, useState } from "react";
import CustomValidationColumn from "./CustomValidationColumn";
import PipelinesStepperProvider from "../../../context/PipelinesStepperProvider";
import CustomValidationColumnError from "./CustomValidationColumnError";
interface ValidationsModalProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: (validations: any[]) => void;
  columns: any | undefined;
  originalValidations?: any[]
}

const ValidationsModal = ({
  open,
  handleClose,
  handleAccept,
  columns,
  originalValidations
}: ValidationsModalProps) => {
  const { pipelineState, pipelineDispatch } = useContext<
    React.ContextType<typeof PipelinesStepperProvider>
  >(PipelinesStepperProvider);
  const { validations: validationsContext, header, isSameStructureFile } = pipelineState;
  const [validations, setValidations] = useState<any[]>(validationsContext);

  const handleChangeValidation = (valObj: any) => {
    setValidations([
      ...validations.filter((validation: any) => {
        return validation.column !== valObj.column;
      }),
      valObj,
    ]);
  };

  const formatColumns = () => {
    return (
      columns &&
      Object.keys(columns)
        .filter((key) => key !== "id" && key !== "indice")
        .map((key) => {
          return header.data.length > 0 || header.renombre.length > 0
            ? { nombre_viejo: key, nombre_nuevo: columns[key] }
            : { nombre_viejo: key, nombre_nuevo: key };
        })
    );
  };

  const formatNotExistentColumns = () => {

    const nonExistentColumns: any[] = [];
    const actualColumns = columns && Object.keys(columns);
    originalValidations?.forEach((val: any) => {
      if (!actualColumns?.includes(val.column)) {
        nonExistentColumns.push({
          nombre_viejo: val.column,
          nombre_nuevo: val.column,
        });
      }
    });
    return nonExistentColumns;
  };

  useEffect(() => {
    if (columns && validationsContext && validationsContext.length === 0) {
      setValidations(
        formatColumns()?.map((col: any) => {
          return {
            column: col.nombre_nuevo,
            columnType: "string",
            noEmptyValues: {
              value: "permitir-vacios",
              label: "Permitir valores vacíos",
            },
          };
        })
      );

      pipelineDispatch({
        type: "SET_VALIDATIONS",
        payload: formatColumns()?.map((col: any) => {
          return {
            column: col.nombre_nuevo,
            columnType: "string",
            noEmptyValues: {
              value: "permitir-vacios",
              label: "Permitir valores vacíos",
            },
          };
        }),
      });
    }
  }, [columns, validationsContext]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      maxWidth="xl"
      title="Validaciones"
      draggable={true}
    >
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          width: "870px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: "10px",
              color: "#172D40",
              marginTop: "30px",
              fontSize: "18px",
              fontWeight: "600",
              textAlign: "center",
              alignSelf: "flex-start",
              marginLeft: "15px",
            }}
          >
            Columnas:
          </Typography>
        </Box>
        {formatColumns() &&
          formatColumns()?.map((column: any) => {
            return (
              <CustomValidationColumn
                key={column.nombre_nuevo}
                column={column}
                handleChangeValidation={handleChangeValidation}
              />
            );
          })}
        {originalValidations && originalValidations?.length > 0 && !isSameStructureFile && formatNotExistentColumns()?.length > 0 && (
          <>
            <Typography
              variant="h6"
              sx={{
                marginBottom: "10px",
                color: "#172D40",
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "600",
                textAlign: "center",
                alignSelf: "flex-start",
                marginLeft: "15px",
              }}
            >
              Columnas no existentes en fuente actual:
            </Typography>
            {formatNotExistentColumns()?.map((column: any) => {
              return (
                <CustomValidationColumnError
                  key={column.nombre_nuevo}
                  column={column}
                  handleChangeValidation={handleChangeValidation}
                  validations={originalValidations}
                />
              );
            })}
          </>
        )}

        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Cancelar"
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={() => {
              handleAccept(validations);
            }}
            color="blue-greeny"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ValidationsModal;
